import { Notification, NotificationProperties } from '@ui-components/containers/Notification'
import { Divider } from '@ui-components/decorative/Divider'
import { FixedButtonSection, FullWidthPageContentSection, Section } from '@ui-components/global/LayoutStyling'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_MEDIUM, LEGACY_SPACE_XLARGE } from '@ui-components/global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXSMALL, SPACE_XXXXLARGE } from '@ui-components/global/spacing'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { PrimaryButton } from '../actions/special/DynamicWidthButtons'
import { LeadText, MainHeading, MutedSmallText } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_LARGE_DESKTOP_PX } from '../global/constants'
import { ButtonContainer } from '../layout/ButtonContainer'
import { PrimaryTopBar, PrimaryTopBarProps } from '../navigation/PrimaryTopBar'
import { WideContainer } from './WideContainer'
import { ConfirmButtonProps } from './WizardTypes'

const LeadContent = styled.div<{ $hasMainContent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${LEGACY_SPACE_XLARGE};
  padding: 0 0 ${(props) => (props.$hasMainContent ? 0 : SPACE_XXXXLARGE)};
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    padding: 0 ${SPACE_XXXXLARGE} ${(props) => (props.$hasMainContent ? LEGACY_SPACE_MEDIUM : SPACE_XXXXLARGE)};
  }
`

const NotificationWrapper = styled.div`
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    padding: 0 ${SPACE_XXXXLARGE} ${LEGACY_SPACE_LARGE};
  }
`

const TopWrapper = styled.header<{ $narrow: boolean }>`
  background: ${Colors.White};
  display: flex;
  flex-direction: column;
  * {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
  }

  @media (min-width: ${BREAKPOINT_DESKTOP_PX - 1}px) {
    align-items: center;
    flex-direction: ${(props) => !props.$narrow && 'row-reverse'};
  }
`

const ImageContainer = styled.picture`
  img {
    object-fit: contain;
    max-height: 100vh;
    max-width: 100%;
  }

  @media (min-width: ${BREAKPOINT_DESKTOP_PX - 1}px) {
    padding: 0 ${SPACE_SMALL};
  }
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    padding: 0;
  }
`

const IntroSection = styled.section`
  padding: ${LEGACY_SPACE_LARGE} ${SPACE_SMALL} 0;
  @media (min-width: ${BREAKPOINT_DESKTOP_PX - 1}px) {
    padding: 0 ${SPACE_SMALL};
  }
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    padding: 0;
  }
`

const TextWrapper = styled.div`
  h1 {
    margin-bottom: ${SPACE_SMALL};
  }

  p {
    margin-bottom: ${SPACE_SMALL};
  }
`

const DividerWrapper = styled.div`
  padding: 0 ${SPACE_SMALL};
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
`

const ContinueButtonSection = styled(FixedButtonSection)`
  width: 100%;
  padding: ${SPACE_XXSMALL};

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    position: relative;
    box-shadow: none;
    min-width: 400px;
  }

  a {
    width: inherit;
  }
`

const InfoText = styled(MutedSmallText)`
  margin-top: ${SPACE_SMALL};
`

export interface EntranceStepProps extends PrimaryTopBarProps {
  confirmButtonProps?: ConfirmButtonProps
  heroImage?: {
    fallback: string
    avif?: string
  }
  heroImageFallback?: string
  heading: string
  leadText: string
  infoText?: string
  mainContent?: ReactNode
  footerContent: ReactNode
  fullWidthFooter?: boolean
  notificationProps?: NotificationProperties
  stepId?: string
  isLoading?: boolean
}

export const EntranceStep = (props: EntranceStepProps): JSX.Element => {
  const {
    notificationProps,
    confirmButtonProps,
    heroImage,
    mainContent,
    footerContent,
    heading,
    leadText,
    infoText,
    fullWidthFooter,
    stepId,
    isLoading,
  } = props

  return (
    <WideContainer data-testid={stepId} footerContent={footerContent} fullWidthFooter={fullWidthFooter}>
      <PrimaryTopBar {...props} />
      {notificationProps && (
        <NotificationWrapper>
          <Notification {...notificationProps} />
        </NotificationWrapper>
      )}
      <LeadContent $hasMainContent={!!mainContent}>
        <TopWrapper $narrow={!heroImage}>
          {!!heroImage && (
            <ImageContainer>
              {heroImage.avif && <source srcSet={heroImage.avif} type="image/avif" />}
              <img alt="" src={heroImage.fallback} />
            </ImageContainer>
          )}
          <IntroSection>
            <TextWrapper>
              <MainHeading>{heading}</MainHeading>
              <LeadText>{leadText}</LeadText>
              {infoText && <InfoText>{infoText}</InfoText>}
            </TextWrapper>
          </IntroSection>
        </TopWrapper>
        {mainContent && (
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
        )}
      </LeadContent>
      <FullWidthPageContentSection $backgroundColor={Colors.White} as="section">
        {mainContent && (
          <Section $maxWidth={BREAKPOINT_DESKTOP_PX}>
            <ContentWrapper>{mainContent}</ContentWrapper>
          </Section>
        )}
        {confirmButtonProps && (
          <ContinueButtonSection>
            <ButtonContainer>
              <PrimaryButton
                label={confirmButtonProps.confirmLabel}
                onClick={confirmButtonProps.onConfirm}
                data-testid="confirm-page-button"
                loading={isLoading}
              />
            </ButtonContainer>
          </ContinueButtonSection>
        )}
      </FullWidthPageContentSection>
    </WideContainer>
  )
}
