import type { KycConflict } from '../kyc'

export enum AppName {
  DigiLi = 'digili',
  CreditCard = 'hakemukset',
  Kyc = 'asiakastiedot',
  SecLoans = 'lainat',
  Bcr = 'pankkitunnukset',
  CorpKyc = 'yritysasiakas',
}

export enum AppId {
  DigiLi = 'digili',
  CreditCard = 'credit-card',
  Kyc = 'kyc',
  SecLoans = 'sec-loans',
  Bcr = 'bcr',
  CorpKyc = 'corp-kyc',
}

export enum ApiName {
  PandaAuth = 'Panda Auth',
  PandaApi = 'Panda API',
}

export enum Language {
  Fi = 'fi',
  Sv = 'sv',
}

export type LocalizedString = Record<Language, string>

export interface ExitUrlResponse {
  successUrl: LocalizedString
  failureUrl: LocalizedString
  cbsTokenParameter?: string
}

export interface GetPostOfficeResponse {
  postOffice: string | null
}

export interface VerifyAuthCodeRequest {
  authCode: string
}

export interface VerifyAuthCodeResponse {
  authToken: string
}

interface StreetAddress {
  street?: string
  countryCode?: string
  countryName?: string
}

export interface DomesticAddress extends StreetAddress {
  postalCode?: string
  postOffice?: string
  municipalityCode?: string
}

export interface ForeignAddress extends StreetAddress {
  foreignAddressLine1?: string
  foreignAddressLine2?: string
}

export interface DomesticAddressWithMunicipality extends DomesticAddress {
  municipalityCode: string
  municipalityName?: string
}

export interface AddressWithMunicipality extends Omit<Address, 'domesticAddress'> {
  domesticAddress?: DomesticAddressWithMunicipality
}

export interface Address {
  domesticAddress?: DomesticAddress
  foreignAddress?: ForeignAddress
}

export interface BankAccount {
  iban: string
  name?: string
  description: string
}

export interface Netbank {
  netbankUserId: string
  debitAccount: string
}

export enum MonetaryRange {
  From0to999 = 'from1to999',
  From1000to2999 = 'from1000to2999',
  From3000to4999 = 'from3000to4999',
  From5000to9999 = 'from5000to9999',
  From10000 = 'from10000',
}

export enum CountRange {
  From1to4 = 'from1to4',
  From5to9 = 'from5to9',
  From10 = 'from10',
}

export enum PaymentInPurpose {
  OwnTransfer = 'ownTransfer',
  SalaryOrPension = 'salaryOrPension',
  OtherIncome = 'otherIncome',
  Dividend = 'dividend',
  Loan = 'loan',
  Gift = 'gift',
  PropertySale = 'propertySale',
  Other = 'other',
}

export enum PaymentOutPurpose {
  OwnTransfer = 'ownTransfer',
  Gift = 'gift',
  Donation = 'donation',
  Loan = 'loan',
  Investment = 'investment',
  Other = 'other',
}

export interface AbroadPaymentIn {
  paymentCountry: string // ISO 3166 Alpha-2 code
  otherPurpose?: string // 1 - 100 characters
  monthlyCount: CountRange
  monthlyAmount: MonetaryRange
  paymentPurposes: PaymentInPurpose[]
}

export interface AbroadPaymentOut {
  paymentCountry: string // ISO 3166 Alpha-2 code
  otherPurpose?: string // 1 - 100 characters
  monthlyCount: CountRange
  monthlyAmount: MonetaryRange
  paymentPurposes: PaymentOutPurpose[]
}

export interface CashPaymentYes {
  hasPayments: true
  cashPurpose: string // 1 - 100 characters
  monthlyCount: CountRange
  monthlyAmount: MonetaryRange
}

export interface CashPaymentNo {
  hasPayments: false
}

export type CashPayment = CashPaymentYes | CashPaymentNo

/** ISO 3166 Alpha-2 code */
export type CountryCode = string

export interface TaxationCountry {
  country: CountryCode
  taxationId?: string // max 100 characters
}

export enum PoliticalPost {
  HeadOfState = 'headOfState',
  HeadOfGovernment = 'headOfGovernment',
  Minister = 'minister',
  Parliamentarian = 'parliamentarian',
  GovernmentMemberOfPoliticalParty = 'governmentMemberOfPoliticalParty',
  MemberOfConstitutionalCourt = 'memberOfConstitutionalCourt',
  MemberOfAuditorsCourt = 'memberOfAuditorsCourt',
  GovernmentMemberOfCentralBank = 'governmentMemberOfCentralBank',
  Ambassador = 'ambassador',
  OfficerOfDefenceForcesGeneral = 'officerOfDefenceForcesGeneral',
  GovernmentMemberOfStateOwnedCompany = 'governmentMemberOfStateOwnedCompany',
  GovernmentMemberOfInternationalCommunity = 'governmentMemberOfInternationalCommunity',
  Other = 'other',
}

export enum BestTimeToReach {
  AnyTime = 'anyTime',
  Forenoon = 'forenoon',
  Afternoon = 'afternoon',
}

export enum PoliticallyExposedPerson {
  Not = 'not',
  Self = 'self',
  Spouse = 'spouse',
  Child = 'child',
  Parent = 'parent',
  Associate = 'associate',
}

export enum IncomeType {
  Pension = 'pension',
  Salary = 'salary',
  Benefit = 'benefit',
  Capital = 'capital',
  Investment = 'investment',
  LoyaltyBenefit = 'loyaltyBenefit',
  Other = 'other',
}

export enum NoIncomeType {
  NoIncome = 'noIncome',
}

export enum NetBankNotificationsTo {
  Email = 'email',
  CellPhone = 'cellPhone',
}

export type TaxationIdentifierType = 'none' | 'fatca' | 'crs' | 'unknown'

export interface Country {
  alpha2Code: CountryCode
  name: string
  taxationIdentifierType: TaxationIdentifierType
  taxationIdentifierInUse: boolean
  privateTinPattern: string | null
  privateTinPlaceholder: string | null
  isBirthCountry: boolean
  isHomeCountry: boolean
  isNationality: boolean
  isTaxationCountry: boolean
  isEuOrEtaCountry: boolean
  phoneCode: string
  isPhoneCountry: boolean
  institutionTinPattern: string | null
  institutionTinPlaceholder: string | null
}

export enum CardType {
  CreditDebit = 'creditDebit',
  Debit = 'debit',
  Credit = 'credit',
}

export enum AuthorizationRegion {
  Global = 'global',
  Europe = 'europe',
  Nordics = 'nordics',
  Domestic = 'domestic',
}

export enum PinSelectionMethod {
  Manual = 'epin',
  Automatic = 'none',
}

export enum PinDeliveryMethod {
  Mail = 'mail',
  Netbank = 'electronic',
}

export enum InvoiceDueDate {
  MiddleOfMonth = 'middleOfMonth',
  EndOfMonth = 'endOfMonth',
}

export enum MaritalStatus {
  Unmarried = 'unmarried',
  Married = 'married',
  Partner = 'partner',
  Divorced = 'divorced',
  Widow = 'widow',
}

export enum Accommodation {
  OwnApartment = 'ownApartment',
  Company = 'company',
  RightOfOccupancy = 'rightOfOccupancy',
  Rental = 'rental',
  Other = 'other',
}

export enum Occupation {
  Employed = 'employed',
  Entrepreneur = 'entrepreneur',
  Student = 'student',
  Pensioner = 'pensioner',
  Unemployed = 'unemployed',
}

export enum EmployeeType {
  Employed = 'employed',
  Official = 'official',
  SeniorOfficial = 'seniorOfficial',
}

export enum EmploymentType {
  Permanent = 'permanent',
  TimeLimited = 'timeLimited',
  Other = 'other',
}

export enum SchoolType {
  University = 'university',
  Polytechnic = 'polytechnic',
  OccupationalSchool = 'occupationalSchool',
  HighSchool = 'highSchool',
  Other = 'other',
}

export interface LoanDetails {
  monthlyPayment: number
  remainingAmount: number
}

export enum ExistingCardType {
  DebitOrCredit = 'debitOrCredit',
  DinersAmexOrOther = 'dinersAmexOrOther',
  MastercardCredit = 'mastercardCredit',
  VisaCredit = 'visaCredit',
  VisaElectron = 'visaElectron',
}

export enum Bank {
  SPankki = 'S-Bank',
  Aktia = 'Aktia',
  Alandsbanken = 'Alandsbanken',
  DanskeBank = 'Danske',
  Handelsbanken = 'Handelsbanken',
  Nordea = 'Nordea',
  OP = 'Op',
  Saastopankki = 'Saastopankki',
  Other = 'other',
}

export enum CreditLimitValue {
  Small_800 = 800,
  Small_1000 = 1000,
  Small_1500 = 1500,
  Small_2000 = 2000,
  Small_2500 = 2500,
  Medium_3000 = 3000,
  Medium_3500 = 3500,
  Medium_4000 = 4000,
  Medium_4500 = 4500,
  Medium_5000 = 5000,
  Large_6000 = 6000,
  Large_7000 = 7000,
  Large_8000 = 8000,
  Large_9000 = 9000,
  Large_10000 = 10000,
}

export enum RepaymentMinimum {
  PERCENTAGE_3 = 3,
  PERCENTAGE_5 = 5,
  PERCENTAGE_10 = 10,
  PERCENTAGE_100 = 100,
}

export interface EmploymentDetails {
  employer: string
  employeeType?: EmployeeType
  profession: string
  employmentType: EmploymentType
  employmentStart: string
  employmentEnd?: string
  previousEmploymentDetails?: {
    employer: string
    employmentStart: string
    employmentEnd: string
  }
}

export interface EducationDetails {
  schoolType: SchoolType
  schoolName: string
  studiesStart: string
  studyPoints: number
}

export interface PaymentCardApplicationRequest {
  phoneNumber?: string
  creditLimit?: CreditLimitValue
  repaymentMinimum?: RepaymentMinimum
  paymentFreeMonth1?: number
  paymentFreeMonth2?: number
  invoiceDueDate?: InvoiceDueDate
  bankAccountNumber?: string
  pinSelectionMethod: PinSelectionMethod
  pinDeliveryMethod?: PinDeliveryMethod
  dailyWithdrawalLimit: number
  dailyPurchaseLimit: number
  dailyInternetPurchaseLimit: number
  authorizedRegion: AuthorizationRegion
  maritalStatus?: MaritalStatus
  accommodation?: Accommodation
  occupation?: Occupation
  salary?: number
  otherIncome?: number
  mortage?: LoanDetails
  otherLoans?: LoanDetails
  paymentCards?: ExistingCardType[]
  bank?: Bank
  employmentDetails?: EmploymentDetails
  educationDetails?: EducationDetails
  cardType: CardType
}

export interface ErrorResponse<T> {
  requestId: string
  errorCode: T
  source?: string
  retryable: boolean
  message: string
}

export type CbsTokenResponse = {
  cbsToken: string
}

export interface UiConfig<T extends string, U extends string = string> {
  uiLanguage: Language
  sourceChannel: U
  flow: T
}

export interface LogErrorRequest {
  error: string
}

export interface SignApplicationResponse {
  signingUrl: string
}

export interface SendApplicationRequest {
  authCode?: string
}

export enum CardApplicationStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
  RejectedPositiveCreditRegistry = 'rejectedPositiveCreditRegistry',
  RejectedCreditInformationEntry = 'rejectedCreditInformationEntry',
}

export const REJECTED_CARD_APPLICATION_STATUSES: CardApplicationStatus[] = [
  CardApplicationStatus.Rejected,
  CardApplicationStatus.RejectedCreditInformationEntry,
  CardApplicationStatus.RejectedPositiveCreditRegistry,
]

export interface CardApplicationResponse {
  status: CardApplicationStatus
  grantedCreditLimit?: CreditLimitValue
}

export interface PaymentCard {
  cardType: CardType
}

export enum CardApplicationFlow {
  CreditLimit = 'creditlimit',
  NewCard = 'newcard',
}

export enum KycFlow {
  Mandatory = 'mandatory',
  Skippable = 'skippable',
  Voluntary = 'voluntary',
}

export enum KycTarget {
  Self = 'self',
  Minor = 'minor',
}

export enum SecLoansFlow {
  Default = 'default',
}

export enum BcrFlow {
  Default = 'default',
}

export enum KycExitReason {
  Success = 'success',
  Cancelled = 'cancelled',
  Error = 'error',
}

export interface UserInfoResponse {
  firstNames: string
  lastName: string
  address: Address
  phoneNumber?: string
}

export interface NewCardUserInfoResponse extends UserInfoResponse {
  existingServices: {
    bankAccounts: BankAccount[]
    cards: PaymentCard[]
  }
}

export interface CreditLimitUserInfoResponse extends UserInfoResponse {
  currentCreditLimit: number
  currentCardType: Exclude<CardType, CardType.Debit>
}

export interface CreditLimitApplicationRequest {
  phoneNumber: string
  creditLimit: CreditLimitValue
  maritalStatus: MaritalStatus
  accommodation: Accommodation
  occupation: Occupation
  salary: number
  otherIncome: number
  mortage?: LoanDetails // if user has answered no mortgage, this is empty
  otherLoans?: LoanDetails // if user has answered no other loans, this is empty
  paymentCards: ExistingCardType[]
  bank: Bank
  employmentDetails?: EmploymentDetails
  educationDetails?: EducationDetails
}

export interface AuthenticateResponse {
  authUrl: string
}

export interface KycInfo {
  birthCountry?: string
  occupation?: string
  nationalities: string[]
  residenceCountry?: string
  netBankNotificationsTo?: NetBankNotificationsTo
  taxationCountries?: TaxationCountry[]
  isTaxableInUsa?: boolean
  isSbankPrimaryBank?: boolean
  incomeTypes?: NoIncomeType[] | IncomeType[]
  otherIncomeType?: string
  monthlyIncome?: MonetaryRange
  monthlyOutcome?: MonetaryRange
  abroadPaymentsIn?: AbroadPaymentIn[]
  abroadPaymentsOut?: AbroadPaymentOut[]
  cashDeposit?: CashPayment
  cashWithdrawal?: CashPayment
  politicallyExposedPerson?: PoliticallyExposedPerson
  politicalPost?: PoliticalPost
  otherPoliticalPost?: string
  hasPepInfo: boolean
  isEmailMarketingAllowed?: boolean
  isPhoneMarketingAllowed?: boolean
  expirationDate?: string
  isActivePrivateCustomer: boolean
}

export enum KycVersion {
  Full = 'full',
  Light = 'light',
}

export interface KycUserInfo extends UserInfoResponse {
  emailAddress?: string
  phoneCountry?: string
  phoneCountryCode?: string
  kycInfo: KycInfo
  kycVersion: KycVersion
  canSkipKyc: boolean
  showKycGate: boolean
}

export interface GetExitUrlQueryParams<T extends string, U extends string> {
  sourceChannel: U
  flow: T
}

export interface GetPostOfficeQueryParams {
  postcode: string
  language: Language
}

export interface LanguageQueryParam {
  lang: Language
}

export interface ServiceStatus {
  service: string
  hostName: string
  port: number
  status: string
}

export enum BffStatus {
  Ok = 'OK',
  Failed = 'FAILED',
  Maintenance = 'MAINTENANCE',
  Offline = 'OFFLINE',
}

export interface StatusResponse {
  status: BffStatus
  environment: string
  version?: string
  sourceIp?: string
  statuses?: ServiceStatus[]
}

export const SOURCE_CHANNEL_SMOBILE = 'smobile'
export const SOURCE_CHANNEL_SKANAVA = 'skanava'

export interface UpdateBankCustomerResponse {
  conflicts: KycConflict[]
}

export enum SchoolDegree {
  Elementary = 'elementary',
  Vocational = 'vocational',
  HighSchool = 'highSchool',
  Undergraduate = 'undergraduate',
  Graduate = 'graduate',
}

export enum TaxLiability {
  Limited = 'limited',
  Generally = 'generally',
}

export interface KycInfoRequest {
  birthCountry: string // ISO 3166 Alpha-2 code
  occupation: string // max 100 characters
  nationalities: string[] // ISO 3166 Alpha-2 codes
  residenceCountry: string // ISO 3166 Alpha-2 code
  netBankNotificationsTo?: NetBankNotificationsTo
  taxationCountries: TaxationCountry[]
  isSbankPrimaryBank: boolean
  incomeTypes: NoIncomeType[] | IncomeType[]
  otherIncomeType?: string // 1 - 100 characters
  monthlyIncome: MonetaryRange
  monthlyOutcome: MonetaryRange
  abroadPaymentsIn: AbroadPaymentIn[]
  abroadPaymentsOut: AbroadPaymentOut[]
  cashDeposit: CashPayment
  cashWithdrawal: CashPayment
  politicallyExposedPerson: PoliticallyExposedPerson
  politicalPost?: PoliticalPost
  otherPoliticalPost?: string // 1 - 200 characters
  isNationalityUsa: boolean
  taxationUsa: boolean
  taxLiabilityFinland?: TaxLiability
}

export interface CountriesResponse {
  countries: Country[]
}

export enum AuthenticationMode {
  Interactive = 'interactive',
  Sso = 'sso',
}

export interface AuthenticateQueryParams extends LanguageQueryParam {
  authMode: AuthenticationMode
}

export interface VerificationCodeRequest {
  code: string
}

export interface PhoneNumber {
  phoneCountryCode: string
  phoneNumber: string
  phoneCountry?: CountryCode
}

export interface PostCodeMunicipality {
  postcode: string
  postOffice: string
  municipality: {
    code: string
    name: string
  }
}

export interface FileObject {
  id: string
  file?: File
  error?: string
  isValid: boolean
  name: string
  size: number
}

export enum CommonMimeType {
  Png = 'image/png',
  Jpeg = 'image/jpeg',
  Pdf = 'application/pdf',
  MsWordDoc = 'application/msword',
  MsWordDocx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface StorageDataAbbreviation {
  bytes: string
  kilobytes: string
  megabytes: string
}

export interface ValidatePhoneNumberRequest {
  phoneNumber: string
  isMobileRequired: boolean
}

export interface ValidatePhoneNumberResponse {
  isValid: boolean
}
