import {
  SDS_SHADOW_ELEVATION_LOW_BLUR,
  SDS_SHADOW_ELEVATION_LOW_Y,
  SDS_SHADOW_ELEVATION_MIDDLE_BLUR,
  SDS_SHADOW_ELEVATION_MIDDLE_Y,
} from '@s-group/design-system-tokens/web/tokens/shadow.es6'

export const SHADOW_ELEVATION_MIDDLE_BLUR = SDS_SHADOW_ELEVATION_MIDDLE_BLUR
export const SHADOW_ELEVATION_MIDDLE_Y = SDS_SHADOW_ELEVATION_MIDDLE_Y
export const SHADOW_ELEVATION_LOW_Y = SDS_SHADOW_ELEVATION_LOW_Y
export const SHADOW_ELEVATION_LOW_BLUR = SDS_SHADOW_ELEVATION_LOW_BLUR

export const StandardShadow = `box-shadow: 0 ${SHADOW_ELEVATION_LOW_BLUR} 8px ${SDS_SHADOW_ELEVATION_LOW_Y} rgb(0 0 0 / 10%);`
