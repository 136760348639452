import {
  LinkButton as DSLinkButton,
  LinkButtonProps as DSLinkButtonProps,
} from '@s-group/design-system-components/LinkButton/LinkButton'
import { ReactNode, RefAttributes } from 'react'
import { css, styled } from 'styled-components'

import { ThemedComponentProps } from '../global/ComponentProps'

export interface LinkButtonProps extends DSLinkButtonProps, ThemedComponentProps {
  className?: string
  onClick?: (event: React.BaseSyntheticEvent) => void
  children?: ReactNode // the shown label currently, add label property if this changes
  tabIndex?: number
  disabled?: boolean
  $disabled?: boolean
  target?: '_self' | '_blank' | '_parent' | '_top'
  ['data-testid']?: string
}

export interface LinkButtonComponentProps extends Omit<LinkButtonProps, '$disabled' | '$iconPadding'> {
  ['aria-label']: string
}

const LinkWrap = styled.div<Omit<LinkButtonProps, 'aria-label'>>`
  flex-grow: 1;
  width: auto;
  margin: auto;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: auto;

      & *,
      & *:hover,
      & *:active {
        text-decoration: none;
      }
    `}
`

const TypedDSLinkButton = DSLinkButton as React.ComponentType<LinkButtonProps & RefAttributes<HTMLLinkElement>>

export const LinkButton = ({
  // Always include href to make link focusable
  href = '',
  icon,
  iconPos = 'before',
  children,
  disabled,
  compact,
  tabIndex,
  onClick,
  className,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  sizing = 'small',
  rounding = 'pill',
  target,
  color,
  variant = 'plain',
}: LinkButtonComponentProps) => {
  const handleClick = (e: React.BaseSyntheticEvent) => {
    // Prevent default navigation behaviour
    e.preventDefault()
    onClick?.(e)
  }

  return (
    <TypedDSLinkButton
      href={href}
      onClick={handleClick}
      className={className}
      tabIndex={tabIndex}
      disabled={disabled}
      $disabled={disabled}
      data-testid={dataTestId}
      aria-disabled={disabled}
      variant={variant}
      icon={icon}
      iconPos={iconPos}
      sizing={sizing}
      rounding={rounding}
      compact={compact}
      target={target}
      color={color}
      role={'link'}
      aria-label={ariaLabel}
    >
      {children && (
        <LinkWrap aria-hidden={!!ariaLabel} color={color}>
          {children}
        </LinkWrap>
      )}
    </TypedDSLinkButton>
  )
}
