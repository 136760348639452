import { LooseFormSectionContent } from '@ui-components/containers/LooseSectionContent'
import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

const SimpleSection = styled.section``

export const SubSectionContainer = (props: PropsWithChildren<{ ['data-testid']?: string }>) => {
  return (
    <SimpleSection {...props}>
      <LooseFormSectionContent>{props.children}</LooseFormSectionContent>
    </SimpleSection>
  )
}
