import { useId } from 'react'

import { PlusIcon } from '../../assets/Icons'
import { MediumText } from '../../decorative/Typography'
import { Button } from '../Button'

export interface AddItemButtonProps {
  onClick: (event: React.BaseSyntheticEvent) => unknown
  label: string
  description?: string
}

export const AddItemButton = (props: AddItemButtonProps) => {
  const descriptionId = useId()

  return (
    <div>
      {props.description && (
        <MediumText id={descriptionId} weight="regular" tagName="p" $color="secondary">
          {props.description}
        </MediumText>
      )}
      <Button
        onClick={props.onClick}
        label={props.label}
        aria-describedby={descriptionId}
        icon={<PlusIcon />}
        compact={true}
        variant="plain"
      />
    </div>
  )
}
