import { PropsWithChildren, ReactElement } from 'react'
import { styled } from 'styled-components'

import { IconSize } from '../assets/Icons'
import { SmallText } from '../decorative/Typography'
import { VERTICAL_PADDING_DEFAULT } from '../global/constants'
import { SPACE_XXXSMALL } from '../global/spacing'
import { getShortRequestId } from '../utilities/helpers'
import { ModalHeaderContainer, ModalMainHeading } from './ModalHeading'
import { TightSectionContent } from './TightSectionContent'

const ModalContainer = styled(TightSectionContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    width: 100%;
  }
`

const IconContainer = styled.div`
  width: ${IconSize.MEDIUM}px;
  height: ${IconSize.MEDIUM}px;
  margin-bottom: ${SPACE_XXXSMALL};
`

const ModalContentContainer = styled.div`
  flex: 1 0 0;
`

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${VERTICAL_PADDING_DEFAULT}px;
  margin-top: 16px;

  > * {
    width: 100%;
  }
`

export interface ModalContentProps {
  icon?: JSX.Element
  header?: ReactElement | string
  requestId?: string
}

export const ModalContent = ({ icon, header, children, requestId }: PropsWithChildren<ModalContentProps>) => {
  const headerElement =
    typeof header === 'string' ? (
      <ModalHeaderContainer>
        <ModalMainHeading>{header}</ModalMainHeading>
        {requestId && <SmallText color="secondary">{getShortRequestId(requestId)}</SmallText>}
      </ModalHeaderContainer>
    ) : (
      header
    )

  return (
    <ModalContainer>
      <div>
        {icon && <IconContainer>{icon}</IconContainer>}
        {header && headerElement}
      </div>
      <ModalContentContainer>{children}</ModalContentContainer>
    </ModalContainer>
  )
}
