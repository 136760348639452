import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'

const IndentationContainer = styled.div`
  margin: 12px 14px;
  padding: 8px 0px 8px 25px;

  border-left: 1px solid ${Colors.BorderWeakNeutral};
`
export interface IndentationTextProps {
  children: ReactNode
}

export const IndentationText = ({ children, ...rest }: IndentationTextProps) => {
  return <IndentationContainer {...rest}>{children}</IndentationContainer>
}
