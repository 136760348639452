import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import { forwardRef } from 'react'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import {
  ErrorSpacerWithReservedSpace,
  FlexColumnContainer,
  FlexRowContainer,
  NarrowInputFieldWrapper,
} from '../global/CommonStyling'
import { FormTextInputProps } from '../global/ComponentProps'
import { parseComponentId } from '../utilities/helpers'
import { TextInputField } from './primitive/TextFields'

export const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>((props, ref) => {
  const { id, name, renderPrefix = () => '', readOnly, disabled, error, additionalDescription } = props
  const { children, ...withoutChildren } = props

  const overriddenId = parseComponentId(name, id)

  const errorAndDescriptionRow = (
    <ErrorAndDescriptionRow error={error} id={overriddenId} description={additionalDescription} />
  )

  return (
    <NarrowInputFieldWrapper $readonly={readOnly} $disabled={disabled} data-testid={overriddenId + '-wrapper'}>
      {props.label && (
        <Label isRequired={props.required} label={props.label} id={overriddenId} description={props.description} />
      )}
      <FlexColumnContainer>
        <FlexRowContainer>
          {renderPrefix({})}
          <TextInputField
            {...withoutChildren}
            readOnly={readOnly}
            disabled={disabled}
            id={overriddenId}
            error={error}
            ref={ref}
          />
        </FlexRowContainer>
        {/* IndentedFormFieldContainer handles spacing for error and description row when rendered */}
        {children ? (
          errorAndDescriptionRow
        ) : (
          <ErrorSpacerWithReservedSpace>{errorAndDescriptionRow}</ErrorSpacerWithReservedSpace>
        )}
      </FlexColumnContainer>
      {children && <IndentedFormFieldContainer>{children}</IndentedFormFieldContainer>}
    </NarrowInputFieldWrapper>
  )
})

FormTextInput.displayName = 'FormTextInput'
