import { Heading as SdsHeading } from '@s-group/design-system-components/Heading/Heading'
import { Button, ButtonProps } from '@ui-components/actions/Button'
import { Notification, NotificationProperties } from '@ui-components/containers/Notification'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { ExtraLargeText, SmallText } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXXSMALL } from '../global/spacing'

const Container = styled.div<{ $noBottomMargin: boolean }>`
  ${({ $noBottomMargin: noBottomMargin }) => `margin-bottom: ${noBottomMargin ? 0 : LEGACY_SPACE_XLARGE};`}
  display: flex;
  flex-direction: column;
  gap: ${SPACE_SMALL};
`

const ButtonWrapper = styled.div`
  margin-top: ${LEGACY_SPACE_LARGE};
`

const HeadingCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACE_XXXSMALL};
  color: ${Colors.TextMediumPrimary};
  h1,
  h2 {
    margin: 0;
  }
  span {
    color: ${Colors.TextStrongNeutral};
  }
`

const Caption = styled(SmallText)``

const LeadText = styled(ExtraLargeText)`
  display: flex;
  flex-direction: column;
  gap: ${SPACE_SMALL};
`

const AdditionalText = styled(SmallText)`
  display: flex;
  flex-direction: column;
  gap: ${SPACE_SMALL};
  color: ${Colors.TextStrongNeutral};
`

interface HeadingProps {
  text: string
}

const Heading = styled(SdsHeading)`
  //TODO: DIG-4949 Find a better way to do the hyphenisation
  hyphens: auto;
  word-wrap: break-word;
`

const H1 = ({ text }: HeadingProps): JSX.Element => (
  <Heading variant="display" tagName="h1" weight="bold" sizing="small" transform="uppercase">
    {text}
  </Heading>
)

const H2 = ({ text }: HeadingProps): JSX.Element => (
  <Heading variant="display" weight="semibold" sizing="xsmall">
    {text}
  </Heading>
)

export interface TitleSectionProps {
  headingLevel?: 1 | 2
  title: string
  caption?: ReactNode
  leadText?: ReactNode
  additionalText?: ReactNode
  moreInfoButtonProps?: ButtonProps
  notificationProps?: NotificationProperties
  noBottomMargin?: boolean
}

export const TitleSection = ({
  headingLevel = 2,
  title,
  caption,
  leadText,
  additionalText,
  moreInfoButtonProps,
  notificationProps,
  noBottomMargin = false,
}: TitleSectionProps): JSX.Element => {
  const heading = headingLevel === 1 ? <H1 text={title} /> : <H2 text={title} />
  //TODO: Will be changed in the typography ticket DIG-4877
  return (
    <Container $noBottomMargin={noBottomMargin}>
      {notificationProps && <Notification status="info" {...notificationProps} />}
      <HeadingCaptionContainer>
        {heading}
        {caption && <Caption>{caption}</Caption>}
      </HeadingCaptionContainer>
      {leadText && <LeadText>{leadText}</LeadText>}
      {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
      {moreInfoButtonProps && (
        <ButtonWrapper>
          <Button variant="plain" compact={true} {...moreInfoButtonProps} />{' '}
        </ButtonWrapper>
      )}
    </Container>
  )
}
