import { CardSection } from '@ui-components/global/CardStyling'
import * as React from 'react'
import { styled } from 'styled-components'

import { InfoIcon } from '../assets/Icons'
import { LargeHeading, LargeText, SmallText } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { LogoIconType } from '../global/ComponentProps'
import { WizardHeaderButtonProps, WizardPageSubHeaderProps } from '.'
import { Assistant } from './Assistant'
import { WizardHeader } from './WizardHeader'

export interface AfterCareStepProps {
  stepId: string
  icon: LogoIconType
  title: string
  topic: string
  heading: string
  text: React.ReactNode[]
  headerButtonProps: WizardHeaderButtonProps
  notification?: string
  children?: React.ReactNode
  subHeaderOptions?: WizardPageSubHeaderProps
}

const Wrapper = styled.div`
  background-color: ${Colors.BackgroundInfo};
  height: 100vh;
`

const NotificationWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const NotificationArea = styled.div`
  color: ${Colors.TextInfo};
`

const AttentionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 15px;
  margin-top: 0px;
`

const AfterCareCard = styled(CardSection)`
  width: inherit;
  display: flex;
  margin: 0px;
  gap: 12px;
  min-width: 200px;
`

const CardHeader = styled(LargeHeading)`
  margin: 0px;
`

const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

export const AfterCareStep = ({
  title,
  topic,
  icon,
  heading,
  text,
  notification,
  children,
  headerButtonProps,
  subHeaderOptions,
  stepId,
}: AfterCareStepProps) => {
  const textArray = (Array.isArray(text) ? text : [text]).filter((t) => t)

  return (
    <Wrapper>
      <WizardHeader
        shouldShowMainHeader={true}
        title={title}
        icon={icon}
        headerButtonProps={headerButtonProps}
        subHeaderOptions={subHeaderOptions}
        $useLegacyBrand={true}
      />
      <main>
        <Assistant data-testid={stepId} title={topic} pictogram="indexFingerUp" twoColumnLayoutSupport={false}>
          <AttentionWrapper>
            <AfterCareCard data-testid="aftercare-card" key={heading}>
              <CardHeaderContainer>
                <CardHeader $color="primary">{heading}</CardHeader>
              </CardHeaderContainer>
              {textArray.map((paragraph, idx) => (
                <LargeText key={`text-${idx}`} $color="primary">
                  {paragraph}
                </LargeText>
              ))}
              {children}
            </AfterCareCard>
            {notification && (
              <NotificationWrapper>
                <InfoIcon style={{ color: Colors.ElementInformation }} />
                <NotificationArea>
                  <SmallText>{notification}</SmallText>
                </NotificationArea>
              </NotificationWrapper>
            )}
          </AttentionWrapper>
        </Assistant>
      </main>
    </Wrapper>
  )
}
