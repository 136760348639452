import { styled } from 'styled-components'

import { FlexColumnContainer, FlexRowContainer } from '../global/CommonStyling'
import { BREAKPOINT_LARGE_DESKTOP_PX } from '../global/constants'
import { LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'
import { SPACE_SMALL } from '../global/spacing'

export interface TwoColumnLayoutProps {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}

export const TwoColumnLayout = ({ leftContent, rightContent }: TwoColumnLayoutProps) => {
  return (
    <Row>
      {leftContent && <Column>{leftContent}</Column>}
      {rightContent && <Column>{rightContent}</Column>}
    </Row>
  )
}

const Row = styled(FlexRowContainer)`
  flex-wrap: wrap;
  width: 100%;
  gap: ${LEGACY_SPACE_XLARGE};
`

const Column = styled(FlexColumnContainer)`
  flex-basis: 100%;

  > :first-child {
    margin-top: 0;
  }

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    flex: 1;

    &:nth-child(2) {
      padding-top: ${SPACE_SMALL};
    }
  }
`
