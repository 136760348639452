import { Card as SDSCard } from '@s-group/design-system-components/Card/Card'
import { isValidElement, ReactElement } from 'react'
import { styled } from 'styled-components'

import { BulletIcon, CheckIcon, IconContainer } from '../assets/Icons'
import { Card } from '../containers/Card'
import { LargeText, MediumText } from '../decorative/Typography'
import { SPACE_SMALL, SPACE_XXSMALL } from '../global/spacing'
import { toObjArray } from '../utilities/helpers'
import { Phase, PhaseContent } from './PhasesView'

export type PhaseInstructions = Record<CompletionProgress, PhaseHeaderTexts>

export interface SimplePhasesViewProps {
  phasesHeader: string
  phases: Phase[]
  shouldShowCompletedPhases: boolean
}

export interface PhaseHeaderTexts {
  title: string
  text: string
}

export enum CompletionProgress {
  NotStarted = 'initial',
  InComplete = 'started',
  InCompletePastHalf = 'pastHalfway',
  Complete = 'complete',
}

const DashConnector = styled.div`
  margin: 0px 0 -15px 20px;
  border-left: 1px dashed currentColor;
  height: 15px;
  color: inherit;
`

const PhaseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const PhaseIcon = styled(IconContainer)`
  width: 42px;
`

const PhasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 15px;
  margin-top: 0px;
`

const PhasesSection = styled.section`
  max-width: 700px;
`

const PhaseContainer = styled.div``

const PhaseCard = styled(Card)`
  max-width: unset;
`

const DescriptionText = styled(MediumText)`
  display: inline-block;
  &:not(:first-of-type) {
    margin-top: ${SPACE_XXSMALL};
  }
`

const ContentCard = styled(SDSCard)`
  padding: ${SPACE_SMALL};
`

const isStandardPhaseContent = (phase: Phase) => {
  return !isValidElement(phase.content)
}

export const PhaseView = (props: { phase: Phase }) => {
  const { phase } = props
  if (!isStandardPhaseContent(phase)) {
    return null
  }

  const phaseContent = phase.content as PhaseContent

  const textArray = (Array.isArray(phaseContent.text) ? phaseContent.text : [phaseContent.text]).filter(Boolean)
  const textObjArr = toObjArray([...textArray])
  const description =
    textArray.length > 0
      ? textObjArr.map((paragraph) => (
          <DescriptionText key={paragraph.id} $color="primary">
            {paragraph.item}
          </DescriptionText>
        ))
      : undefined

  return (
    <PhaseCard
      variant={phaseContent.action ? 'primary' : 'basic'}
      title={{ text: phase.header, $tagName: 'h2', $variant: 'heading' }}
      clickOptions={{
        kind: 'button',
        buttonText: phaseContent.action?.label ?? '',
        onClick: phaseContent.action?.onClick ?? (() => void 0),
      }}
      subtitle={phaseContent.description ? { text: phaseContent.description } : undefined}
      description={description}
    />
  )
}

export const SimplePhasesView = (props: SimplePhasesViewProps) => {
  const { phases, phasesHeader, shouldShowCompletedPhases } = props

  const completePhaseCount = phases.filter((phase) => phase.isComplete).length
  const inCompletePhaseCount = phases.length - completePhaseCount

  let phasesText = phasesHeader
  if (!shouldShowCompletedPhases) {
    phasesText += ` (${inCompletePhaseCount})`
  }

  return (
    <PhasesSection>
      <LargeText weight="bold">{phasesText}</LargeText>
      <PhasesWrapper>
        <DashConnector />
        {phases
          .filter((phase) => shouldShowCompletedPhases || !phase.isComplete)
          .map((phaseToShow) => {
            const isComplete = phaseToShow.isComplete
            const isFirstIncomplete = phaseToShow === phases.find((phase) => !phase.isComplete)
            const isPhaseStandardContent = isStandardPhaseContent(phaseToShow)

            const component = isPhaseStandardContent ? (
              <PhaseView phase={phaseToShow} key={phaseToShow.header} />
            ) : (
              <ContentCard variant="elevated">{phaseToShow.content as ReactElement}</ContentCard>
            )

            return (
              <PhaseContainer key={phaseToShow.header} data-testid="phase">
                {!isFirstIncomplete && (
                  <PhaseWrapper>
                    <PhaseIcon>{isComplete ? <CheckIcon /> : <BulletIcon />}</PhaseIcon>
                    <MediumText>{phaseToShow.header}</MediumText>
                  </PhaseWrapper>
                )}
                {isFirstIncomplete && component}
                <DashConnector />
              </PhaseContainer>
            )
          })}
      </PhasesWrapper>
    </PhasesSection>
  )
}
