import { styled } from 'styled-components'

import { LegacyBrandProps } from '../common/types'
import { SegmentedProgressBar } from '../decorative/SegmentedProgressBar'
import { SecondaryHeaderWrapper } from '../global/CommonStyling'
import { LogoIconType } from '../global/ComponentProps'
import { PrimaryTopBar } from '../navigation/PrimaryTopBar'
import { SecondaryTopBar } from '../navigation/SecondaryTopBar'
import { PAGE_EXIT_BUTTON_CLASS } from '../utilities/helpers'
import { WizardHeaderButtonProps, WizardPageSubHeaderProps } from './WizardTypes'

interface WizardHeaderProps extends LegacyBrandProps {
  shouldShowMainHeader: boolean
  title: string
  icon: LogoIconType
  headerButtonProps?: WizardHeaderButtonProps
  subHeaderOptions?: WizardPageSubHeaderProps
  fixToTop?: boolean
  additionalText?: string
  hideBoxShadow?: boolean
}

const Header = styled.header`
  width: 100%;
`

const PrimaryHeaderWrapper = styled.div`
  // Hide primary bar title text on very narrow screens
  @media (max-width: 342px) {
    > * > span {
      display: none;
    }
  }
`

export const WizardHeader = (props: WizardHeaderProps) => {
  const { subHeaderOptions, fixToTop, additionalText, hideBoxShadow } = props
  const progressBarOptions = subHeaderOptions?.progressBarOptions
  const secondaryTopBarMenuProps = subHeaderOptions?.secondaryTopBarMenuProps

  return (
    <Header>
      {props.shouldShowMainHeader && (
        <PrimaryHeaderWrapper>
          <PrimaryTopBar
            actionButtonOptions={{
              className: PAGE_EXIT_BUTTON_CLASS,
              label: props.headerButtonProps?.headerButtonLabel ?? '',
              onClick: props.headerButtonProps?.onHeaderButtonClick,
            }}
            icon={props.icon}
            serviceName={props.title}
            userDisplayName={additionalText}
            variant="compact"
          />
        </PrimaryHeaderWrapper>
      )}
      {subHeaderOptions && (
        <SecondaryHeaderWrapper $fixedToTop={!!fixToTop} data-testid="secondary-header">
          <SecondaryTopBar
            title={subHeaderOptions.stepTitle}
            description={subHeaderOptions.stepDescription}
            small={fixToTop}
            previousButtonLabel={subHeaderOptions.backButtonLabel}
            onPreviousButtonClick={subHeaderOptions?.onBack}
            infoButtonLabel={subHeaderOptions.infoButtonLabel}
            onInfoButtonClick={subHeaderOptions?.onInfo}
            menu={secondaryTopBarMenuProps}
            hideBoxShadow={hideBoxShadow}
          />
          {progressBarOptions && (
            <SegmentedProgressBar
              segments={progressBarOptions.totalSteps}
              current={progressBarOptions.currentStepIndex}
              aria-label={progressBarOptions['aria-label']}
            />
          )}
        </SecondaryHeaderWrapper>
      )}
    </Header>
  )
}
