import { styled } from 'styled-components'

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_LARGE_DESKTOP_PX, LARGE_COINTAINER_MAX_WIDTH_PX } from '../global/constants'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXXXLARGE } from '../global/spacing'

export const LargeScreenContainer = styled.div<{ $smallTopPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${LEGACY_SPACE_XLARGE};
  padding: ${(props) => (props.$smallTopPadding ? LEGACY_SPACE_LARGE : LEGACY_SPACE_XLARGE)} ${SPACE_SMALL};

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: ${(props) => (props.$smallTopPadding ? LEGACY_SPACE_LARGE : LEGACY_SPACE_XLARGE)} ${LEGACY_SPACE_LARGE};
  }

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    padding: ${(props) => (props.$smallTopPadding ? LEGACY_SPACE_LARGE : LEGACY_SPACE_XLARGE)} ${SPACE_XXXXLARGE}
      ${SPACE_XXXXLARGE};
    fieldset,
    input {
      max-width: ${LARGE_COINTAINER_MAX_WIDTH_PX}px;
    }
  }
`
