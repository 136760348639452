import { styled } from 'styled-components'

import { BREAKPOINT_DESKTOP_PX, SECTION_HORIZONTAL_PADDING_DEFAULT } from '../global/constants'
import { Divider } from './Divider'

const DividerWrapper = styled.div`
  width: inherit;
  box-sizing: border-box;

  padding: 0 16px;

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: 0 ${SECTION_HORIZONTAL_PADDING_DEFAULT}px;
  }
`

export const PaddedDivider = () => {
  return (
    <DividerWrapper>
      <Divider spacing="small" data-testid="divider" />
    </DividerWrapper>
  )
}
