import { RefObject, useEffect, useState } from 'react'

interface UseFullyInViewportReturn {
  isFullyInViewport: boolean
  isFullyOutOfViewport: boolean
}

export const useFullyInViewport = (element: RefObject<HTMLElement>): UseFullyInViewportReturn => {
  const [isFullyInViewport, setIsFullyInViewport] = useState(false)
  const [isFullyOutOfViewport, setIsFullyOutOfViewport] = useState(false)

  useEffect(() => {
    const currentElement = element.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.intersectionRatio === 0) {
          setIsFullyOutOfViewport(true)
        } else {
          setIsFullyOutOfViewport(false)
        }

        if (entry?.intersectionRatio === 1) {
          setIsFullyInViewport(true)
        } else {
          setIsFullyInViewport(false)
        }
      },
      { threshold: [0, 1] },
    )

    currentElement && observer.observe(element.current)
    return () => {
      currentElement && observer.unobserve(currentElement)
    }
  }, [element])

  return { isFullyInViewport, isFullyOutOfViewport }
}
