import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'

export interface SpeechBubbleProperties {
  $border?: string
  $borderColor?: string
  $backgroundColor?: string
  children?: ReactNode
}

const BubbleStyle = ({
  $border = '1px solid',
  $backgroundColor = Colors.White,
  $borderColor = Colors.BorderDefaultNeutral,
}: SpeechBubbleProperties) => `
  border: ${$border};
  border-color: ${$borderColor};
  background-color: ${$backgroundColor};
`

const SpeechBubbleWrap = styled.div<SpeechBubbleProperties>`
  ${BubbleStyle}
  padding: 16px;
  overflow-wrap: break-word;
`

const SpeechBubbleContent = styled.div``
const SpeechBubbleEndWrapper = styled.div`
  width: 100%;
`

const SpeechBubbleEnd = styled.div<Omit<SpeechBubbleProperties, 'children'>>`
  transform-origin: center;
  transform: rotate(45deg);
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  overflow-wrap: break-word;

  ${BubbleStyle}
  border-left: 0;
  border-top: 0;
  top: -15px;
`

export const SpeechBubble = ({ children = '', ...rest }: SpeechBubbleProperties) => (
  <div>
    <SpeechBubbleWrap {...rest}>
      <SpeechBubbleContent>{children}</SpeechBubbleContent>
    </SpeechBubbleWrap>
    <SpeechBubbleEndWrapper>
      <SpeechBubbleEnd {...rest} />
    </SpeechBubbleEndWrapper>
  </div>
)
