import { SDS_BRAND_COLOR_BORDER_WEAK_NEUTRAL } from '@s-group/design-system-tokens/web/tokens/s-pankki/colors.es6'
import { LooseFormSectionContent } from '@ui-components/containers/LooseSectionContent'
import { Fragment, ReactNode } from 'react'
import { styled } from 'styled-components'

import { Divider } from '../decorative/Divider'
import { LargeHeading } from '../decorative/Typography'
import { BREAKPOINT_LARGE_DESKTOP_PX } from '../global/constants'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'

const StepSummaryContainer = styled(LooseFormSectionContent)`
  padding: 32px 16px 56px 16px;
  border: 1px solid ${SDS_BRAND_COLOR_BORDER_WEAK_NEUTRAL};
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    padding: ${LEGACY_SPACE_LARGE} ${LEGACY_SPACE_LARGE} ${LEGACY_SPACE_XLARGE};
  }
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const PhaseHeading = styled(LargeHeading)`
  margin-bottom: 40px;
  margin-top: 16px;
`

export interface PhaseSummaryProps {
  heading: string
  children: ReactNode
}

export const PhaseSummary = (props: PhaseSummaryProps) => {
  const { heading, children } = props

  const stepSummaries = Array.isArray(children) ? children.filter(Boolean) : [children]

  return (
    <StepSummaryContainer>
      <PhaseHeading>{heading}</PhaseHeading>
      {stepSummaries.map((stepSummary, index) => {
        return (
          <Fragment key={stepSummary?.key}>
            {index !== 0 && <Divider data-testid="divider" />}
            <StepContainer key={stepSummary?.key}>{stepSummary}</StepContainer>
          </Fragment>
        )
      })}
    </StepSummaryContainer>
  )
}
