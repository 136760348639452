import { forwardRef } from 'react'
import { styled } from 'styled-components'

import { Label } from '../dataDisplay/Label'
import { FormDescribedCheckboxProps } from '../global/ComponentProps'
import { parseComponentId } from '../utilities/helpers'
import { FormLabelledCheckbox } from './FormLabelledCheckbox'

const DescribedCheckboxWrapper = styled.div``

export const FormDescribedCheckbox = forwardRef<HTMLInputElement, FormDescribedCheckboxProps>((props, ref) => {
  const {
    className,
    name,
    id,
    description,
    additionalDescription,
    checkboxLabel,
    side = 'right',
    required = false,
    error = undefined,
    isOneColumnLayout,
    ...rest
  } = props

  const overriddenId = parseComponentId(`${name}-described-checkbox`, id)

  return (
    <DescribedCheckboxWrapper data-testid={`${overriddenId}-section-wrapper`} className={className}>
      <Label
        id={overriddenId}
        label={description}
        isRequired={required}
        description={additionalDescription}
        labelIdOverride={`${overriddenId}-top-label`}
      >
        <FormLabelledCheckbox
          isOneColumnLayout={isOneColumnLayout}
          name={name}
          id={overriddenId}
          border={true}
          data-testid={overriddenId}
          side={side}
          label={checkboxLabel}
          error={error}
          ref={ref}
          {...rest}
        />
      </Label>
    </DescribedCheckboxWrapper>
  )
})

FormDescribedCheckbox.displayName = 'FormConsentCheckbox'
