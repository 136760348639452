import { forwardRef } from 'react'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import { FlexColumnContainer, FlexRowContainer, InputFieldWrapper } from '../global/CommonStyling'
import { FormTextInputProps } from '../global/ComponentProps'
import { parseComponentId } from '../utilities/helpers'
import { TextAreaField } from './primitive/TextFields'

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextInputProps>((props, ref) => {
  const { additionalDescription, description, ...textAreaFieldProps } = props
  const {
    id,
    required,
    label,
    name,
    readOnly,
    disabled,
    renderPrefix = () => '',
    width = `100%`,
    error,
  } = textAreaFieldProps

  const overriddenId = parseComponentId(name, id)

  return (
    <InputFieldWrapper $width={width} $readonly={readOnly} $disabled={disabled}>
      {label && <Label isRequired={required} label={label} id={overriddenId} description={description} />}
      <FlexColumnContainer>
        <FlexRowContainer>
          {renderPrefix({})}
          <TextAreaField {...textAreaFieldProps} description={description} id={overriddenId} ref={ref} />
        </FlexRowContainer>
        <ErrorAndDescriptionRow error={error} id={overriddenId} description={additionalDescription} />
      </FlexColumnContainer>
    </InputFieldWrapper>
  )
})

FormTextArea.displayName = 'FormTextArea'
