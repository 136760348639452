export const BREAKPOINT_NARROW_MOBILE_PX = 400
export const BREAKPOINT_DESKTOP_PX = 768
export const BREAKPOINT_LARGE_DESKTOP_PX = 1280
export const VERTICAL_PADDING_DEFAULT = 8
export const HORIZONTAL_PADDING_DEFAULT = 12
export const SECTION_VERTICAL_PADDING_DEFAULT = 32
export const SECTION_HORIZONTAL_PADDING_DEFAULT = 32
export const CHECKBOX_SIZE = 20
export const ROUNDING_DEFAULT = 8
export const DEFAULT_INPUT_MAX_WIDTH_PX = 400
export const MIN_SCREEN_WIDTH_PX = 320
export const COMMON_PLACEHOLDER = '--'
export const LARGE_COINTAINER_MAX_WIDTH_PX = 540

export const MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX = 1024

export const MAIN_STEP_HEADER_HEIGHT = 40
export const MAIN_HEADER_HEIGHT = 76
export const PAGE_CONTENT_TOP_MARGIN = 16
export const STEP_HEADER_HEIGHT = 73
export const STEP_HEADER_SMALL_HEIGHT = 53
export const STEP_HEADER_BOTTOM_MARGIN = 12

export const PAGE_COMPONENT_GAP = 56
export const AVERAGE_PAGE_COMPONENT_GAP = 32
export const TIGHT_PAGE_COMPONENT_GAP = 16
export const PAGE_BOTTOM_PADDING = 100
export const CTA_BUTTON_HEIGHT = 42

export const FONT_FAMILY_S_BONUS_UX = 'S Bonus UX'

export const MAX_FILE_UPLOAD_SIZE_IN_BYTES = 5 * 1024 * 1024

export const DEFAULT_TEXT_INPUT_MAX_LENGTH = 200

export const DEFAULT_SCROLLED_OVER_HEIGHT_PX = 250
