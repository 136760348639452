import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { ExtraSmallHeading, MutedSmallText } from '@ui-components/decorative/Typography'
import { styled } from 'styled-components'

export interface FormStepQuaternaryHeaderProps {
  header: string
  id: string
  tagName?: 'h4' | 'h5' | 'h6'
  headerDescription?: string
}

const Heading = styled(ExtraSmallHeading)`
  margin: 0px;
`

export const FormStepQuaternaryHeader = ({
  header,
  id,
  tagName = 'h4',
  headerDescription,
}: FormStepQuaternaryHeaderProps) => {
  return (
    <TightSectionContent>
      <div>
        <Heading tagName={tagName} id={id}>
          {header}
        </Heading>
        {headerDescription && <MutedSmallText>{headerDescription}</MutedSmallText>}
      </div>
    </TightSectionContent>
  )
}
