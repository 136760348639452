import {
  Bank,
  BestTimeToReach,
  ExitUrlResponse,
  Language,
  LanguageQueryParam,
  MaritalStatus,
  Occupation,
  SchoolDegree,
  SecLoansFlow,
  UiConfig,
  UserInfoResponse,
  VerifyAuthCodeRequest,
} from '../types'

export type SecLoansUiConfig = UiConfig<SecLoansFlow, SecLoansSource>

export interface SecLoansVerifyAuthCodeRequest extends VerifyAuthCodeRequest, SecLoansUiConfig {}

export enum SecLoanApplicationState {
  Draft = 'Draft',
  CancelledByPrimaryApplicant = 'CancelledByPrimaryApplicant',
  WaitingForSecondaryApplicant = 'WaitingForSecondaryApplicant',
  ReturnedBySecondaryApplicant = 'ReturnedBySecondaryApplicant',
  CancelledBySecondaryApplicant = 'CancelledBySecondaryApplicant',
  Sending = 'Sending',
  WaitingForResend = 'WaitingForResend',
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export enum ApplicantRole {
  PrimaryApplicant = 'primary',
  SecondaryApplicant = 'secondary',
}

export enum SecLoanApplicationType {
  HousingLoan = 'HousingLoan',
  CottageLoan = 'CottageLoan',
  PropertyInvestment = 'PropertyInvestment',
  PurchaseLoan = 'PurchaseLoan',
}

export enum SecLoansResidenceType {
  RealEstate = 'realEstate', // Only used in non known leisure time residence type selection (could be a different one PreferredResidenceType)
  BlockOfFlats = 'blockOfFlats',
  TerracedHouse = 'terracedHouse',
  TownHouse = 'townHouse',
  SemiDetachedHouse = 'semiDetachedHouse',
  DetachedHouse = 'detachedHouse',
  Other = 'other',
}

export enum SecLoanPurposeType {
  Buy = 'Buy',
  Construction = 'Construction',
  Renovation = 'Renovation',
  LoanTransferToSbank = 'LoanTransferToSbank',
  Other = 'Other',
}

export enum SecLoanCarOwnershipType {
  OwnCar = 'OwnCar',
  Leasing = 'Leasing',
  CompanyCarBenefitInKind = 'CompanyCarBenefitInKind',
  CompanyCarFringeBenefit = 'CompanyCarFringeBenefit',
  OwnMultipleCars = 'OwnMultipleCars',
}

export enum SecLoanChildrenAgeGroup {
  ZeroToTen = '0-10',
  ElevenToFifteen = '11-15',
  SixteenToEighteen = '16-18',
}

export interface SecLoanChildAlimony {
  ageGroup: SecLoanChildrenAgeGroup
  alimonyAmount: number
}

export enum SecLoanHousingType {
  HomeOwnership = 'HomeOwnership',
  RentalHome = 'RentalHome',
  RightOfResidence = 'RightOfResidence',
  SharedOwnership = 'SharedOwnership',
  EmployerProvidedHousing = 'EmployerProvidedHousing',
  ParentsHome = 'ParentsHome',
  Other = 'Other',
}

export enum SecLoanHomeOwnershipHousingType {
  HousingShareCooperative = 'HousingShareCooperative',
  DetachedHouseOnOwnLand = 'DetachedHouseOnOwnLand',
  DetachedHouseOnLeasedLand = 'DetachedHouseOnLeasedLand',
  Other = 'Other',
}

export enum SecLoanWorkingHoursOptions {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum SecLoanWorkingPositionOptions {
  Employee = 'Employee',
  OfficeWorker = 'OfficeWorker',
  ManagerOrSpecialist = 'ManagerOrSpecialist',
  LeadershipPosition = 'LeadershipPosition',
  Other = 'Other',
}

export enum SecLoanProbationOptions {
  Ended = 'Ended',
  Ongoing = 'Ongoing',
  NoProbation = 'NoProbation',
}

export enum SecLoanPreviousEmploymentTypeOptions {
  Permanent = 'Permanent',
  FixedTerm = 'FixedTerm',
  Temporary = 'Temporary',
  EntrepreneurOrFreelancer = 'EntrepreneurOrFreelancer',
  NoPreviousEmployment = 'NoPreviousEmployment',
}

export enum SecLoanOtherIncomeType {
  ChildAllowance = 'ChildAllowance',
  RentalIncome = 'RentalIncome',
  RegularExpenseReimbursements = 'RegularExpenseReimbursements',
  DividendIncome = 'DividendIncome',
  SideJob = 'SideJob',
  HousingAllowance = 'HousingAllowance',
  Grant = 'Grant',
  ChildSupport = 'ChildSupport',
  Pension = 'Pension',
  Other = 'Other',
}

export interface SecLoanOtherIncome {
  otherIncomeType: SecLoanOtherIncomeType
  amountPerYear?: number
  amountPerMonth?: number
  description?: string
}

// TODO: DIG-2714 - add attachment categories and mapping to externalDocumentSubCategory
// values (cArchive categories) used in Ibus requests
export enum SecLoanAttachmentCategory {
  Other = 'other',
}

export interface CommonLoanApplicationData {
  // TODO: DIG-2798: define common data and properties
}

export interface HousingLoanApplicationData
  extends CommonLoanApplicationData,
    TargetInfoDataType,
    ApplicantInfoDataType,
    LoanInfoDataType {}

export interface CottageLoanApplicationData
  extends CommonLoanApplicationData,
    TargetInfoDataType,
    ApplicantInfoDataType,
    LoanInfoDataType {}
export interface PropertyInvestmentLoanApplicationData
  extends CommonLoanApplicationData,
    TargetInfoDataType,
    ApplicantInfoDataType,
    LoanInfoDataType {}
export interface PurchaseLoanApplicationData
  extends CommonLoanApplicationData,
    TargetInfoDataType,
    ApplicantInfoDataType,
    LoanInfoDataType {}

export type SecLoanApplicationData = TargetInfoDataType & ApplicantInfoDataType & LoanInfoDataType

export type PrimaryApplicantInfo = UserInfoResponse

export interface SecLoansApplicationMetadata {
  interestRate: number
  applicationId: string
  applicantRole: ApplicantRole
  applicationState: SecLoanApplicationState
  applicationResult?: LoanApplicationResultResponse
  applicationPurpose: SecLoanPurposeType
  applicationType: SecLoanApplicationType
  created: number
  updated: number
  version: string
  primaryApplicantInfo: PrimaryApplicantInfo
  hasCoApplicant: boolean
}

export interface SecLoanAttachment {
  attachmentId: string
  filename: string
  category: SecLoanAttachmentCategory
}

export interface SecLoanApplication extends SecLoansApplicationMetadata {
  data: Partial<SecLoanApplicationData>
  attachments: SecLoanAttachment[]
}

export interface SecLoansApplicationsMetadataResponse {
  applications: SecLoansApplicationMetadata[]
}

export interface ApplicationIdQueryParam {
  applicationId: string
}

export enum SecLoanAction {
  Submit = 'submit',
  Return = 'return',
  Cancel = 'cancel',
}

export interface ApplicationActionQueryParams extends ApplicationIdQueryParam {
  action: SecLoanAction
}

export interface SecLoansUserInfoResponse extends UserInfoResponse, SecLoansApplicationsMetadataResponse {
  ssn: string
  isSummaryPdfLinkVisible: boolean
}

export interface SecLoansCreateApplicationRequest {
  applicationType: SecLoanApplicationType
  applicationPurpose: SecLoanPurposeType
  data: Partial<HousingLoanApplicationData>
}

export interface SecLoansCreateApplicationResponse extends SecLoanApplication {}

export interface SecLoansGetApplicationResponse extends SecLoanApplication {}

export interface SecLoansUpdateApplicationRequest {
  data: SecLoanApplicationData
}

export interface SecLoansUpdateApplicationResponse extends SecLoanApplication {}

export enum SecLoansSource {
  Netbank = 'netbank',
  SPankkiFi = 'spankkifi',
  SMobile = 'smobile',
  SMobileBrowser = 'smobilebrowser',
  Unknown = 'unknown',
}

export interface SecLoansAuthenticateQueryParams extends LanguageQueryParam {
  sourceChannel: SecLoansSource
}

export enum SecLoansHousingLoanTargetType {
  HousingStock = 'HousingStock',
  RealEstate = 'RealEstate',
  NotKnown = 'NotKnown',
  RightOfResidence = 'RightOfResidence',
  PartOwnership = 'PartOwnership',
}

export enum SecLoansEmploymentType {
  Permanent = 'permanent',
  TimeLimited = 'timeLimited',
  HiredWork = 'hiredWork',
}

export enum SecLoansCurrentResidenceSellingPlan {
  SellingFirst = 'sellingFirst',
  SellingAfter = 'sellingAfter',
  NotForSale = 'notForSale',
}

export enum SecLoansExpenseShareForNewFlat {
  All = 'all',
  OtherAmount = 'otherAmount',
}

export enum SecLoansMethodOfRepayment {
  Annuity = 'annuity',
  ConstantAmount = 'constantAmount',
  ConstantDuration = 'constantDuration',
}

export enum SecLoansPropertyType {
  HousingStock = 'housingStock',
  Residential = 'residential',
  Leisure = 'leisure',
  PartOwnership = 'partOwnership',
  RightOfOccupancy = 'rightOfOccupancy',
  Other = 'other',
}

export enum SecLoansInvestmentType {
  Funds = 'funds',
  PensionInsurance = 'pensionInsurance',
  InsuranceSavings = 'insuranceSavings',
  Equity = 'equity',
  Other = 'other',
}

export enum SecLoansGuaranteeLiability {
  Mortgage = 'mortgage',
  Other = 'other',
}

export enum SecLoansApplicants {
  BothApplicants = 'both',
  FirstApplicant = 'first',
  SecondApplicant = 'second',
}

export interface SecLoansProperty {
  propertyType: SecLoansPropertyType
  value: number
  ownership: SecLoansApplicants
  companyDebtAmount?: number
  monthlyPaymentToCompanyDebt?: number
}

export interface SecLoansInvestment {
  investmentType: SecLoansInvestmentType
  value: number
  ownership: SecLoansApplicants
}

export enum SecLoansLoanOrCreditType {
  CarLoan = 'carLoan',
  ConsumerCredit = 'consumerCredit',
  Mortgage = 'mortgage',
  CottageLoan = 'cottageLoan',
  RenovationLoan = 'renovationLoan',
  StudentLoan = 'studentLoan',
  InvestmentMortgage = 'investmentMortgage',
  InvestmentLoan = 'investmentLoan',
  Other = 'other',
}

export interface SecLoansLoanOrCredit {
  loanOrCreditType: SecLoansLoanOrCreditType
  lender: Bank
  amount: number
  installment: number
  ownership: SecLoansApplicants
  isTransferredLoan?: boolean
}

export interface SecLoansGuarantee {
  liability: SecLoansGuaranteeLiability
  guarantor?: SecLoansGuarantor
  amount: number
}

export enum SecLoansGuarantor {
  FirstApplicant = 'first',
  SecondApplicant = 'second',
}

export enum SecLoansCreditCardTypes {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'American Express',
  DinersClub = 'Diners Club',
  Other = 'Other',
}

export enum SecLoansCompanyForm {
  LimitedCompany = 'LimitedCompany',
  LimitedPartnership = 'LimitedPartnership',
  OpenCompany = 'OpenCompany',
  TradeName = 'TradeName',
}

export enum SecLoansIndustrySector {
  AgricultureFishing = 'agriculture-fishing',
  MiningQuarrying = 'mining-quarrying',
  Industry = 'industry',
  ElectricityGasSupply = 'electricity-gas-supply',
  WaterSupply = 'water-supply',
  Construction = 'construction',
  WholesaleRetail = 'wholesale-retail',
  Transportation = 'transportation',
  AccommodationFood = 'accommodation-food',
  InfoCommunication = 'info-communication',
  FinancyInsurance = 'financy-insurance',
  RealEstate = 'real-estate',
  ScientificalTechnical = 'scientifical-technical',
  Administration = 'administration',
  PublicAdministration = 'public-administration',
  Education = 'education',
  HealthAndSocialServices = 'health-and-social-services',
  Arts = 'arts',
  OtherServices = 'other-services',
  HouseholdEmployers = 'household-employers',
  InternationalOrganisations = 'international-organisations',
}

export enum SecLoansReferenceRate {
  Euribor12Month = 'Euribor12Month',
  Fixed = 'Fixed',
}

export enum SecLoansCollateralType {
  OwnCollateral = 'ownCollateral',
  StateGuarantee = 'stateGuarantee',
  AnotherPersonsAssets = 'anotherPersonsAssets',
}

export type SecLoansLotType = SecLoansLotOwnership | SecLoansConstructionLotOwnership

export enum SecLoansLotOwnership {
  Own = 'own',
  PublicRental = 'publicRental',
  PrivateRental = 'privateRental',
}

export enum SecLoansConstructionLotOwnership {
  Own = 'own',
  BuyLater = 'buyLater',
  Rent = 'rent',
}

export interface TargetInfoData
  extends PriceInfoDataType,
    LocationInfoDataType,
    AddressInfoDataType,
    TargetAdditionalInfoDataType,
    ResidenceInfoDataType,
    RealEstateLivingExpensesInfoDataType,
    LivingExpensesInfoDataType,
    HousingLoanInfoDataType,
    RenovationsInfoDataType,
    PurchaseLoanInfoDataType,
    LoanCostEstimateInfoDataType,
    ConstructionLoanTargetInfoDataType {}

export interface TargetInfoDataType {
  targetInfo?: TargetInfoData
}

export interface PriceInfoDataType {
  priceInfo?: PriceInfoData
}

export interface PriceInfoData {
  price: number
  netSellingPrice?: number
  isPayingCompanyLoanShare?: boolean
}

export interface LivingExpensesInfoData {
  maintenanceFee: number
  housingCompanyLoanPayment?: number
  otherExpenses: number
}

export interface LocationInfoDataType {
  locationInfoData?: LocationInfoData
}

export interface LocationInfoData {
  municipality: string
  preciseLocation: string
}

export interface AddressInfoData {
  street: string
  postCode: string
  postOffice: string
}

export interface AddressInfoDataType {
  addressInfoData?: AddressInfoData
}

export interface AdditionalInfoData {
  additionalInfo?: string | null
}

export interface AdditionalInfoDataType {
  additionalInfoData?: AdditionalInfoData
}

export interface TargetAdditionalInfoData {
  additionalInfo?: string | null
  additionalRenovationsInfo?: string | null
}

export interface TargetAdditionalInfoDataType {
  additionalInfoData?: TargetAdditionalInfoData
}

export interface LoanAdditionalInfoData {
  additionalInfo?: string | null
  campaignCode?: string | null
}

export interface LoanAdditionalInfoDataType {
  loanAdditionalInfoData?: LoanAdditionalInfoData
}

export interface TargetTypeInfoDataType {
  targetTypeInfo?: TargetTypeInfoData
}

export type HousingLoanInfo = TargetTypeInfoDataType & LoanTransferTargetInfoDataType

export interface HousingLoanInfoDataType {
  housingLoanInfo?: HousingLoanInfo
}

export interface LoanCostEstimateInfoDataType {
  loanCostEstimateInfo?: LoanCostEstimateInfoData
}

export interface LoanCostEstimateInfoData {
  plotOwnership?: SecLoansConstructionLotOwnership
  costEstimate: number
  ownFinancingShare: number
  ownWorkShare?: number
  plotValue?: number
  plotSellingPrice?: number
}

export interface ConstructionLoanTargetInfoDataType {
  constructionLoanTargetInfo?: ConstructionLoanTargetInfoData
}

export interface ConstructionLoanTargetInfoData {
  constructionMaterial: ConstructionMaterial
  numberOfFloors: number
  desiredCompletionDate: string
  livingSquareArea: number
  totalSquareArea: number
}

export interface LoanTransferTargetInfoDataType extends TargetTypeInfoDataType {
  transferredLoanTargetData?: LoanTransferInfoData
}

export interface PurchaseLoanInfoDataType {
  purchaseLoanInfo?: PurchaseLoanInfoData
}

export interface TargetTypeInfoData {
  targetType: SecLoansHousingLoanTargetType
}

export interface LoanTransferInfoData {
  loanAmount: number
  resaleEstimate: number
  purchasePrice: number
  moveInMonth: string
  loanIssuer: Bank
  loanMarginPercentage?: number | null
  loanMonthlyPayment: number | null
  isLoanForFirstTimePurchase: boolean
  isAspLoan?: boolean
}

export interface PurchaseLoanInfoData {
  loanAmount: number
  loanPurpose: SecLoansPurchaseLoanPurpose
  loanIssuer?: Bank // not required since only applies to loan transfer and not for new loan
  loanMonthlyPayment?: number // not required since only applies to loan transfer and not for new loan
  otherPurpose?: string
}

export interface RenovationsInfoData {
  renovationsAmount?: number
  otherPurchasesAmount?: number
}

export interface RenovationsInfoDataType {
  renovationsInfoData?: RenovationsInfoData
}

export enum SecLoansPurchaseLoanPurpose {
  Car = 'car',
  Consumption = 'consumption',
  Investment = 'investment',
  Other = 'other',
}

export enum SecLoansHousingSalesSite {
  Oikotie = 'oikotie',
  Etuovi = 'etuovi',
  Other = 'other',
}

export enum SecLoansHeatingType {
  Electric = 'electric',
  District = 'district',
  GeoThermal = 'geothermal',
  Oil = 'oil',
  Other = 'other',
}

export interface RealEstateLivingExpensesInfoData {
  monthlyHeatingExpense: number
  otherMonthlyExpenses: number
  yearlyRealEstateTax?: number
  yearlyLotRent?: number
}

export interface ResidenceInfoData
  extends RealEstateResidenceData,
    HousingStockResidenceData,
    RightOfResidenceData,
    RenovationResidenceData {}

export interface RenovationResidenceData {
  isCollateralForSBankLoan?: boolean
  renovationNeeds?: string | null
}

export interface RealEstateResidenceData extends SalesSiteData, CommonKnownTargetResidenceData {
  lotType?: SecLoansLotOwnership
  numberOfFloors?: number
  totalSquareArea?: number
  lotSquareArea?: number
  heatingType?: SecLoansHeatingType
  realEstateId?: string | null
}

export interface SalesSiteData {
  hasSalesSite?: boolean
  salesSite?: SecLoansHousingSalesSite | null
  salesSiteTargetId?: string
  salesSiteOther?: string
}

export interface CommonKnownTargetResidenceData {
  livingSquareArea?: number
  numberOfRooms?: number
  roomLayoutDescription?: string
  buildYear?: number
  renovationHistory?: string | null
}

export interface HousingStockResidenceData extends SalesSiteData, CommonKnownTargetResidenceData {
  residenceType?: SecLoansResidenceType
  plannedRenovations?: string
  numberOfApartments?: number
  isPipeRenovationDone?: boolean
}

export interface RightOfResidenceData extends CommonKnownTargetResidenceData {
  residenceType?: SecLoansResidenceType
}

export interface PartOwnershipData extends CommonKnownTargetResidenceData {
  residenceType?: SecLoansResidenceType
}

export interface RenovationData extends CommonKnownTargetResidenceData {
  isCollateralForSBankLoan: boolean
  residenceType: SecLoansResidenceType
}

export interface ResidenceInfoDataType {
  residenceInfoData?: ResidenceInfoData
}

export interface RealEstateLivingExpensesInfoDataType {
  realEstateLivingExpensesInfoData?: RealEstateLivingExpensesInfoData
}

export interface LivingExpensesInfoDataType {
  livingExpensesInfoData?: LivingExpensesInfoData
}

export interface ApplicantInfoDataType {
  applicantInfo?: ApplicantInfoData
}

export interface EmploymentInfoDataType {
  employmentInfoData?: EmploymentInfoData
}

export interface EmploymentInfoBase {
  employer: string
  workingHours: SecLoanWorkingHoursOptions
  workingPosition: SecLoanWorkingPositionOptions
  jobTitle: string
  employmentStartDate: string
  employmentEndDate?: string
  probation?: SecLoanProbationOptions
  previousEmploymentType?: SecLoanPreviousEmploymentTypeOptions
  previousEmployment?: PreviousEmploymentFields
  returnToWorkDate?: string
}

export interface EmploymentInfoData {
  firstApplicant?: EmploymentInfoBase
  secondApplicant?: EmploymentInfoBase
}

export interface EntrepreneurInfoBase {
  companyName: string
  businessId: string
  legalFormOfTheCompany: SecLoansCompanyForm
  industrySector: SecLoansIndustrySector
  entrepreneurshipStart: string
  previousEmploymentType?: SecLoanPreviousEmploymentTypeOptions
  previousEmployment?: PreviousEmploymentFields
}
export interface EntrepreneurInfoDataType {
  entrepreneurInfoData?: EntrepreneurInfoData
}

export interface EntrepreneurInfoData {
  firstApplicant?: EntrepreneurInfoBase
  secondApplicant?: EntrepreneurInfoBase
}

export interface PreviousEmploymentFields {
  previousEmployerName?: string
  previousCompanyName?: string
  previousJobTitle: string
  previousEmploymentStartDate: string
  previousEmploymentEndDate: string
}

export interface BackgroundInfoCommon {
  isFinnishCitizen: boolean
  maritalStatus: MaritalStatus
  highestDegree: SchoolDegree
  isFirstHomeBuyer: boolean
  aspAmount?: number
  occupation: SecLoanOccupation
  hasValidEmploymentRelationship?: boolean
  employmentType?: SecLoansEmploymentType
  phoneNumber: string
  email?: string
}

export interface BackgroundInfoBase extends BackgroundInfoCommon {
  bestTimeToReach: BestTimeToReach
}

export interface BackgroundInfoSecondApplicantData extends BackgroundInfoCommon {
  firstName: string
  lastName: string
  ssn: string
  streetAddress: string
  postcode: string
  postOffice: string
  serviceLanguage: Language
}

export interface BackgroundInfoData extends BackgroundInfoBase {
  secondApplicant?: BackgroundInfoSecondApplicantData
}

export interface BackgroundInfoDataType {
  backgroundInfoData?: BackgroundInfoData
}

export interface HousingInfoDataType {
  housingInfoData?: HousingInfoData
}

export interface HousingInfoBase {
  housingType: SecLoanHousingType
  homeOwnershipType?: SecLoanHomeOwnershipHousingType
}

export interface HousingInfoData extends HousingInfoBase {
  numberOfAdults: number
  isSameHousehold: boolean | null
  numberOfChildren: number
  ownership?: SecLoansApplicants
  secondApplicant?: HousingInfoBase
  monthlyLivingExpenses?: number
  moveInDate?: string
}
export interface IncomeInfoDataType {
  incomeInfoData?: IncomeInfoData
}

export interface IncomeInfoBase {
  grossSalary?: number
  netSalary?: number
  otherIncome?: SecLoanOtherIncome[]
}
export interface IncomeInfoData extends IncomeInfoBase {
  secondApplicant?: IncomeInfoBase
}

export interface NumberOfApplicantsInfoData {
  hasCoApplicant: boolean
}

export interface NumberOfApplicantsInfoDataType {
  numberOfApplicantsInfoData?: NumberOfApplicantsInfoData
}

export interface WealthInfoData {
  savingsAmount?: number
  ownership?: SecLoansApplicants
  properties?: SecLoansProperty[]
  investments?: SecLoansInvestment[]
}

export interface WealthInfoDataType {
  wealthInfoData?: WealthInfoData
}

export interface CreditInfoBase {
  numberOfCreditCards?: number
  creditLimit?: number
  cardTypes?: SecLoansCreditCardTypes[]
}

export interface CreditInfoData extends CreditInfoBase {
  secondApplicant?: CreditInfoBase
}

export interface CreditInfoDataType {
  creditInfoData?: CreditInfoData
}

export interface CurrentResidenceInfoBase {
  sellingPlan?: SecLoansCurrentResidenceSellingPlan
  price?: number
  remainingLoanAmount?: number
  remainingLoanInstallment?: number
  lender?: Bank
  expenseShareForNewFlatAmount?: number
}

export interface CurrentResidenceInfoData extends CurrentResidenceInfoBase {
  secondApplicant?: CurrentResidenceInfoBase | null
}

export interface CurrentResidenceInfoDataType {
  currentResidenceInfoData?: CurrentResidenceInfoData
}

export interface ExpensesInfoDataType {
  expensesInfoData?: ExpensesInfoData
}

export interface ExpensesInfoData extends ExpensesInfoBase {
  secondApplicant?: ExpensesInfoBase
}

export interface ExpensesInfoBase {
  carOwnershipType?: SecLoanCarOwnershipType
  childAlimony?: SecLoanChildAlimony[]
}

export interface LoanBackgroundInfoData extends LoanAmounts {
  ownMoney?: number
  firstApplicantSharesOwnership?: number
}

export interface LoanBackgroundInfoDataType {
  loanBackgroundInfoData?: LoanBackgroundInfoData | null
}

export interface InstallmentInfoData {
  totalInstallment?: number
  monthlyInstallment?: number
  installmentOwnership?: SecLoansApplicants
  hasInstallment: boolean
}

export interface InstallmentInfoDataType {
  installmentInfoData?: InstallmentInfoData
}

export interface GuaranteesInfoData {
  guarantees?: SecLoansGuarantee[]
}

export interface GuaranteesInfoDataType {
  guaranteesInfoData?: GuaranteesInfoData
}

export interface LoanInfoDataType {
  loanInfo?: LoanInfoData
}

export interface LoansAndCreditsInfoBase {
  numberOfQuickLoans?: number
  quickLoansAmount?: number
  quickLoansInstallment?: number
}

export interface LoansAndCreditsInfoData extends LoansAndCreditsInfoBase {
  secondApplicant?: LoansAndCreditsInfoBase
  loansAndCredits?: SecLoansLoanOrCredit[]
}

export interface LoansAndCreditsInfoDataType {
  loansAndCreditsInfoData?: LoansAndCreditsInfoData
}

export interface MonthlySavingsInfoBase {
  savingsAmount?: number
}

export interface MonthlySavingsInfoData extends MonthlySavingsInfoBase {
  secondApplicant?: MonthlySavingsInfoBase
}

export interface MonthlySavingsInfoDataType {
  monthlySavingsInfoData?: MonthlySavingsInfoData
}

export interface ApplicantInfoData
  extends NumberOfApplicantsInfoDataType,
    ExpensesInfoDataType,
    IncomeInfoDataType,
    HousingInfoDataType,
    WealthInfoDataType,
    BackgroundInfoDataType,
    EmploymentInfoDataType,
    EntrepreneurInfoDataType,
    CurrentResidenceInfoDataType,
    CreditInfoDataType,
    LoansAndCreditsInfoDataType,
    InstallmentInfoDataType,
    AdditionalInfoDataType,
    GuaranteesInfoDataType {}

export interface PaymentPlanInfoDataType {
  paymentPlanInfoData?: PaymentPlanInfoData
}

export interface PaymentPlanInfoData {
  methodOfRepayment: SecLoansMethodOfRepayment
  repaymentFreeMonthsAmount: number
  loanTimeInYears?: number
  approximatedRepayment?: number
}

export interface ReferenceRateInfoDataType {
  referenceRateInfoData?: ReferenceRateInfoData
}

export interface ReferenceRateInfoData {
  referenceRate: SecLoansReferenceRate
}

export interface InsuranceInfoData {
  isInterestedInInsurance: boolean
}

export interface InsuranceInfoDataType {
  insuranceInfoData?: InsuranceInfoData
}

export interface SecLoansCollateral {
  collateralType: SecLoansCollateralType
  additionalInfo?: string | null
}

export interface CollateralInfoData {
  collaterals: SecLoansCollateral[]
}

export interface CollateralInfoDataType {
  collateralInfoData?: CollateralInfoData
}

export interface LoanInfoData
  extends LoanBackgroundInfoDataType,
    PaymentPlanInfoDataType,
    ReferenceRateInfoDataType,
    InsuranceInfoDataType,
    CollateralInfoDataType,
    MonthlySavingsInfoDataType,
    LoanAdditionalInfoDataType {}

export enum LoanApplicationDecision {
  AcceptedPreliminaryOffer = 'acceptedPreliminaryOffer', // Z5
  AcceptedBookMeeting = 'acceptedBookMeeting', // Z6
  WaitingForCoApplicant = 'waitingForCoApplicant', // Z2
  Pending = 'pending', // Z9
  Rejected = 'rejected', // Z4
  RejectedCreditInformationEntry = 'rejectedCreditInformationEntry', // Z3
  RejectedPositiveCreditRegistry = 'rejectedPositiveCreditRegistry', // Z8
  WaitingForResend = 'waitingForResend', // Z9
}

export interface LoanApplicationResultResponse {
  decision: LoanApplicationDecision
  reservationLink?: string
}

export enum SecLoansAbortModalCase {
  LeaveAndContinueLater = 'leaveAndContinueLater',
  TiredOfWaiting = 'tiredOfWaiting',
}

export interface LoanAmounts {
  loanAmount: number
  temporaryLoanAmount?: number
  finalLoanAmount?: number
}

export enum OtherOccupation {
  OnFamilyLeave = 'onFamilyLeave',
  Other = 'other',
}

export type SecLoanOccupation = Occupation | OtherOccupation

export enum ConstructionMaterial {
  Wood = 'wood',
  Brick = 'brick',
  Stone = 'stone',
}

export interface SecLoansExitUrlResponse extends ExitUrlResponse {
  showProcessingTimeNotification: boolean
}
