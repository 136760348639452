import {
  ControlBuildIcon,
  ControlLockIcon,
  ExclamationMarkIcon,
  HeartFilledIcon,
  HelpIcon,
  IconSize,
  InfoIcon,
  SadIcon,
  StatusBanIcon,
  StatusErrorIcon,
  SuccessCircleIcon,
  ThumbUpIcon,
  WarnExclamationIcon,
} from '../assets/Icons'
import { Colors } from '../global/colors'
import { ModalVisualMode } from './ModalTypes'

export const getIcon = (mode: ModalVisualMode) => {
  switch (mode) {
    case ModalVisualMode.Plain:
      return undefined
    case ModalVisualMode.Encouragement:
      return <ThumbUpIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementMediumPrimary }} />
    case ModalVisualMode.Check:
      return <SuccessCircleIcon size={IconSize.MEDIUM} />
    case ModalVisualMode.Danger:
      return <StatusErrorIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementError }} />
    case ModalVisualMode.Warn:
      return <WarnExclamationIcon />
    case ModalVisualMode.Error:
      return <ExclamationMarkIcon />
    case ModalVisualMode.Info:
      return <InfoIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementMediumNeutral }} />
    case ModalVisualMode.Happy:
      return <HeartFilledIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementMediumSecondary }} />
    case ModalVisualMode.Help:
      return <HelpIcon />
    case ModalVisualMode.ControlLock:
      return <ControlLockIcon />
    case ModalVisualMode.TechnicalError:
      return <ControlBuildIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementMediumNeutral }} />
    case ModalVisualMode.Banned:
      return <StatusBanIcon size={IconSize.MEDIUM} style={{ color: Colors.ElementMediumNeutral }} />
    case ModalVisualMode.Sad:
      return <SadIcon style={{ color: Colors.ElementMediumNeutral }} />
    default: {
      const _exhaustiveCheck: never = mode
      return _exhaustiveCheck
    }
  }
}
