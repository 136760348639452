import { assertValue } from '@shared'
import { StandardShadow } from '@ui-components/global/shadow'
import { useId, useState } from 'react'
import { styled } from 'styled-components'

import { Button } from '../actions/Button'
import { Link } from '../actions/Link'
import { LinkButton } from '../actions/LinkButton'
import { CloseIcon, IconSize, InfoIcon, KebabMenuIcon, LeftChevronIcon } from '../assets/Icons'
import { LargeText, MediumText, MiddleText, SmallText, SnippetColor } from '../decorative/Typography'
import { Colors } from '../global/colors'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_NARROW_MOBILE_PX,
  STEP_HEADER_HEIGHT,
  STEP_HEADER_SMALL_HEIGHT,
} from '../global/constants'
import { SPACE_SMALL, SPACE_XXSMALL, SPACE_XXXSMALL } from '../global/spacing'
import { useMediaQuery } from '../global/useMediaQuery'
import { PAGE_BACK_BUTTON_CLASS, PAGE_TITLE_CLASS } from '../utilities/helpers'

const TopBarContainer = styled.div<{ $hideBoxShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.$hideBoxShadow ? undefined : StandardShadow)}
`

const TopBarWrapper = styled.div<{ $small?: boolean }>`
  background-color: ${Colors.White};
  width: 100%;
  min-height: ${({ $small }) => ($small ? `${STEP_HEADER_SMALL_HEIGHT}px` : `${STEP_HEADER_HEIGHT}px`)};
  display: grid;
  align-items: center;
  grid-template-columns: 36px 1fr 36px;
  padding: ${SPACE_XXXSMALL} 0;

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    grid-template-columns: 200px 1fr 200px;
  }

  svg {
    padding-right: 0;
    height: ${IconSize.SMALL}px;
    box-sizing: content-box;
  }
`

const ItemWrapper = styled.div<{ $noPointerEvents?: boolean; $removeSidePadding?: boolean }>`
  h1 {
    margin-top: 0;
  }
  text-align: center;

  > * {
    pointer-events: ${({ $noPointerEvents }) => $noPointerEvents && `none`};
  }
  text-align: center;
  &:first-child {
    justify-self: start;
    margin-left: ${({ $removeSidePadding }) => ($removeSidePadding ? 0 : SPACE_SMALL)};
    :hover {
      color: ${Colors.TextDefaultNeutral};
    }
    :active {
      color: ${Colors.TextDefaultNeutral};
    }
  }
  &:nth-child(2) {
    padding: 0 ${SPACE_XXSMALL};
  }
  text-align: center;
  &:last-child {
    justify-self: end;
    margin-right: ${({ $removeSidePadding }) => ($removeSidePadding ? 0 : SPACE_SMALL)};
  }
`

const MenuContent = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.BorderWeakNeutral};
  padding: 16px 0;
  border-bottom: 1px solid ${Colors.BorderWeakNeutral};
`

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    width: fit-content;
    padding: 0 16px;
    min-height: 40px;
  }
`

const MenuToggleWrapper = styled.div`
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    padding: 0;
  }
`

export type SecondaryTopBarMenuOption = {
  text: string
  textColor?: SnippetColor
  onClick: () => unknown
}

export interface SecondaryTopBarMenuProps {
  menuAriaLabelOpen?: string
  menuAriaLabelClose?: string
  menuOptions?: SecondaryTopBarMenuOption[]
}

interface ToggleMenuProps extends Omit<SecondaryTopBarMenuProps, 'menuOptions'> {
  isMenuOpen: boolean
  setIsMenuOpen: (open: boolean) => void
  'aria-controls': string
}

interface InfoButtonProps {
  onInfoButtonClick?: () => void
  infoButtonLabel?: string
}

export interface SecondaryTopBarProps extends InfoButtonProps {
  title?: string
  description?: string
  onPreviousButtonClick?: () => void
  previousButtonLabel?: string
  small?: boolean
  menu?: SecondaryTopBarMenuProps
  hideBoxShadow?: boolean
  hideButtonLabelsInMobileBreakpoint?: boolean
  removeSidePadding?: boolean
  titleTagName?: keyof JSX.IntrinsicElements
}

export const SecondaryTopBar = ({
  onPreviousButtonClick,
  previousButtonLabel,
  title,
  onInfoButtonClick,
  infoButtonLabel,
  small,
  description,
  menu = {},
  hideBoxShadow,
  hideButtonLabelsInMobileBreakpoint = true,
  removeSidePadding = false,
  titleTagName = 'h1',
}: SecondaryTopBarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenuId = `toggle-menu-${useId()}`
  const { menuOptions, menuAriaLabelOpen, menuAriaLabelClose } = menu

  const mobileSize = small ? 'small' : 'medium'

  const isDesktopSize = useMediaQuery(`(min-width:${BREAKPOINT_DESKTOP_PX}px)`)
  const isMobileSize = useMediaQuery(`(max-width:${BREAKPOINT_NARROW_MOBILE_PX}px)`)

  const showButtonLabels = !hideButtonLabelsInMobileBreakpoint || isDesktopSize

  return (
    <TopBarContainer $hideBoxShadow={hideBoxShadow}>
      <TopBarWrapper $small={small}>
        <ItemWrapper $removeSidePadding={removeSidePadding}>
          {onPreviousButtonClick && (
            <LinkButton
              icon={<LeftChevronIcon />}
              onClick={onPreviousButtonClick}
              data-testid="step-header-previous-button"
              aria-label={assertValue(previousButtonLabel, 'previousButtonLabel')}
              className={PAGE_BACK_BUTTON_CLASS}
              color="neutral"
              compact={true}
            >
              {showButtonLabels && <MediumText>{previousButtonLabel}</MediumText>}
            </LinkButton>
          )}
        </ItemWrapper>
        <ItemWrapper aria-live="polite" $noPointerEvents={true}>
          {title && (
            <MiddleText
              $color="primary"
              sizing={isMobileSize ? mobileSize : 'large'}
              weight="medium"
              className={PAGE_TITLE_CLASS}
              tabIndex={-1}
              tagName={titleTagName}
            >
              {title}
            </MiddleText>
          )}
          {description && <SmallText $color="secondary">{description}</SmallText>}
        </ItemWrapper>
        <ItemWrapper $removeSidePadding={removeSidePadding}>
          {menuOptions && (
            <ToggleMenu
              aria-controls={toggleMenuId}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              menuAriaLabelOpen={menuAriaLabelOpen}
              menuAriaLabelClose={menuAriaLabelClose}
            />
          )}
          {onInfoButtonClick && (
            <InfoButton
              infoButtonLabel={infoButtonLabel}
              onInfoButtonClick={onInfoButtonClick}
              showButtonLabel={showButtonLabels}
            />
          )}
        </ItemWrapper>
      </TopBarWrapper>
      {isMenuOpen && (
        <MenuContent id={toggleMenuId}>
          {menuOptions?.map((menuOption) => (
            <MenuOption key={menuOption.text}>
              <Link standalone={true} onClick={menuOption.onClick}>
                <LargeText $color={menuOption.textColor ?? 'primary'}>{menuOption.text}</LargeText>
              </Link>
            </MenuOption>
          ))}
        </MenuContent>
      )}
    </TopBarContainer>
  )
}

const ToggleMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  'aria-controls': ariaControls,
  menuAriaLabelOpen,
  menuAriaLabelClose,
}: ToggleMenuProps) => {
  const onToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <MenuToggleWrapper>
      <Button
        aria-label={isMenuOpen ? menuAriaLabelClose : menuAriaLabelOpen}
        aria-expanded={isMenuOpen}
        aria-controls={ariaControls}
        compact={true}
        color="neutral"
        icon={isMenuOpen ? <CloseIcon /> : <KebabMenuIcon />}
        onClick={onToggle}
        variant="plain"
      />
    </MenuToggleWrapper>
  )
}

const InfoButton = ({
  infoButtonLabel,
  onInfoButtonClick,
  showButtonLabel,
}: InfoButtonProps & { showButtonLabel: boolean }) => {
  return (
    <Button
      aria-label={!showButtonLabel ? infoButtonLabel : undefined}
      color="neutral"
      compact={true}
      data-testid="step-header-info-button"
      icon={<InfoIcon style={{ color: Colors.ElementDefaultNeutral }} />}
      label={showButtonLabel ? infoButtonLabel : undefined}
      onClick={onInfoButtonClick}
      variant="plain"
    />
  )
}
