import * as React from 'react'
import { styled } from 'styled-components'

import { RemoveItemButton } from '../actions/special/RemoveItemButton'
import { MutedSmallText, SmallHeading } from '../decorative/Typography'
import { FlexColumnContainer } from '../global/CommonStyling'
import { getIndexedLabel } from '../utilities/textHelpers'

const SmallHeadingWithRemoveIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SmallHeadingWithNoMargin = styled(SmallHeading)`
  margin: unset;
`

export interface FormRemovableSectionHeaderProps {
  index: number
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  heading: string
  headingDescription?: string
  testID: string
  dynamicArrayLength: number
  removeActionLabel: string
  id?: string
}

export const FormRemovableSectionHeader = ({
  index,
  onClick,
  heading,
  headingDescription,
  testID,
  dynamicArrayLength,
  removeActionLabel,
  id,
}: FormRemovableSectionHeaderProps) => {
  const formattedHeading = getIndexedLabel(heading, index, dynamicArrayLength)
  return (
    <SmallHeadingWithRemoveIconContainer>
      <FlexColumnContainer>
        <SmallHeadingWithNoMargin $color="primary" id={id}>
          {formattedHeading}
        </SmallHeadingWithNoMargin>
        {headingDescription && <MutedSmallText>{headingDescription}</MutedSmallText>}
      </FlexColumnContainer>
      {index > 0 && (
        <RemoveItemButton
          type="button"
          data-testid={testID && `${testID}-remove-button`}
          onClick={onClick}
          label={removeActionLabel}
          aria-label={`${removeActionLabel} ${formattedHeading}`}
        />
      )}
    </SmallHeadingWithRemoveIconContainer>
  )
}
