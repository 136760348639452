import { RadioButtonGroup } from '@s-group/design-system-components/RadioButtonGroup/RadioButtonGroup'
import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import classNames from 'classnames'
import { Children, forwardRef, ReactNode } from 'react'
import { styled } from 'styled-components'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import { IndentationText } from '../decorative/IndentationText'
import { MediumText } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { InputFieldWrapper, PaddedDiv } from '../global/CommonStyling'
import { FormGroupOption, FormGroupProps } from '../global/ComponentProps'
import { buildAriaAttributes } from '../global/global'
import { SPACE_XSMALL, SPACE_XXXXSMALL } from '../global/spacing'
import { parseComponentId } from '../utilities/helpers'
import { Radio } from './primitive/Radio'

const BorderedPaddedDiv = styled(PaddedDiv)`
  margin-top: 3px;
  border-radius: 8px;
  padding: 0;

  border: 1px solid ${Colors.BorderWeakNeutral};
  &.error {
    border: 1px solid ${Colors.BorderError};
  }

  > div {
    &:hover {
      background-color: ${Colors.BackgroundWeakNeutral};

      > .visible-radio:not(~ input:disabled) {
        background-color: ${Colors.White} !important;
      }
    }

    &.focus {
      outline: 2px solid #4d74fe;
    }

    cursor: pointer;
    border-bottom: 1px solid ${Colors.BorderWeakNeutral};
  }

  > div:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  > div:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom: none;
  }
`

const RadioGroupWrapper = styled.div`
  word-break: break-word;
`

const StyledErrorAndDescriptionRow = styled(ErrorAndDescriptionRow)`
  margin-top: ${SPACE_XXXXSMALL};
`

const DescriptionWrapper = styled.div`
  margin-top: ${SPACE_XSMALL};
`

const FieldSetDescription = (props: { indented: boolean; children: ReactNode; parentId: string }) => {
  const Wrapper = props.indented ? IndentationText : DescriptionWrapper

  return (
    <Wrapper>
      <MediumText tagName="div" id={`${props.parentId}-description`}>
        {props.children}
      </MediumText>
    </Wrapper>
  )
}

export const FormRadioGroup = forwardRef<HTMLInputElement, FormGroupProps>(
  (props: FormGroupProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      id,
      name,
      required,
      options,
      optionsDisabledStates = Array(options.length).fill(false),
      onChange,
      onBlur: originalOnBlur,
      border = true,
      additionalDescriptionForFieldset,
      additionalDescriptionForFieldsetIndented = true,
      error,
      description,
    } = props

    const { children, ...withoutChildren } = props

    const overriddenId = parseComponentId(name, id)
    const StyledPaddedDiv = border ? BorderedPaddedDiv : PaddedDiv
    const hasError = !!error

    const overriddenOnBlur = async (event: React.FocusEvent<HTMLInputElement, HTMLInputElement>) => {
      const { relatedTarget, target } = event
      if (originalOnBlur && relatedTarget?.name !== target?.name) {
        originalOnBlur(event)
      }
    }

    // In some cases children provided by parent is just an array of falsy values which we don't want to render
    // Children.toArray automatically filters those falsy values out from the array
    const childrenToRender = Children.toArray(children)

    const radioGroup = options.map((optionProps: FormGroupOption, index: number) => {
      return (
        <Radio
          {...withoutChildren}
          id={overriddenId}
          value={optionProps.key}
          disabled={optionsDisabledStates[index]}
          name={overriddenId}
          ref={ref}
          onChange={onChange}
          onBlur={overriddenOnBlur}
          key={`${overriddenId}-${optionProps.key}`}
          description={optionProps.description}
          label={optionProps.label}
        />
      )
    })

    const fieldSetId = 'fieldset-' + overriddenId
    return (
      <RadioGroupWrapper>
        <RadioButtonGroup
          role="radiogroup"
          data-testid={fieldSetId}
          id={fieldSetId}
          {...buildAriaAttributes(
            overriddenId,
            !!required,
            !!description,
            !!error,
            additionalDescriptionForFieldset ? `${fieldSetId}-description` : undefined,
          )}
        >
          <InputFieldWrapper>
            {props.label && (
              <Label
                as="legend"
                isRequired={props.required}
                label={props.label}
                id={overriddenId}
                description={description}
              />
            )}
            <StyledPaddedDiv className={classNames({ error: hasError })}>{radioGroup}</StyledPaddedDiv>
            {additionalDescriptionForFieldset && (
              <FieldSetDescription indented={additionalDescriptionForFieldsetIndented} parentId={fieldSetId}>
                {additionalDescriptionForFieldset}
              </FieldSetDescription>
            )}
            <StyledErrorAndDescriptionRow id={overriddenId} error={error} />
          </InputFieldWrapper>
        </RadioButtonGroup>
        {/* Don't render IndentedFormFieldContainer wrapper when there is nothing to render */}
        {!!childrenToRender.length && <IndentedFormFieldContainer>{childrenToRender}</IndentedFormFieldContainer>}
      </RadioGroupWrapper>
    )
  },
)

FormRadioGroup.displayName = 'FormRadioGroup'
