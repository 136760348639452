export enum ModalVisualMode {
  Plain = 'plain',
  Happy = 'happy',
  Info = 'info',
  Check = 'check',
  Warn = 'warn',
  Error = 'error',
  Danger = 'danger',
  Encouragement = 'encouragement',
  Help = 'help',
  ControlLock = 'control-lock',
  TechnicalError = 'technical-error',
  Banned = 'banned',
  Sad = 'sad',
}
