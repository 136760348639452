export const YEARS_SINCE_BUILT_TO_ASK_ABOUT_PIPES = 25
export const MINIMUM_BUILD_YEAR = 1850
export const MAXIMUM_BUILD_YEAR = 2100

const SHORT_TEXTAREA_MAX_LENGTH = 500
const MEDIUM_TEXTAREA_MAX_LENGTH = 1000
const LONG_TEXTAREA_MAX_LENGTH = 2000

export const RENOVATIONS_HISTORY_MAX_LENGTH = SHORT_TEXTAREA_MAX_LENGTH
export const PLANNED_RENOVATIONS_MAX_LENGTH = MEDIUM_TEXTAREA_MAX_LENGTH
export const RENOVATION_NEEDS_MAX_LENGTH = SHORT_TEXTAREA_MAX_LENGTH

export const SHORT_TEXT_INPUT_MAX_LENGTH = 50
export const ROOM_LAYOUT_MAX_LENGTH = SHORT_TEXT_INPUT_MAX_LENGTH
export const SALES_SITE_OTHER_MAX_LENGTH = SHORT_TEXT_INPUT_MAX_LENGTH
export const SALES_SITE_ID_MAX_LENGTH = SHORT_TEXT_INPUT_MAX_LENGTH
export const PURCHASE_LOAN_OTHER_PURPOSE_MAX_LENGTH = SHORT_TEXT_INPUT_MAX_LENGTH

export const REAL_ESTATE_ID_MAX_LENGTH = 25

export const MINIMUM_MOVE_IN_YEAR_IN_PAST = 1900

export const PRECISE_LOCATION_MAX_LENGTH = 100

export const ADDITIONAL_INFO_MAX_LENGTH = LONG_TEXTAREA_MAX_LENGTH
export const LOAN_INFO_ADDITIONAL_INFO_MAX_LENGTH = MEDIUM_TEXTAREA_MAX_LENGTH

export const RENOVATION_ADDITIONAL_INFO_MAX_LENGTH = MEDIUM_TEXTAREA_MAX_LENGTH

export const COLLATERAL_ADDITIONAL_INFO_MAX_LENGTH = SHORT_TEXTAREA_MAX_LENGTH
export const OTHER_INCOME_DESCRIPTION_MAX_LENGTH = SHORT_TEXTAREA_MAX_LENGTH

export const MIN_NUMBER_OF_APARTMENTS = 1
export const MAX_NUMBER_OF_APARTMENTS = 999

export const MIN_NUMBER_OF_FLOORS = 1
export const MAX_NUMBER_OF_FLOORS_OPTION = 3
export const MAX_NUMBER_OF_FLOORS = 99

export const MAX_NUMBER_OF_ROOMS_OPTION = 4

export const CAMPAIGN_CODE_MAX_LENGTH = 20

export const POST_OFFICE_MAX_LENGTH = 30

export const MIN_OWNERSHIP_SHARE = 0.01
export const MAX_OWNERSHIP_SHARE = 99.99

export const MIN_NUMBER_OF_CHILDREN = 0
export const MAX_NUMBER_OF_CHILDREN = 100

export const MIN_LOT_SQUARE_AREA = 1
export const MAX_LOT_SQUARE_AREA = 9_999_999

export const MAX_GUARANTEES_AMOUNT = 5
export const MAX_OTHER_INCOMES_AMOUNT = 10
export const MAX_INVESTMENTS_AMOUNT = 10
