import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import { AriaRole, ReactElement, ReactNode } from 'react'
import { styled } from 'styled-components'

import { ColoredLabel, ColoredLabelProps } from '../dataDisplay/ColoredLabel'
import { LoadingSpinner } from '../decorative/LoadingSpinner'
import { ExtraSmallHeading, MediumText, SmallHeading, SmallText } from '../decorative/Typography'
import { BREAKPOINT_LARGE_DESKTOP_PX, COMMON_PLACEHOLDER } from '../global/constants'
import { SPACE_XXXSMALL } from '../global/spacing'
import { TwoColumnLayout } from '../layout/TwoColumnLayout'

export type SummaryLayout = 'full' | 'concise'

// After react 18 upgrade we could set id optional and use useId()
// https://reactjs.org/docs/hooks-reference.html#useid
interface DataSummaryItemProps {
  coloredLabel?: ColoredLabelProps
  testId?: string
  id: string
  label: string
  description?: string
  value: string | ReactNode
  children?: ReactNode
  mode?: SummaryLayout
  labelId?: string
  async?: {
    isLoading: boolean
    loadingText: string
  }
  role?: AriaRole
  ['aria-labelledby']?: string
}

const SummaryItemContainer = styled.div`
  overflow-wrap: break-word;
`

const LabelSection = ({ label, description, id }: { label: string; description?: string; id: string }): JSX.Element => {
  return (
    <>
      <MediumText $color="primary" weight="medium" tagName="div" id={`${id}-label`}>
        {label}
      </MediumText>
      {description && (
        <SmallText $color="secondary" tagName="div">
          {description}
        </SmallText>
      )}
    </>
  )
}

const DataSummaryItemUnstyled = ({
  id,
  label,
  labelId,
  description,
  testId,
  value,
  coloredLabel,
  children,
  async,
  mode = 'full',
  ...rest
}: DataSummaryItemProps) => {
  const isFullMode = mode === 'full'

  let renderedValue: ReactNode | string
  if (async?.isLoading) {
    renderedValue = <LoadingSpinner size="small" alt={async.loadingText} />
  } else {
    renderedValue = typeof value !== 'undefined' && value !== '' && value !== null ? value : COMMON_PLACEHOLDER
  }

  return (
    <SummaryItemContainer data-testid={testId ?? label} {...rest}>
      {coloredLabel && <ColoredLabel color={coloredLabel.color} text={coloredLabel.text} />}
      {isFullMode && <LabelSection id={labelId ?? id} label={label} description={description} />}
      <div style={{ minHeight: '25px' }}>
        <MediumText>{renderedValue}</MediumText>
      </div>
      {children && <IndentedFormFieldContainer>{children}</IndentedFormFieldContainer>}
    </SummaryItemContainer>
  )
}

export const DataSummaryItem = styled(DataSummaryItemUnstyled)``

interface DataSummarySubheadingProps {
  children: ReactNode
}

const DataSummarySubheadingUnstyled = ({ children }: DataSummarySubheadingProps) => {
  return <ExtraSmallHeading>{children}</ExtraSmallHeading>
}

export const DataSummarySubheading = styled(DataSummarySubheadingUnstyled)`
  padding-bottom: 18px;
`

interface DataSummaryProps {
  children:
    | ReactElement<typeof DataSummaryItem>
    | ReactElement<typeof DataSummaryItem>[]
    | ReactElement<typeof DataSummarySubheading>
    | ReactElement<typeof DataSummarySubheading>[]
    | ReactNode
  heading?: string
  editLink?: ReactNode
  className?: string
  largeScreenSupport?: boolean
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const HeadingContainer = styled(SmallHeading)`
  margin: 0;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    margin-bottom: ${SPACE_XXXSMALL};
  }
`

const EditLinkContainer = styled.div`
  justify-self: end;
`

const LegacyHeadingContainer = styled.div`
  margin-bottom: 32px;
`

const LegacyEditLinkContainer = styled.div`
  float: right;
  margin-top: -7px;
`

const DataContainer = styled.div`
  flex-direction: column;

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const DataSummaryUnstyled = ({
  children,
  className,
  heading,
  editLink = null,
  largeScreenSupport = false,
}: DataSummaryProps) => {
  return (
    <div className={className}>
      {largeScreenSupport ? (
        <TwoColumnLayout
          leftContent={
            <HeaderContainer>
              {heading && <HeadingContainer $color="primary">{heading}</HeadingContainer>}
              {editLink && <EditLinkContainer>{editLink}</EditLinkContainer>}
            </HeaderContainer>
          }
          rightContent={<DataContainer>{children}</DataContainer>}
        />
      ) : (
        <>
          {editLink && <LegacyEditLinkContainer>{editLink}</LegacyEditLinkContainer>}
          {heading && (
            <LegacyHeadingContainer>
              <SmallHeading $color="primary">{heading}</SmallHeading>
            </LegacyHeadingContainer>
          )}
          {children}
        </>
      )}
    </div>
  )
}

// Prefer StepSummaryView
export const DataSummary = styled(DataSummaryUnstyled)`
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
