import { TextArea, TextAreaProps } from '@s-group/design-system-components/TextArea/TextArea'
import { TextInput, TextInputProps } from '@s-group/design-system-components/TextInput/TextInput'
import classNames from 'classnames'
import { forwardRef, RefAttributes } from 'react'
import { css, styled } from 'styled-components'

import { InputFieldProps } from '../../global/ComponentProps'
import { DEFAULT_TEXT_INPUT_MAX_LENGTH } from '../../global/constants'
import { buildAriaAttributes } from '../../global/global'

const genericFormStyle = css`
  margin-top: 3px;
  flex-grow: 1;
  min-width: 0;
`

const TypedDSTextInput = TextInput as React.ComponentType<TextInputProps & RefAttributes<HTMLInputElement>>
const TypedDSTextArea = TextArea as React.ComponentType<TextAreaProps & RefAttributes<HTMLTextAreaElement>>

const UnstyledTextInputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      id = '',
      name,
      className,
      disabled,
      readOnly,
      defaultValue,
      onBlur,
      maxLength = 100,
      type = 'text',
      inputMode = 'text',
      placeholder = '',
      required = false,
      tabIndex = 0,
      onChange,
      onKeyDown,
      autoComplete,
      error,
      description,
    },
    ref,
  ) => (
    <TypedDSTextInput
      data-testid={id + '-input'}
      ref={ref}
      id={id}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      maxLength={maxLength ?? undefined}
      className={classNames(className, { error: !!error })}
      type={type}
      inputMode={inputMode}
      tab-index={tabIndex}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoComplete={autoComplete}
      onBlur={onBlur}
      error={!!error}
      rounding={'small'}
      sizing={'small'}
      {...buildAriaAttributes(id, required, !!description, !!error)}
    />
  ),
)

UnstyledTextInputField.displayName = 'TextInputField'

const UnstyledTextAreaField = forwardRef<HTMLTextAreaElement, InputFieldProps>(
  (
    {
      id = '',
      name,
      className,
      disabled,
      defaultValue,
      onBlur,
      maxLength = DEFAULT_TEXT_INPUT_MAX_LENGTH,
      placeholder = '',
      required = false,
      tabIndex = 0,
      onChange,
      onKeyDown,
      autoComplete,
      error,
      description,
    }: InputFieldProps,
    ref,
  ) => (
    <TypedDSTextArea
      ref={ref}
      id={id}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      maxLength={maxLength ?? undefined}
      className={classNames(className, { error: !!error })}
      tab-index={tabIndex}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoComplete={autoComplete}
      onBlur={onBlur}
      rounding={'small'}
      error={!!error}
      rows={2}
      {...buildAriaAttributes(id, required, !!description, !!error)}
    />
  ),
)

UnstyledTextAreaField.displayName = 'TextAreaField'

export const TextInputField = styled(UnstyledTextInputField)`
  ${genericFormStyle}
`

export const TextAreaField = styled(UnstyledTextAreaField)`
  ${genericFormStyle}
`
