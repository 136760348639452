import { CheckboxGroup } from '@s-group/design-system-components/CheckboxGroup/CheckboxGroup'
import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import classNames from 'classnames'
import { forwardRef } from 'react'
import { styled } from 'styled-components'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import { Colors } from '../global/colors'
import { PaddedDiv } from '../global/CommonStyling'
import { FormGroupOption, FormGroupProps } from '../global/ComponentProps'
import { ROUNDING_DEFAULT } from '../global/constants'
import { buildAriaAttributes } from '../global/global'
import { SPACE_XXXXSMALL } from '../global/spacing'
import { parseComponentId, removeKeysFromObject } from '../utilities/helpers'
import { FormLabelledCheckbox } from './FormLabelledCheckbox'

const BorderedPaddedDiv = styled(PaddedDiv)`
  margin-top: 3px;
  border-radius: 8px;
  padding: 0;
  border: 1px solid ${Colors.BorderWeakNeutral};
  &.error {
    border: 1px solid ${Colors.BorderError};
  }

  > {
    :first-child {
      border-top-left-radius: ${ROUNDING_DEFAULT}px;
      border-top-right-radius: ${ROUNDING_DEFAULT}px;
    }
    :last-child {
      border-bottom-left-radius: ${ROUNDING_DEFAULT}px;
      border-bottom-right-radius: ${ROUNDING_DEFAULT}px;
    }
    :hover:not(.disabled) {
      background-color: ${Colors.BackgroundWeakNeutral};

      span {
        background-color: ${Colors.White} !important;
      }
    }

    > {
      :first-child {
        border-bottom: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      :last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    label {
      cursor: pointer;

      > div.focus {
        border: 2px solid #4d74fe;
        margin: -2px;
      }
    }
  }

  > {
    :not(:last-child) {
      border-bottom: 1px solid ${Colors.BorderWeakNeutral};
    }
  }
`

const CheckboxGroupWrapper = styled.div`
  padding-top: 10px;
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  && {
    gap: 0;
  }
`

const StyledErrorAndDescriptionRow = styled(ErrorAndDescriptionRow)`
  margin-top: ${SPACE_XXXXSMALL};
`

export const FormCheckboxGroup = forwardRef<HTMLInputElement, FormGroupProps>((props: FormGroupProps, ref) => {
  const {
    id,
    name,
    required,
    options,
    border = false,
    side = 'left',
    optionsDisabledStates = Array(options.length).fill(false),
    onBlur: originalOnBlur,
    error,
  } = props

  const { children, ...withoutChildren } = props

  const overriddenId = parseComponentId(name, id)
  const StyledPaddedDiv = border ? BorderedPaddedDiv : PaddedDiv
  const overridesHandledByGroup = { required: false, border: false, error: undefined }
  const hasError = !!error
  const hasDescription = !!props.description

  const overriddenOnBlur = async (event: React.FocusEvent<HTMLInputElement, HTMLInputElement>) => {
    const { relatedTarget, target } = event
    if (originalOnBlur && relatedTarget?.name !== target?.name) {
      originalOnBlur(event)
    }
  }

  const checkboxGroup = options.map(function ({ key, label, description }: FormGroupOption, index: number) {
    return (
      <FormLabelledCheckbox
        {...withoutChildren}
        ref={ref}
        id={`${overriddenId}-option-${key}`}
        label={label}
        key={`${overriddenId}-option-${key}`}
        value={key}
        onBlur={overriddenOnBlur}
        disabled={optionsDisabledStates[index]}
        $shouldCenterItemsVertically={true}
        $isGrouped={true}
        groupId={overriddenId}
        {...overridesHandledByGroup}
        description={description}
      />
    )
  })

  const ariaAttributes = removeKeysFromObject(
    'aria-required',
    buildAriaAttributes(overriddenId, false, hasDescription, hasError),
  )

  return (
    <CheckboxGroupWrapper>
      <StyledCheckboxGroup data-testid={`fieldset-${overriddenId}`} {...ariaAttributes}>
        {props.label && (
          <Label
            as="legend"
            isRequired={required}
            label={props.label}
            id={overriddenId}
            description={props.description}
          />
        )}
        <StyledPaddedDiv className={classNames({ error: hasError, right: side === 'right' })}>
          {checkboxGroup}
        </StyledPaddedDiv>
        <StyledErrorAndDescriptionRow error={error} id={overriddenId} />
      </StyledCheckboxGroup>
      {children && <IndentedFormFieldContainer>{children}</IndentedFormFieldContainer>}
    </CheckboxGroupWrapper>
  )
})

FormCheckboxGroup.displayName = 'FormCheckboxGroup'
