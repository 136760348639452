import * as R from 'remeda'

import { assertValue } from '../helpers'
import { getTotalAspSavings } from './calculation-helpers'
import {
  FINANCING_THRESHOLD_FOR_COLLATERAL_REQUIREMENT,
  HOUSING_COMPANY_SHAREHOLDER_TAX_RATE,
  PERCENTAGE_PRECISION_MULTIPLIER,
  REAL_ESTATE_TAX_RATE,
} from './constants'
import {
  CurrentResidenceInfoBase,
  LoanCostEstimateInfoData,
  SecLoanApplicationData,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansHousingLoanTargetType,
  SecLoansResidenceType,
  TargetInfoData,
} from './types'

export interface LoanTimeInYearsAndMonths {
  years: number
  months: number
}

export const calculateLoanTimeInMonths = (
  loanAmount: number,
  interest: number,
  approximatedRepayment: number,
): number => {
  const months =
    (Math.log(approximatedRepayment) - Math.log(approximatedRepayment - (interest * loanAmount) / 12)) /
    Math.log(1 + interest / 12)
  return months
}

export const convertMonthsToYearsAndMonths = (loanTimeInMonths: number): LoanTimeInYearsAndMonths => {
  let years = Math.floor(loanTimeInMonths / 12)
  let months = Math.ceil(loanTimeInMonths % 12)
  if (months === 12) {
    years += 1
    months = 0
  }
  return { years, months }
}

interface MonthlyRepaymentValues {
  monthlyInstallment: number | undefined
  monthlyPrincipalAmount: number | undefined
  monthlyInterestAmount: number
}

export const calculateMonthlyRepayment = (
  loanAmount: number,
  yearlyInterest: number,
  loanTimeInYears: number | undefined,
): MonthlyRepaymentValues => {
  //EMI = PxRx(1+R)^N / [(1+R)^N-1]
  //P = Principal loan amount
  //R = Monthly interest rate
  //N = Loan tenure in months
  const monthlyInterestRate = yearlyInterest / 12

  let loanTimeInMonths: number | undefined
  let equatedMonthlyInstallment: number | undefined

  if (R.isNumber(loanTimeInYears)) {
    loanTimeInMonths = loanTimeInYears * 12
    equatedMonthlyInstallment =
      (loanAmount * monthlyInterestRate * (1 + monthlyInterestRate) ** loanTimeInMonths) /
      ((1 + monthlyInterestRate) ** loanTimeInMonths - 1)
  }

  const { monthlyPrincipalAmount, monthlyInterestAmount } = calculateMonthlyInterestAmountAndMonthlyPrincipalAmount(
    equatedMonthlyInstallment,
    loanAmount,
    monthlyInterestRate,
  )

  return { monthlyInstallment: equatedMonthlyInstallment, monthlyPrincipalAmount, monthlyInterestAmount }
}

export const calculateMonthlyInterestAmountAndMonthlyPrincipalAmount = (
  equatedMonthlyInstallment: number | undefined,
  loanAmount: number,
  yearlyInterest: number,
): Omit<MonthlyRepaymentValues, 'monthlyInstallment'> => {
  //This is only estimation and usable in this case because we are only estimating to users. The correct
  // definite formula would have monthlyInterestAmount reduced monthly since loan amount is reduced after each payment
  const monthlyInterestRate = yearlyInterest / 12
  const monthlyInterestAmount = loanAmount * monthlyInterestRate
  const monthlyPrincipalAmount = R.isNumber(equatedMonthlyInstallment)
    ? equatedMonthlyInstallment - monthlyInterestAmount
    : undefined

  return {
    monthlyInterestAmount,
    monthlyPrincipalAmount,
  }
}

export const calculateEqualAmortization = (
  loanAmount: number,
  yearlyInterest: number,
  loanTimeInYears: number | undefined,
): MonthlyRepaymentValues => {
  const loanTimeInMonths = R.isNumber(loanTimeInYears) ? loanTimeInYears * 12 : undefined
  const monthlyInterestRate = yearlyInterest / 12
  const monthlyInterestAmount = monthlyInterestRate * loanAmount
  const monthlyPrincipalAmount = R.isNumber(loanTimeInMonths) ? loanAmount / loanTimeInMonths : undefined
  const monthlyInstallment = R.isNumber(monthlyPrincipalAmount)
    ? monthlyPrincipalAmount + monthlyInterestAmount
    : undefined

  return {
    monthlyInstallment,
    monthlyPrincipalAmount,
    monthlyInterestAmount,
  }
}

const getTransferTaxRate = (applicationPurpose: SecLoanPurposeType, targetInfo: TargetInfoData): number => {
  const targetType = targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType
  const residenceType = targetInfo?.residenceInfoData?.residenceType
  const applicationPurposeIsConstruction = applicationPurpose === SecLoanPurposeType.Construction
  const targetTypeIsNotKnown = targetType === SecLoansHousingLoanTargetType.NotKnown
  const targetTypeIsRealEstate = targetType === SecLoansHousingLoanTargetType.RealEstate
  const residenceTypeIsTownHouse = residenceType === SecLoansResidenceType.TownHouse
  const residenceTypeIsRealEstate = residenceType === SecLoansResidenceType.RealEstate

  if (targetTypeIsNotKnown && (residenceTypeIsTownHouse || residenceTypeIsRealEstate)) {
    return REAL_ESTATE_TAX_RATE
  }

  if (applicationPurposeIsConstruction || targetTypeIsRealEstate) {
    return REAL_ESTATE_TAX_RATE
  }

  return HOUSING_COMPANY_SHAREHOLDER_TAX_RATE
}

const getTaxableAmount = (applicationPurpose: SecLoanPurposeType, targetInfo: TargetInfoData) => {
  const targetType = targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType
  const isTargetTypeRightOfResidence = targetType === SecLoansHousingLoanTargetType.RightOfResidence
  const applicationPurposeIsNotBuy = applicationPurpose !== SecLoanPurposeType.Buy
  const applicationPurposeIsConstruction = applicationPurpose === SecLoanPurposeType.Construction

  if (applicationPurposeIsConstruction) {
    return targetInfo?.loanCostEstimateInfo?.plotSellingPrice ?? 0
  }

  if (applicationPurposeIsNotBuy || isTargetTypeRightOfResidence) {
    return 0
  }

  return targetInfo.priceInfo?.netSellingPrice ?? assertValue(targetInfo.priceInfo?.price, 'targetInfo.priceInfo.price')
}

export const getTransferTaxValue = (applicationPurpose: SecLoanPurposeType, targetInfo: TargetInfoData): number => {
  const taxableAmount = getTaxableAmount(applicationPurpose, targetInfo)
  const transferTaxRate = getTransferTaxRate(applicationPurpose, targetInfo)
  return roundNumberToFractionDigits(taxableAmount * transferTaxRate)
}

export const getDifference = (minuend: number, subtrahend: number): number => {
  if (subtrahend > minuend) {
    return 0
  }
  return (minuend * 1000 - subtrahend * 1000) / 1000
}

export const calculateCost = (
  applicationPurpose: SecLoanPurposeType,
  targetInfo: TargetInfoData,
  includeTransferTax = true,
): number | undefined => {
  const { priceInfo, renovationsInfoData, loanCostEstimateInfo } = targetInfo ?? {}
  const { price, netSellingPrice, isPayingCompanyLoanShare } = priceInfo ?? {}
  const { renovationsAmount = 0, otherPurchasesAmount = 0 } = renovationsInfoData ?? {}
  const { costEstimate = 0, plotSellingPrice = 0 } = loanCostEstimateInfo ?? {}

  const transferTaxAmount = includeTransferTax ? getTransferTaxValue(applicationPurpose, targetInfo) : 0

  if (applicationPurpose === SecLoanPurposeType.Construction) {
    return costEstimate + transferTaxAmount + plotSellingPrice
  }

  if (price === undefined) {
    return undefined
  }

  const companyLoanAmount = isPayingCompanyLoanShare && netSellingPrice ? getDifference(netSellingPrice, price) : 0

  return price + transferTaxAmount + renovationsAmount + otherPurchasesAmount + companyLoanAmount
}

export const isCollateralRequired = (
  applicationType: SecLoanApplicationType,
  applicationPurpose: SecLoanPurposeType,
  applicationData: SecLoanApplicationData,
): boolean => {
  if (applicationType === SecLoanApplicationType.PurchaseLoan) {
    return true
  }
  if (applicationPurpose === SecLoanPurposeType.Renovation) {
    return true
  }

  if (!applicationData.targetInfo) {
    return false
  }
  if (applicationPurpose === SecLoanPurposeType.LoanTransferToSbank) {
    return isCollateralRequiredForLoanTransfer(applicationData.targetInfo)
  }

  const fullLoanAmount = getLoanAmountToUseForCollateralCalculations(applicationPurpose, applicationData.targetInfo)
  if (!fullLoanAmount) {
    return true
  }

  const ownMoney = getExistingCollaterals(applicationData)

  const priceInCents = fullLoanAmount * 100
  const ownMoneyInCents = ownMoney * 100
  const transferTaxValueInCents = getTransferTaxValue(applicationPurpose, applicationData.targetInfo) * 100

  const scaledCollateralThreshold =
    PERCENTAGE_PRECISION_MULTIPLIER - FINANCING_THRESHOLD_FOR_COLLATERAL_REQUIREMENT * PERCENTAGE_PRECISION_MULTIPLIER
  const thresholdAmountCents = (scaledCollateralThreshold * priceInCents) / PERCENTAGE_PRECISION_MULTIPLIER

  return ownMoneyInCents < thresholdAmountCents + transferTaxValueInCents
}

export const isCollateralRequiredForLoanTransfer = (targetInfo: TargetInfoData): boolean => {
  const resaleEstimate = targetInfo?.housingLoanInfo?.transferredLoanTargetData?.resaleEstimate ?? 0
  const loanAmount = targetInfo?.housingLoanInfo?.transferredLoanTargetData?.loanAmount ?? 0

  const resaleEstimateInCents = resaleEstimate * 100
  const loanAmountInCents = loanAmount * 100

  const scaledCollateralThreshold =
    PERCENTAGE_PRECISION_MULTIPLIER -
    (1 - FINANCING_THRESHOLD_FOR_COLLATERAL_REQUIREMENT) * PERCENTAGE_PRECISION_MULTIPLIER

  const thresholdAmountCents = (scaledCollateralThreshold * resaleEstimateInCents) / PERCENTAGE_PRECISION_MULTIPLIER

  return thresholdAmountCents < loanAmountInCents
}

export const calculateTotalRealEstateLivingExpenses = (
  monthlyHeatingExpense: number | undefined,
  otherMonthlyExpenses: number | undefined,
  yearlyExpense?: number,
): number => {
  const yearlyValue = yearlyExpense ? yearlyExpense / 12 : 0
  return (monthlyHeatingExpense ?? 0) + (otherMonthlyExpenses ?? 0) + yearlyValue
}

export const roundNumberToFractionDigits = (number: number, fractionDigits = 2): number => {
  return parseFloat(number.toFixed(fractionDigits))
}

export const getLoanAmountToUseForCollateralCalculations = (
  applicationPurpose: SecLoanPurposeType,
  targetInfo: TargetInfoData,
): number | undefined => {
  if (applicationPurpose === SecLoanPurposeType.LoanTransferToSbank) {
    return targetInfo?.housingLoanInfo?.transferredLoanTargetData?.loanAmount
  }

  return calculateCost(applicationPurpose, targetInfo, false)
}

export const getSaleIncomeAndRemainingLoan = (
  currentResidenceInfoDatas: (CurrentResidenceInfoBase | undefined | null)[],
): { saleIncomeBefore: number; saleIncomeAfter: number; remainingLoan: number } => {
  let saleIncomeBefore = 0
  let saleIncomeAfter = 0
  let remainingLoan = 0

  for (const currentResidenceInfoData of currentResidenceInfoDatas) {
    const {
      sellingPlan,
      remainingLoanAmount = 0,
      price: sellingPrice = 0,
      expenseShareForNewFlatAmount: amountToBeUsedFromSellingPrice,
    } = currentResidenceInfoData ?? {}

    if (sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst) {
      const propertySaleContribution = amountToBeUsedFromSellingPrice ?? sellingPrice - remainingLoanAmount
      saleIncomeBefore += propertySaleContribution ?? 0
    } else if (sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingAfter) {
      const propertySaleContribution = amountToBeUsedFromSellingPrice ?? sellingPrice - remainingLoanAmount
      remainingLoan += remainingLoanAmount ?? 0
      saleIncomeAfter += propertySaleContribution ?? 0
    }
  }
  return { saleIncomeBefore, saleIncomeAfter, remainingLoan }
}

export const getExistingCollaterals = (applicationData: SecLoanApplicationData): number => {
  const { targetInfo, applicantInfo, loanInfo } = applicationData
  const currentResidenceInfoDatas: (CurrentResidenceInfoBase | undefined | null)[] = [
    applicantInfo?.currentResidenceInfoData,
    applicantInfo?.currentResidenceInfoData?.secondApplicant,
  ]

  const ownMoney = loanInfo?.loanBackgroundInfoData?.ownMoney ?? 0
  const aspSavingsTotalAmount = applicantInfo?.backgroundInfoData
    ? getTotalAspSavings(applicantInfo.backgroundInfoData)
    : 0
  const { ownFinancingShare = 0, ownWorkShare = 0 } = targetInfo?.loanCostEstimateInfo ?? {}

  const { saleIncomeBefore, saleIncomeAfter } = getSaleIncomeAndRemainingLoan(currentResidenceInfoDatas)

  return saleIncomeBefore + saleIncomeAfter + aspSavingsTotalAmount + ownMoney + ownFinancingShare + ownWorkShare
}

export const getMoneyFromCurrentResidence = (
  currentResidenceInfo: CurrentResidenceInfoBase | undefined | null,
): number | undefined => {
  const { sellingPlan, expenseShareForNewFlatAmount, price = 0, remainingLoanAmount = 0 } = currentResidenceInfo ?? {}
  const isSelling =
    sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingFirst ||
    sellingPlan === SecLoansCurrentResidenceSellingPlan.SellingAfter

  return isSelling
    ? (expenseShareForNewFlatAmount ?? roundNumberToFractionDigits(price - remainingLoanAmount))
    : undefined
}

export const getSquarePrice = (
  loanCostEstimateInfo?: LoanCostEstimateInfoData,
  totalSquareArea?: number,
): number | undefined => {
  const { costEstimate, plotSellingPrice, plotValue } = loanCostEstimateInfo ?? {}
  if (!costEstimate || !totalSquareArea) {
    return undefined
  }
  const plotPlusCostEstimate = costEstimate + (plotSellingPrice ?? 0) + (plotValue ?? 0)
  return roundNumberToFractionDigits(plotPlusCostEstimate / totalSquareArea)
}
