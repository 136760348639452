import { styled } from 'styled-components'

import {
  ExclamationMarkIcon as AttentionIcon,
  HelpIcon,
  IconSize,
  InfoCircleIcon as InfoIcon,
  MegaphoneIcon as CommunicationIcon,
  RedExCircleIcon as FailedIcon,
  SuccessCircleIcon as SuccessIcon,
} from '../assets/Icons'

export enum IconTypeEnum {
  Info = 'info',
  Success = 'success',
  Fail = 'fail',
  Communication = 'communication',
  Attention = 'attention',
  Help = 'help',
}

export interface IconComponentProps {
  iconType?: IconTypeEnum
  size?: IconSize
}

const IconWrapper = styled.span<{ $size: number }>`
  display: inline-block;
  width: ${(props) => props.$size}px;
  height: auto;
`

export const Icon = (props: IconComponentProps): JSX.Element => {
  const size = props.size ?? IconSize.MEDIUM
  let icon
  const iconType = props.iconType ? props.iconType : IconTypeEnum.Info

  switch (iconType) {
    case IconTypeEnum.Fail:
      icon = <FailedIcon />
      break
    case IconTypeEnum.Success:
      icon = <SuccessIcon size={size} />
      break
    case IconTypeEnum.Communication:
      icon = <CommunicationIcon />
      break
    case IconTypeEnum.Attention:
      icon = <AttentionIcon size={size} />
      break
    case IconTypeEnum.Help:
      icon = <HelpIcon size={size} />
      break
    default:
      icon = <InfoIcon />
      break
  }

  return (
    <IconWrapper $size={size} data-testid={`icon-${iconType}`}>
      {icon}
    </IconWrapper>
  )
}
