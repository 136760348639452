import { forwardRef } from 'react'
import { styled } from 'styled-components'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import { ErrorSpacerWithReservedSpace, FlexColumnContainer, NarrowInputFieldWrapper } from '../global/CommonStyling'
import { FormSelectInputProps } from '../global/ComponentProps'
import { SPACE_XXXSMALL } from '../global/spacing'
import { parseComponentId } from '../utilities/helpers'
import { SelectField } from './primitive/SelectField'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACE_XXXSMALL};
`

export const FormSelect = forwardRef<HTMLSelectElement, FormSelectInputProps>((props, ref) => {
  const {
    id,
    required,
    label,
    description,
    name,
    defaultValue = '',
    children,
    error,
    additionalElement,
    type,
    inputMode,
    placeholder,
    maxLength,
    onBlur,
    onChange,
    className,
    tabIndex,
    disabled,
    $theme,
    side,
  } = props

  const overriddenId = parseComponentId(name, id)

  return (
    <NarrowInputFieldWrapper data-testid={`${overriddenId}-select-wrapper`}>
      {label && (
        <FlexContainer>
          <Label isRequired={required} label={label} id={overriddenId} description={description} />
          {additionalElement}
        </FlexContainer>
      )}
      <FlexColumnContainer>
        <SelectField
          name={name}
          id={overriddenId}
          defaultValue={defaultValue}
          ref={ref}
          type={type}
          inputMode={inputMode}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={onChange}
          className={className}
          tabIndex={tabIndex}
          disabled={disabled}
          $theme={$theme}
          side={side}
          error={error}
          description={description}
          required={required}
        >
          {children}
        </SelectField>
        <ErrorSpacerWithReservedSpace>
          <ErrorAndDescriptionRow id={overriddenId} error={error} />
        </ErrorSpacerWithReservedSpace>
      </FlexColumnContainer>
    </NarrowInputFieldWrapper>
  )
})

FormSelect.displayName = 'FormSelect'
