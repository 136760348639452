import { ChangeEvent, DragEvent, useRef, useState } from 'react'
import { styled } from 'styled-components'

import { Button } from '../actions/Button'
import { MediumText, SmallText, XXSmallHeading } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { SPACE_XXXSMALL } from '../global/spacing'

const DropzoneContainer = styled.div<{ $isDraggedOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: ${Colors.BorderStrongNeutral} 1px ${(props) => (props.$isDraggedOver ? 'solid' : 'dashed')};
  border-radius: ${SPACE_XXXSMALL};
  background-color: ${(props) =>
    props.$isDraggedOver ? `${Colors.BackgroundWeakNeutral}` : `${Colors.BackgroundWeakestNeutral}`};
  padding: 24px 0;
`

const UploadButtonDescription = styled(MediumText)`
  margin-top: ${SPACE_XXXSMALL};
  text-align: center;
`

const DropzoneTitle = styled(XXSmallHeading)`
  margin: 0;
`

const DropzoneDescription = styled(SmallText)`
  margin-top: 0;
  &:last-of-type {
    margin-bottom: ${SPACE_XXXSMALL};
  }
`

interface FormUploadProps {
  onDrop: (event: DragEvent<HTMLDivElement>) => void
  onAddButtonClick: (event: ChangeEvent<HTMLInputElement>) => void
  uploadButtonTitle: string
  uploadButtonDescription: string
  dropzoneTitle: string
  dropzoneDescription?: string
  accept: string | undefined
  isDanger: boolean
  isDisabled: boolean
  uploadButtonAriaLabel: string
}
export const FormUpload = ({
  onDrop,
  onAddButtonClick,
  uploadButtonTitle,
  uploadButtonDescription,
  dropzoneTitle,
  dropzoneDescription,
  accept,
  isDanger,
  isDisabled,
  uploadButtonAriaLabel,
}: FormUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const dropzoneRef = useRef<HTMLDivElement>(null)
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    // Prevent file from being opened when dragged in
    event.preventDefault()
  }

  return (
    <>
      <DropzoneTitle>{dropzoneTitle}</DropzoneTitle>
      {dropzoneDescription && (
        <DropzoneDescription tagName="p" $color="secondary">
          {dropzoneDescription}
        </DropzoneDescription>
      )}
      <DropzoneContainer
        id="drop-zone"
        data-testid="drop-zone"
        onDrop={(e) => {
          onDrop(e)
          setIsDraggedOver(false)
        }}
        onDragOver={onDragOver}
        $isDraggedOver={isDraggedOver}
        ref={dropzoneRef}
        onDragEnter={() => setIsDraggedOver(true)}
        onDragLeave={() => setIsDraggedOver(false)}
      >
        <input
          type="file"
          id="files"
          data-testid="files"
          hidden
          multiple
          onChange={onAddButtonClick}
          disabled={isDisabled}
          ref={inputRef}
          accept={accept}
          value=""
        />
        <Button
          onClick={(e) => {
            if (e.currentTarget.ariaDisabled === 'true') {
              return
            }
            if (inputRef?.current) {
              inputRef.current.click()
            }
          }}
          aria-label={uploadButtonAriaLabel}
          aria-disabled={isDisabled}
          disabled={isDisabled}
          aria-labelledby="upload-button-hidden-label"
          aria-describedby="upload-button-description"
          label={uploadButtonTitle}
          variant="secondary"
        />
        <UploadButtonDescription
          weight="regular"
          tagName="span"
          $color={isDanger ? 'danger' : 'secondary'}
          id={'upload-button-description'}
          data-testid="upload-button-description"
        >
          {uploadButtonDescription}
        </UploadButtonDescription>
      </DropzoneContainer>
    </>
  )
}
