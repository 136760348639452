import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { LargeHeading, LeadText, MutedSmallText } from '@ui-components/decorative/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

export interface FormStepSecondaryHeaderProps {
  header: string
  id: string
  tagName?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  additionalText?: ReactNode
  description?: ReactNode
}

const Heading = styled(LargeHeading)`
  margin: 0px;
`

export const FormStepSecondaryHeader = ({
  header,
  id,
  tagName = 'h3',
  additionalText,
  description,
}: FormStepSecondaryHeaderProps) => {
  return (
    <TightSectionContent>
      <Heading tagName={tagName} id={id}>
        {header}
      </Heading>
      {additionalText && <LeadText>{additionalText}</LeadText>}
      {description && <MutedSmallText>{description}</MutedSmallText>}
    </TightSectionContent>
  )
}
