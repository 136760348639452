import { Notification as DSNotification } from '@s-group/design-system-components/Notification/Notification'
import { IconCommerceOffer, IconControlCheck, IconStatusInfo } from '@s-group/design-system-icons'
import { SDS_FONT_SIZE_BODY_MEDIUM } from '@s-group/design-system-tokens/web/tokens/font.es6'
import { AriaAttributes, ReactNode } from 'react'
import { styled } from 'styled-components'

import { MediumText, paragraphSnippet } from '../decorative/Typography'
import { SPACE_SMALL } from '../global/spacing'

type NotificationVariant = 'plain'
type IconType = 'no-icon' | 'default' | 'check' | 'info' | 'offer'
export type StatusVariant = 'info' | 'success' | 'warning' | 'error'

const Header = styled(MediumText)`
  p:not(:last-child) {
    margin-bottom: ${SPACE_SMALL};
  }
`

const NotificationParagraphContainer = styled.div`
  p {
    ${paragraphSnippet(SDS_FONT_SIZE_BODY_MEDIUM)}
  }
`

export interface NotificationProperties {
  header: ReactNode
  className?: string
  children?: ReactNode
  iconType?: IconType
  status?: StatusVariant
  variant?: NotificationVariant
  'aria-live'?: AriaAttributes['aria-live']
  trailer?: ReactNode
}

const getIconVariant = (iconType: IconType | undefined): JSX.Element | undefined | null => {
  switch (iconType) {
    case 'no-icon':
      return null
    case 'check':
      return <IconControlCheck />
    case 'info':
      return <IconStatusInfo />
    case 'offer':
      return <IconCommerceOffer />
    case 'default':
    default:
      return undefined
  }
}

export const Notification = ({
  className,
  children,
  iconType,
  status,
  header,
  variant = undefined,
  'aria-live': ariaLive,
  trailer,
}: NotificationProperties) => {
  return (
    <DSNotification
      className={className}
      close={false}
      orientation={'vertical'}
      icon={getIconVariant(iconType)}
      header={children ? header : <Header>{header}</Header>}
      status={status}
      variant={variant}
      aria-live={ariaLive}
      trailer={trailer}
    >
      <NotificationParagraphContainer>{children}</NotificationParagraphContainer>
    </DSNotification>
  )
}
