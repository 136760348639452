import { styled } from 'styled-components'

import { Colors } from './colors'
import { mainContentAttributes } from './CommonStyling'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_LARGE_DESKTOP_PX,
  FONT_FAMILY_S_BONUS_UX,
  HORIZONTAL_PADDING_DEFAULT,
  MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX,
  SECTION_HORIZONTAL_PADDING_DEFAULT,
  SECTION_VERTICAL_PADDING_DEFAULT,
  VERTICAL_PADDING_DEFAULT,
} from './constants'
import { LEGACY_SPACE_XLARGE } from './legacy/spacing'
import { StandardShadow } from './shadow'
import { SPACE_SMALL } from './spacing'

export const FixedButtonSection = styled.div`
  z-index: 1;
  box-shadow: rgb(0 0 0 / 10%) 0px -4px 8px -2px;
  background-color: ${Colors.White};
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  > * {
    padding: ${VERTICAL_PADDING_DEFAULT}px ${HORIZONTAL_PADDING_DEFAULT}px;
  }

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    width: ${BREAKPOINT_DESKTOP_PX - 2}px;
    padding: 0;

    button {
      max-width: 351.5px;
    }
  }
`

export const Section = styled.section<{
  noTopPadding?: boolean
  noVerticalPadding?: boolean
  halfHorizontalPadding?: boolean
  $maxWidth?: number
}>`
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : 'none')};
  padding-left: ${SPACE_SMALL};
  padding-right: ${SPACE_SMALL};
  padding-top: ${({ noTopPadding, noVerticalPadding }) =>
    noTopPadding || noVerticalPadding ? 0 : `${SECTION_VERTICAL_PADDING_DEFAULT}px`};
  padding-bottom: ${({ noVerticalPadding }) => (noVerticalPadding ? 0 : LEGACY_SPACE_XLARGE)};

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding-left: ${({ halfHorizontalPadding }) =>
      halfHorizontalPadding
        ? `${SECTION_HORIZONTAL_PADDING_DEFAULT / 2}px`
        : `${SECTION_HORIZONTAL_PADDING_DEFAULT}px`};
    padding-right: ${({ halfHorizontalPadding }) =>
      halfHorizontalPadding
        ? `${SECTION_HORIZONTAL_PADDING_DEFAULT / 2}px`
        : `${SECTION_HORIZONTAL_PADDING_DEFAULT}px`};
    padding-bottom: ${({ noTopPadding, noVerticalPadding }) =>
      noTopPadding || noVerticalPadding ? 0 : `${SECTION_VERTICAL_PADDING_DEFAULT}px`};
  }
`

interface ListStyleProps {
  $listStyleType?: 'circle' | 'square' | 'decimal' | 'disc'
}

export const ListSection = styled(Section)<ListStyleProps>`
  font-family: ${FONT_FAMILY_S_BONUS_UX};
  margin-top: 0px;
  &&& {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  text-align: left;
  ul {
    margin: 0px;
    list-style-type: ${(props) => props.$listStyleType ?? 'square'};
    padding-left: 0px;
    @media (max-width: ${BREAKPOINT_DESKTOP_PX - 1}px) {
      padding-left: 10px;
    }
    li {
      margin: ${(props) => (props.$listStyleType === 'decimal' ? '0' : '1rem')} 0;
    }
  }
`

export const FullWidthContentSection = styled(Section)<{
  $isInfoSection?: boolean
}>`
  background: ${({ $isInfoSection: isInfoSection }) =>
    isInfoSection ? Colors.BackgroundInfo : Colors.BackgroundWeakNeutral};
  margin: auto;
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  ${({ $isInfoSection: isInfoSection }) => (isInfoSection ? StandardShadow : undefined)};
`

export const SimplePageContentSection = styled.div.attrs(mainContentAttributes)<{
  $backgroundColor: string
  $largeScreenSupport?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
  margin: auto;
  background: ${(props) => props.$backgroundColor};
  border: 0;
  width: 100%;

  ${({ $largeScreenSupport }) =>
    $largeScreenSupport &&
    `
    @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
      max-width: ${MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX}px;
      }
      `}
`

export const FullWidthPageContentSection = styled(SimplePageContentSection)`
  max-width: none;
`

export const PageContentSection = styled.div<{ $hideBoxShadow?: boolean; $largeScreenSupport?: boolean }>`
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
  background-color: ${Colors.White};
  ${(props) => (props.$hideBoxShadow ? undefined : StandardShadow)}
  border: 0;
  width: 100%;

  ${(props) =>
    props.$largeScreenSupport &&
    `
    @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
      max-width: ${MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX}px;
    }
  `}
`

export const ButtonSection = styled(Section)`
  & > :first-child {
    display: block;
  }
  & > hr {
    margin-top: 20px;
  }
`
