import {
  Icon,
  IconBankingCredentials,
  IconCommerceAgeLimit,
  IconCommerceList,
  IconCommerceSparkle,
  IconCommunicationEmail,
  IconCommunicationFaceHappy,
  IconCommunicationFaceSad,
  IconCommunicationMessageUnread,
  IconCommunicationMobile,
  IconCommunicationNotificationChecked,
  IconCommunicationPhone,
  IconControlBuild,
  IconControlCheck,
  IconControlCopy,
  IconControlEdit,
  IconControlHeart,
  IconControlHeartFilled,
  IconControlLock,
  IconControlMap,
  IconControlPlus,
  IconControlRemove,
  IconControlThumbUp,
  IconControlVisibilityOff,
  IconControlVisibilityOn,
  IconHospitalityDiamond,
  IconLogoSgroup,
  IconNavigationChevronLeft,
  IconNavigationChevronRight,
  IconNavigationClose,
  IconNavigationHome,
  IconNavigationLinkExternal,
  IconNavigationOptions,
  IconPaymentEuro,
  IconStatusBan,
  IconStatusError,
  IconStatusHelp,
  IconStatusInfo,
  IconStatusInfoFilled,
  IconTimeClock,
  IconUserIdcard,
  IconUserLogout,
  IconUserPassword,
  IconUserProfile,
} from '@s-group/design-system-icons'
import { CSSProperties } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'

export enum IconSize {
  XSMALL = 16,
  SMALL = 24,
  MEDIUM = 40,
  LARGE = 48,
  EXTRALARGE = 60,
}

export interface IconProps {
  className?: string
  size?: IconSize
  style?: CSSProperties
}

export interface IconPropsWithAriaLabel extends IconProps {
  ariaLabel?: string
}

export const BRAND_ICON_DEFAULT_HEIGHT = 22

const getIconSize = (size: IconSize): string => (size === IconSize.XSMALL ? `${IconSize.XSMALL}` : `${IconSize.SMALL}`)

export const SPankkiIcon = (width = 62, height = BRAND_ICON_DEFAULT_HEIGHT) => (
  <Icon
    svg={
      <svg
        width={width}
        height={height}
        viewBox="0 0 62 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="logoTitle"
      >
        <title id="logoTitle">S-Pankki logo</title>
        <path
          d="M21.4264 4.69708C22.3998 4.69708 23.1471 4.99334 23.6773 5.58585C24.203 6.17836 24.4681 6.9787 24.4681 7.9957V11.3651C24.4681 12.413 24.2074 13.2531 23.686 13.881C23.1645 14.5089 22.4041 14.8229 21.409 14.8229H19.9708V17.5157H17.4331V4.69708H21.4264ZM21.7132 12.13C21.8696 11.9355 21.9478 11.6525 21.9478 11.2855V8.19025C21.9478 7.45624 21.6524 7.09366 21.0658 7.09366H19.9664V12.4219H21.044C21.3352 12.4263 21.5568 12.329 21.7132 12.13Z"
          fill="white"
        />
        <path
          d="M30.3953 4.69708L32.9503 17.5157H30.3953L29.9434 14.9157H27.3145L26.8626 17.5157H24.3424L26.9321 4.69708H30.3953ZM28.6485 7.33244H28.6138L27.6969 12.6253H29.5523L28.6485 7.33244Z"
          fill="white"
        />
        <path
          d="M36.2614 4.69708L38.6903 12.2406H38.7077L38.6903 4.69708H41.102V17.5157H38.6903L36.1006 9.71575H36.0832L36.1006 17.5157H33.689V4.69708H36.2614Z"
          fill="white"
        />
        <path
          d="M44.8736 4.69708V12.1521L45.0517 11.6038L47.3547 4.7015H49.9271L47.6763 11.2943L50.0358 17.5201H47.4634L45.8817 13.2708L44.8736 15.6497V17.5157H42.4098V4.69708H44.8736Z"
          fill="white"
        />
        <path
          d="M53.2425 4.69708V12.1521L53.4207 11.6038L55.7237 4.7015H58.2961L56.0452 11.2943L58.4047 17.5201H55.8323L54.2506 13.2708L53.2425 15.6497V17.5157H50.7788V4.69708H53.2425Z"
          fill="white"
        />
        <path d="M61.8071 4.69708V17.5157H59.1609V4.69708H61.8071Z" fill={Colors.White} />
        <path
          d="M6.22244 3.67567C7.6868 2.18554 9.57265 1.53555 11.5802 1.53555H13.0619V0.102905H11.5715C9.19026 0.102905 6.95244 0.90324 5.22737 2.66309C3.48926 4.43179 2.71145 6.6913 2.71145 9.16305V12.8685C2.71145 13.0055 2.70276 13.178 2.68104 13.293C2.62889 13.5715 2.52026 13.797 2.33776 13.9783C2.17264 14.1419 1.9771 14.2392 1.82502 14.2923C1.69466 14.3409 1.52954 14.3763 1.35138 14.4028C0.955961 14.4647 0 14.4824 0 14.4824V18.9263H1.29489C2.9461 18.9263 4.45826 18.4178 5.59237 17.2637C6.72649 16.114 7.22185 14.6372 7.22185 13.0498V9.15863C7.22185 7.96034 7.59555 6.83722 8.46026 5.95729C9.32931 5.07294 10.433 4.69267 11.5932 4.69267H11.7974V6.39946C11.402 6.42599 11.1239 6.46579 10.7633 6.58075C10.3852 6.70456 10.0246 6.90354 9.70735 7.22633C9.17723 7.76136 8.95996 8.44673 8.95996 9.15863V13.0498C8.95996 15.128 8.30817 17.0382 6.83947 18.5327C5.37511 20.0228 3.48926 20.6728 1.48174 20.6728H0V22.1055H1.49043C3.87164 22.1055 6.10946 21.3051 7.83454 19.5453C9.57265 17.7766 10.3505 15.5171 10.3505 13.0453V9.33992C10.3505 9.20285 10.3591 9.0304 10.3809 8.91543C10.433 8.63686 10.5416 8.41136 10.7241 8.23006C10.8893 8.06646 11.0848 7.96918 11.2369 7.91612C11.3672 7.86748 11.5324 7.83211 11.7105 7.80558C12.1059 7.74367 13.0619 7.72599 13.0619 7.72599V3.28214H11.767C10.1158 3.28214 8.60365 3.79064 7.46953 4.94471C6.33541 6.09436 5.84005 7.57123 5.84005 9.15863V13.0498C5.84005 14.2481 5.46636 15.3712 4.60165 16.2511C3.73259 17.1354 2.62889 17.5157 1.4687 17.5157H1.26448V15.7736C1.6599 15.747 1.95972 15.694 2.29865 15.5923C2.68104 15.4729 3.03735 15.3048 3.35455 14.9865C3.88468 14.4515 4.10194 13.7661 4.10194 13.0542V9.16305C4.10194 7.08483 4.75373 5.17022 6.22244 3.67567Z"
          fill="white"
        />
      </svg>
    }
  />
)

export const SpankkiGreenIcon = (width = 79, height = 29) => (
  <Icon
    svg={
      <svg
        width={width}
        height={height}
        viewBox="0 0 79 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="logoTitleGreen"
      >
        <title id="logoTitleGreen">S-Pankki logo</title>
        <path
          d="M27.27 5.84713C28.5088 5.84713 29.46 6.22419 30.1347 6.97829C30.8039 7.7324 31.1412 8.75101 31.1412 10.0454V14.3336C31.1412 15.6674 30.8094 16.7367 30.1458 17.5358C29.4821 18.3349 28.5143 18.7345 27.2479 18.7345H25.4173V22.1617H22.1876V5.84713H27.27ZM27.635 15.3072C27.8341 15.0596 27.9336 14.6994 27.9336 14.2323V10.293C27.9336 9.35879 27.5576 8.89733 26.811 8.89733H25.4118V15.6787H26.7833C27.1538 15.6843 27.4359 15.5605 27.635 15.3072Z"
          fill={Colors.ElementMediumPrimary}
        />
        <path
          d="M38.6849 5.84713L41.9368 22.1617H38.6849L38.1098 18.8527H34.7639L34.1888 22.1617H30.9812L34.2773 5.84713H38.6849ZM36.4617 9.20122H36.4175L35.2506 15.9375H37.6121L36.4617 9.20122Z"
          fill={Colors.ElementMediumPrimary}
        />
        <path
          d="M46.1508 5.84713L49.2423 15.4479H49.2644L49.2423 5.84713H52.3116V22.1617H49.2423L45.9462 12.2345H45.9241L45.9462 22.1617H42.8769V5.84713H46.1508Z"
          fill={Colors.ElementMediumPrimary}
        />
        <path
          d="M57.1118 5.84713V15.3354L57.3386 14.6375L60.2697 5.85276H63.5436L60.6789 14.2436L63.6819 22.1674H60.4079L58.3949 16.7592L57.1118 19.7869V22.1617H53.9761V5.84713H57.1118Z"
          fill={Colors.ElementMediumPrimary}
        />
        <path
          d="M67.7632 5.84713V15.3354L67.99 14.6375L70.921 5.85276H74.195L71.3303 14.2436L74.3333 22.1674H71.0593L69.0463 16.7592L67.7632 19.7869V22.1617H64.6275V5.84713H67.7632Z"
          fill={Colors.ElementMediumPrimary}
        />
        <path d="M78.6636 5.84713V22.1617H75.2956V5.84713H78.6636Z" fill={Colors.ElementMediumPrimary} />
        <path
          d="M7.91947 4.54716C9.78319 2.65063 12.1834 1.82336 14.7384 1.82336H16.6242V0H14.7273C11.6967 0 8.84856 1.01861 6.65301 3.25842C4.44087 5.50949 3.45094 8.38523 3.45094 11.5311V16.2471C3.45094 16.4215 3.43988 16.641 3.41223 16.7873C3.34586 17.1419 3.2076 17.4289 2.97533 17.6596C2.76518 17.8678 2.51631 17.9917 2.32275 18.0592C2.15684 18.1211 1.94668 18.1661 1.71994 18.1999C1.21668 18.2787 0 18.3012 0 18.3012V23.957H1.64804C3.74958 23.957 5.67414 23.3098 7.11756 21.841C8.56099 20.3778 9.19145 18.4981 9.19145 16.4778V11.5255C9.19145 10.0004 9.66706 8.57094 10.7676 7.45103C11.8737 6.3255 13.2784 5.84152 14.755 5.84152H15.0149V8.0138C14.5116 8.04757 14.1577 8.09822 13.6987 8.24454C13.2175 8.40211 12.7585 8.65536 12.3548 9.06617C11.6801 9.74712 11.4036 10.6194 11.4036 11.5255V16.4778C11.4036 19.1228 10.574 21.554 8.70477 23.4561C6.84105 25.3526 4.44087 26.1799 1.88585 26.1799H0V28.0033H1.89691C4.92754 28.0033 7.77568 26.9847 9.97123 24.7449C12.1834 22.4938 13.1733 19.6181 13.1733 16.4722V11.7562C13.1733 11.5817 13.1844 11.3623 13.212 11.2159C13.2784 10.8614 13.4166 10.5744 13.6489 10.3437C13.8591 10.1354 14.1079 10.0116 14.3015 9.94409C14.4674 9.88219 14.6776 9.83717 14.9043 9.8034C15.4076 9.72461 16.6242 9.7021 16.6242 9.7021V4.04629H14.9762C12.8747 4.04629 10.9501 4.69348 9.50668 6.1623C8.06325 7.62549 7.43279 9.50513 7.43279 11.5255V16.4778C7.43279 18.0029 6.95718 19.4323 5.85664 20.5522C4.75057 21.6778 3.34586 22.1618 1.86926 22.1618H1.60933V19.9445C2.11259 19.9107 2.49419 19.8432 2.92556 19.7137C3.41223 19.5618 3.86572 19.3479 4.26943 18.9427C4.94414 18.2618 5.22065 17.3895 5.22065 16.4834V11.5311C5.22065 8.88609 6.05021 6.44931 7.91947 4.54716Z"
          fill={Colors.ElementMediumPrimary}
        />
      </svg>
    }
  />
)

export const SokIcon = (props?: IconPropsWithAriaLabel) => {
  const ariaLabel = props?.ariaLabel ?? 'SOK logo'
  return <IconLogoSgroup className={props?.className} color={Colors.White} aria-label={ariaLabel} />
}

export const SokGreenIcon = (props?: IconPropsWithAriaLabel) => {
  const size = props?.size ?? IconSize.MEDIUM
  const ariaLabel = props?.ariaLabel ?? 'SOK logo'
  return (
    <IconLogoSgroup
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      color={Colors.ElementMediumPrimary}
      aria-label={ariaLabel}
    />
  )
}

export const SokGreenIconNoWhitespace = (width = 14, height = 23) => (
  <Icon
    svg={
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="logoTitleNoWhitespace"
      >
        <title id="logoTitleNoWhitespace">SOK logo</title>
        <path
          d="M6.22244 3.64644C7.6868 2.15631 9.57265 1.50631 11.5802 1.50631H13.0619V0.0736694H11.5715C9.19026 0.0736694 6.95244 0.874005 5.22737 2.63386C3.48926 4.40255 2.71145 6.66206 2.71145 9.13381V12.8392C2.71145 12.9763 2.70276 13.1488 2.68104 13.2637C2.62889 13.5423 2.52026 13.7678 2.33776 13.9491C2.17264 14.1127 1.9771 14.21 1.82502 14.263C1.69466 14.3117 1.52954 14.347 1.35138 14.3736C0.955961 14.4355 0 14.4532 0 14.4532V18.897H1.29489C2.9461 18.897 4.45826 18.3885 5.59237 17.2344C6.72649 16.0848 7.22185 14.6079 7.22185 13.0205V9.12939C7.22185 7.9311 7.59555 6.80798 8.46026 5.92805C9.32931 5.0437 10.433 4.66344 11.5932 4.66344H11.7974V6.37023C11.402 6.39676 11.1239 6.43655 10.7633 6.55152C10.3852 6.67533 10.0246 6.87431 9.70735 7.19709C9.17723 7.73212 8.95996 8.41749 8.95996 9.12939V13.0205C8.95996 15.0987 8.30817 17.0089 6.83947 18.5035C5.37511 19.9936 3.48926 20.6436 1.48174 20.6436H0V22.0762H1.49043C3.87164 22.0762 6.10946 21.2759 7.83454 19.5161C9.57265 17.7474 10.3505 15.4879 10.3505 13.0161V9.31068C10.3505 9.17361 10.3591 9.00116 10.3809 8.8862C10.433 8.60763 10.5416 8.38212 10.7241 8.20083C10.8893 8.03722 11.0848 7.93995 11.2369 7.88688C11.3672 7.83825 11.5324 7.80287 11.7105 7.77634C12.1059 7.71444 13.0619 7.69675 13.0619 7.69675V3.2529H11.767C10.1158 3.2529 8.60365 3.7614 7.46953 4.91547C6.33541 6.06513 5.84005 7.54199 5.84005 9.12939V13.0205C5.84005 14.2188 5.46636 15.3419 4.60165 16.2219C3.73259 17.1062 2.62889 17.4865 1.4687 17.4865H1.26448V15.7443C1.6599 15.7178 1.95972 15.6647 2.29865 15.563C2.68104 15.4436 3.03735 15.2756 3.35455 14.9572C3.88468 14.4222 4.10194 13.7368 4.10194 13.0249V9.13381C4.10194 7.0556 4.75373 5.14098 6.22244 3.64644Z"
          fill={Colors.ElementMediumPrimary}
        />
      </svg>
    }
  />
)

export const ExternalLinkIcon = (props: IconProps) => {
  return <IconNavigationLinkExternal className={props?.className} size={getIconSize(IconSize.SMALL)} />
}

export const LeftChevronIcon = (props: IconProps) => <IconNavigationChevronLeft className={props?.className} />

export const Checkmark = (color: string) => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.42857L1.73077 5.71429L4.61538 8.57143L13.2692 0L15 1.71429L4.61538 12L0 7.42857Z"
      fill={color}
    />
  </svg>
)

export const DownChevron = () =>
  '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="%232C353E" preserveAspectRatio="none"><path d="M7.05667 0.723389L4 3.78006L0.943333 0.723389L0 1.66672L4 5.66672L8 1.66672L7.05667 0.723389Z" /></svg>'

export const GreenChevron = () => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.833333 16L0 15.1825L7.30952 8L0 0.817517L0.833333 -6.71314e-07L9 8L0.833333 16Z"
      fill={Colors.ElementStrongPrimary}
    />
  </svg>
)

export const NotificationDefaultIcon = (props: IconProps) => <IconControlCheck className={props?.className} />

export const RedExCircleIcon = () => (
  <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 30C0 13.3043 13.3043 0 30 0C46.6957 0 60 13.3043 60 30C60 46.6957 46.6957 60 30 60C13.3043 60 0 46.6957 0 30Z"
      fill={Colors.White}
    />
    <path
      d="M30 3.13043C44.8696 3.13043 57.1304 15.1304 57.1304 30C57.1304 44.8696 45.1304 57.1304 30 57.1304C14.8696 57.1304 2.86957 44.8696 2.86957 30C2.86957 15.1304 15.1304 3.13043 30 3.13043ZM30 0C13.3043 0 0 13.3043 0 30C0 46.6957 13.3043 60 30 60C46.6957 60 60 46.6957 60 30C60 13.3043 46.6957 0 30 0Z"
      fill={Colors.BorderWeakNeutral}
    />
    <path
      d="M39.8882 22.3354C40.5022 21.7213 40.5022 20.7258 39.8882 20.1118C39.2742 19.4978 38.2787 19.4978 37.6646 20.1118L30 27.7764L22.3354 20.1118C21.7213 19.4978 20.7258 19.4978 20.1118 20.1118C19.4978 20.7258 19.4978 21.7213 20.1118 22.3354L27.7764 30L20.1118 37.6646C19.4978 38.2787 19.4978 39.2742 20.1118 39.8882C20.7258 40.5022 21.7213 40.5022 22.3354 39.8882L30 32.2236L37.6646 39.8882C38.2787 40.5022 39.2742 40.5022 39.8882 39.8882C40.5022 39.2742 40.5022 38.2787 39.8882 37.6646L32.2236 30L39.8882 22.3354Z"
      fill={Colors.ElementError}
    />
  </svg>
)

export const InfoCircleIcon = (props: { exclamationColor?: string }) => (
  <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4347_79553)">
      <path d="M0.5 30.1955C0.5 13.4998 13.8043 0.195496 30.5 0.195496C47.1957 0.195496 60.5 13.4998 60.5 30.1955C60.5 46.8911 47.1957 60.1955 30.5 60.1955C13.8043 60.1955 0.5 46.8911 0.5 30.1955Z" />
      <path
        d="M30.5 3.32593C45.3696 3.32593 57.6304 15.3259 57.6304 30.1955C57.6304 45.0651 45.6304 57.3259 30.5 57.3259C15.3696 57.3259 3.36957 45.0651 3.36957 30.1955C3.36957 15.3259 15.6304 3.32593 30.5 3.32593ZM30.5 0.195496C13.8043 0.195496 0.5 13.4998 0.5 30.1955C0.5 46.8912 13.8043 60.1955 30.5 60.1955C47.1957 60.1955 60.5 46.8912 60.5 30.1955C60.5 13.4998 47.1957 0.195496 30.5 0.195496Z"
        fill={Colors.TextWeakNeutral}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.7261 40.1955H25.5203V37.656H28.8522V28.8463H25.5V26.3068H31.3943V28.8463V37.656H34.7261V40.1955ZM30.1245 18.1955C31.4501 18.1955 32.5269 19.2723 32.5269 20.5979C32.5269 21.9261 31.4501 23.0003 30.1245 23.0003C28.7963 23.0003 27.7196 21.9261 27.7196 20.5979C27.7196 19.2723 28.7963 18.1955 30.1245 18.1955Z"
      fill={props.exclamationColor ?? Colors.ElementStrongNeutral}
    />
    <defs>
      <clipPath id="clip0_4347_79553">
        <rect width="60" height="60" transform="translate(0.5 0.195496)" />
      </clipPath>
    </defs>
  </svg>
)

export const SuccessCircleIcon = (props: { size?: IconSize }) => (
  <svg
    height={`${props.size ?? 60}px`}
    width={`${props.size ?? 60}px`}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="success-circle-icon"
  >
    <path d="M0 30C0 13.3043 13.3043 0 30 0C46.6957 0 60 13.3043 60 30C60 46.6957 46.6957 60 30 60C13.3043 60 0 46.6957 0 30Z" />
    <path
      d="M30 3.13043C44.8696 3.13043 57.1304 15.1304 57.1304 30C57.1304 44.8696 45.1304 57.1304 30 57.1304C14.8696 57.1304 2.86957 44.8696 2.86957 30C2.86957 15.1304 15.1304 3.13043 30 3.13043ZM30 0C13.3043 0 0 13.3043 0 30C0 46.6957 13.3043 60 30 60C46.6957 60 60 46.6957 60 30C60 13.3043 46.6957 0 30 0Z"
      fill={Colors.TextWeakNeutral}
    />
    <path
      d="M24.5455 37.612L18.4795 32.0349C17.8615 31.4667 16.9112 31.4667 16.2932 32.0349C15.5972 32.6748 15.5972 33.773 16.2932 34.4129L23.1651 40.7309C23.9455 41.4484 25.1454 41.4484 25.9258 40.7309L43.7068 24.383C44.4028 23.7431 44.4028 22.6449 43.7068 22.0051C43.0888 21.4369 42.1385 21.4369 41.5205 22.0051L24.5455 37.612Z"
      fill={Colors.ElementMediumPrimary}
    />
  </svg>
)

export const PlusIcon = (props: IconProps) => <IconControlPlus className={props?.className} size={'16'} />

export const CopyIcon = (props: IconProps) => <IconControlCopy className={props?.className} size={'16'} />

export const TrashIcon = (props: IconProps) => <IconControlRemove className={props?.className} />

export const BlueInfoIcon = (props: IconProps) => (
  <IconStatusInfoFilled className={props?.className} data-testid={'blue-info-icon'} color={'#005A71'} />
)

export const MegaphoneIcon = (props: IconProps) => (
  <Icon
    className={props?.className}
    svg={
      <svg
        height={props.size ? `${props.size}px` : '100%'}
        width={props.size ? `${props.size}px` : '100%'}
        viewBox="0 0 44 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3041 9.5006L40.3034 0.909969C41.9481 0.237134 43.75 1.44682 43.75 3.22384V35.7762C43.75 37.5532 41.9481 38.7629 40.3034 38.09L19.307 29.5006H13.75V39.5006H10V29.5006C4.47715 29.5006 0 25.0234 0 19.5006C0 13.9777 4.47715 9.5006 10 9.5006H19.3041ZM40 33.9143L20 25.7324V13.2676L40 5.08575V33.9143ZM16.25 13.2506H10C6.54822 13.2506 3.75 16.0488 3.75 19.5006C3.75 22.9524 6.54822 25.7506 10 25.7506H16.25V13.2506Z"
          fill={Colors.ElementMediumPrimary}
        />
      </svg>
    }
  />
)

export const BulletIcon = (props: IconProps) => (
  <Icon
    className={props?.className}
    aria-hidden="true"
    svg={
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="bullet-icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3.20837C6 4.86523 4.65685 6.20837 3 6.20837C1.34315 6.20837 0 4.86523 0 3.20837C0 1.55152 1.34315 0.208374 3 0.208374C4.65685 0.208374 6 1.55152 6 3.20837Z"
          fill="currentcolor"
        />
      </svg>
    }
  />
)

export const CustomizeableExclamationMark = (props: {
  className?: string
  exclamationColor?: string
  circleColor?: string
  size?: IconSize
  testId: string
}) => (
  <Icon
    className={props?.className}
    svg={
      <svg
        height={props.size ? `${props.size}px` : '100%'}
        width={props.size ? `${props.size}px` : '100%'}
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={props.testId}
      >
        <path d="M0.5 30C0.5 13.3043 13.8043 0 30.5 0C47.1957 0 60.5 13.3043 60.5 30C60.5 46.6957 47.1957 60 30.5 60C13.8043 60 0.5 46.6957 0.5 30Z" />
        <path
          d="M30.5 3.13044C45.3696 3.13044 57.6304 15.1304 57.6304 30C57.6304 44.8696 45.6304 57.1304 30.5 57.1304C15.3696 57.1304 3.36957 44.8696 3.36957 30C3.36957 15.1304 15.6304 3.13044 30.5 3.13044ZM30.5 0C13.8043 0 0.5 13.3043 0.5 30C0.5 46.6957 13.8043 60 30.5 60C47.1957 60 60.5 46.6957 60.5 30C60.5 13.3043 47.1957 0 30.5 0Z"
          fill={props.circleColor ?? `${Colors.TextWeakNeutral}`}
        />
        <path
          d="M28.5 37.3333V14H32.875V37.3333H28.5Z"
          fill={props.exclamationColor ?? `${Colors.ElementStrongNeutral}`}
        />
        <path
          d="M28.5 46.0833V41.7083H32.875V46.0833H28.5Z"
          fill={props.exclamationColor ?? `${Colors.ElementStrongNeutral}`}
        />
      </svg>
    }
  />
)

export const WarnExclamationIcon = (props: IconProps) => (
  <CustomizeableExclamationMark exclamationColor={Colors.ElementError} {...props} testId="warn-icon" />
)
export const ExclamationMarkIcon = (props: IconProps) => (
  <CustomizeableExclamationMark {...props} testId="exclamation-icon" />
)

export const InfoIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconStatusInfo
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      data-testid="info-icon"
      style={props.style}
    />
  )
}

export const HelpIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.MEDIUM
  return (
    <IconStatusHelp
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      data-testid="info-help-icon"
      style={{ ...props.style, color: Colors.ElementMediumNeutral }}
    />
  )
}

export const StatusBanIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconStatusBan
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="status-ban-icon"
    />
  )
}

export const StatusErrorIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconStatusError
      className={props.className}
      style={props.style}
      height={`${size}px`}
      width={`${size}px`}
      data-testid="status-error-icon"
    />
  )
}

export const RemoveItemIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlRemove
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      data-testid="remove-icon"
      style={props.style}
    />
  )
}

export const HeartFilledIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlHeartFilled
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid={'heart-filled-icon'}
    />
  )
}

export const NavigationLinkExternalIcon = (props: IconProps) => {
  return <IconNavigationLinkExternal className={props?.className} data-testid="navigation-link-external-icon" />
}

export const ControlMapIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlMap
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="control-map-icon"
    />
  )
}

export const CommunicationNotificationCheckedIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommunicationNotificationChecked
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="communication-notification-checked-icon"
    />
  )
}

export const UserProfileIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconUserProfile
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="user-profile-icon"
    />
  )
}

export const CommerceListIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommerceList
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="commerce-list-icon"
    />
  )
}

export const CommunicationPhoneIcon = (props: IconProps) => {
  return <IconCommunicationPhone className={props?.className} data-testid="communication-phone-icon" />
}

export const LogoutIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconUserLogout
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="navigation-logout-icon"
    />
  )
}

export const ControlLockIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlLock
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="control-lock-icon"
    />
  )
}

export const HospitalityDiamondIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconHospitalityDiamond
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="hospitality-diamond-icon"
    />
  )
}

export const NavigationChevronRightIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconNavigationChevronRight
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="navigation-chevron-right-icon"
    />
  )
}

export const CommunicationMobileIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommunicationMobile
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="communication-mobile-icon"
    />
  )
}

export const CommunicationEmailIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommunicationEmail
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="communication-email-icon"
    />
  )
}

export const TimeClockIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconTimeClock
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="time-clock-icon"
    />
  )
}

export const PaymentEuroIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconPaymentEuro
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="payment-euro-icon"
    />
  )
}

export const UserPasswordIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconUserPassword
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="user-password-icon"
    />
  )
}

export const NavigationHomeIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconNavigationHome
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="navigation-home-icon"
    />
  )
}

export const UserIdcardIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconUserIdcard
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="user-idcard-icon"
    />
  )
}

export const CommerceAgeLimitIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommerceAgeLimit
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="commerce-age-limit-icon"
    />
  )
}

export const CommunicationMessageUnreadIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconCommunicationMessageUnread
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="communication-message-unread-icon"
    />
  )
}

export const BankingCredentialsIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconBankingCredentials
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="banking-credentials-icon"
    />
  )
}

export const ControlBuildIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlBuild
      className={props?.className}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      data-testid="control-build-icon"
    />
  )
}

export const GenericErrorIcon = (props: IconProps) => {
  return (
    <ControlBuildIcon
      className={props?.className}
      size={IconSize.LARGE}
      style={{ color: Colors.ElementMediumNeutral }}
    />
  )
}

export const ThumbUpIcon = (props: IconProps) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlThumbUp
      className={props?.className}
      style={props?.style}
      height={`${size}px`}
      width={`${size}px`}
      data-testid={'thumb-up-icon'}
    />
  )
}

export const SparkleIcon = (props: IconProps) => (
  <IconCommerceSparkle className={props?.className} data-testid={'sparkle-icon'} />
)
export const HeartIcon = (props: IconProps) => (
  <IconControlHeart className={props?.className} data-testid={'heart-icon'} />
)
export const HappyFaceIcon = (props: IconProps) => (
  <IconCommunicationFaceHappy className={props?.className} data-testid={'happy-face-icon'} />
)
export const CheckIcon = (props: IconProps) => (
  <IconControlCheck className={props?.className} size="24" data-testid={'check-icon'} aria-hidden="true" />
)
export const PenIcon = (props: IconProps) => <IconControlEdit className={props?.className} data-testid={'edit-icon'} />
export const KebabMenuIcon = (props: IconProps) => (
  <IconNavigationOptions className={props?.className} data-testid={'navigation-options-icon'} />
)
export const CloseIcon = (props: IconProps) => (
  <IconNavigationClose className={props?.className} data-testid={'navigation-close-icon'} />
)

export const IconContainer = styled.div`
  display: flex;
  width: ${IconSize.SMALL}px;
  height: ${IconSize.SMALL}px;
  justify-content: center;
  align-items: center;
`

export const ShowIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlVisibilityOn
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="show-icon"
    />
  )
}

export const HideIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.SMALL
  return (
    <IconControlVisibilityOff
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="hide-icon"
    />
  )
}

export const SadIcon = (props: IconPropsWithAriaLabel) => {
  const size = props.size ?? IconSize.MEDIUM
  return (
    <IconCommunicationFaceSad
      className={props?.className}
      size={getIconSize(size)}
      height={`${size}px`}
      width={`${size}px`}
      style={props.style}
      aria-label={props.ariaLabel}
      data-testid="sad-icon"
    />
  )
}
