import { css, styled } from 'styled-components'

/**
 * This container can be used as a wrapper for a button or several buttons. The container centers
 * the button(s) and adds spacing between them. Optionally, extra top padding can be added as well.
 */
export const ButtonContainer = styled.div<{ $padded?: boolean }>`
  display: flex;
  ${({ $padded }) =>
    $padded &&
    css`
      padding-top: 32px;
    `}

  & > * {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;

    &:first-child {
      margin-top: 0;
    }
  }
`
