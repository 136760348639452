import * as React from 'react'
import { styled } from 'styled-components'

import { IconContainer, IconSize } from '../assets/Icons'
import { getPictogram, PictogramType } from '../decorative/Pictograms'
import { ExtraLargeText, MainHeading } from '../decorative/Typography'
import { Colors } from '../global/colors'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_LARGE_DESKTOP_PX,
  MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX,
  SECTION_HORIZONTAL_PADDING_DEFAULT,
  SECTION_VERTICAL_PADDING_DEFAULT,
} from '../global/constants'
import { LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXXSMALL } from '../global/spacing'
import { TwoColumnLayout } from './TwoColumnLayout'

export interface AssistantProps {
  title: string
  pictogram: PictogramType
  leadText?: React.ReactNode
  children?: React.ReactNode
  twoColumnLayoutSupport?: boolean
}

export const Assistant = React.forwardRef<HTMLDivElement, AssistantProps>(function Assistant(
  props: AssistantProps,
  ref,
) {
  const { pictogram, title, leadText, children, twoColumnLayoutSupport = true, ...rest } = props

  const header = (
    <Header>
      <PictogramAndTitle>
        <PictogramContainer>{getPictogram(pictogram)}</PictogramContainer>
        <StyledHeading>{title}</StyledHeading>
      </PictogramAndTitle>
      {leadText && <ExtraLargeText>{leadText}</ExtraLargeText>}
    </Header>
  )

  return (
    <Container {...rest} ref={ref}>
      <ContentColumn>
        {twoColumnLayoutSupport ? (
          <TwoColumnLayout leftContent={header} rightContent={children} />
        ) : (
          <OneColumnLayout>
            {header}
            {children}
          </OneColumnLayout>
        )}
      </ContentColumn>
    </Container>
  )
})

const Container = styled.div`
  background-color: ${Colors.BackgroundInfo};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${Colors.TextInfo};
  box-sizing: border-box;

  padding: ${SECTION_VERTICAL_PADDING_DEFAULT}px ${SPACE_SMALL};
  padding-bottom: ${LEGACY_SPACE_XLARGE};

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: ${SECTION_VERTICAL_PADDING_DEFAULT}px ${SECTION_HORIZONTAL_PADDING_DEFAULT}px;
    padding-bottom: ${LEGACY_SPACE_XLARGE};
  }
`

const ContentColumn = styled.section`
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
  width: 100%;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    max-width: ${MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX}px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: ${SPACE_SMALL};

  * {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

const PictogramAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACE_XXXSMALL};
`

const OneColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LEGACY_SPACE_XLARGE};
`

const PictogramContainer = styled(IconContainer)`
  width: ${IconSize.EXTRALARGE}px;
  height: ${IconSize.EXTRALARGE}px;
`

const StyledHeading = styled(MainHeading)`
  color: ${Colors.TextInfo};
`
