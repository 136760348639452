import { Address } from '../../types'

export const toUpperCaseAddress = (address: Address): Address => {
  const result: Address = {}
  if (address.domesticAddress) {
    result.domesticAddress = {
      ...address.domesticAddress,
      street: address.domesticAddress.street?.toUpperCase(),
      postOffice: address.domesticAddress.postOffice?.toUpperCase(),
      countryName: address.domesticAddress.countryName?.toUpperCase(),
    }
  }
  if (address.foreignAddress) {
    result.foreignAddress = {
      ...address.foreignAddress,
      street: address.foreignAddress.street?.toUpperCase(),
      foreignAddressLine1: address.foreignAddress.foreignAddressLine1?.toUpperCase(),
      foreignAddressLine2: address.foreignAddress.foreignAddressLine2?.toUpperCase(),
      countryName: address.foreignAddress.countryName?.toUpperCase(),
    }
  }
  return result
}
