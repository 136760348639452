import { BREAKPOINT_LARGE_DESKTOP_PX, LARGE_COINTAINER_MAX_WIDTH_PX } from '@ui-components/global/constants'
import { ListSection } from '@ui-components/global/LayoutStyling'
import { forwardRef } from 'react'
import { styled } from 'styled-components'

import { ExternalLink } from '../actions/ExternalLink'
import { Label } from '../dataDisplay/Label'
import { MediumText, MutedSmallText, SmallHeading, SmallText, TextWeight } from '../decorative/Typography'
import { FormLabelledCheckbox } from '../form/FormLabelledCheckbox'
import { SPACE_MEDIUM, SPACE_XXLARGE, SPACE_XXSMALL } from '../global/spacing'

interface LinkData {
  url: string
  text: string
  details?: string
}

interface ConsentProps {
  label?: string
  description?: string
  preCheckboxLabel: string
  preCheckboxDescription?: string
  checkboxId: string
  checkboxLabel: string
  links?: LinkData[]
  name: string
  error: string | undefined
  labelTextProps?: { weight: TextWeight }
  isRequired?: boolean
  isOneColumnLayout?: boolean
}

const Wrapper = styled.div``

const CheckboxWrapper = styled.div`
  margin-top: ${SPACE_XXLARGE};
`

const LinksWrapper = styled.div`
  margin-top: ${SPACE_MEDIUM};
`

const LinkWrapper = styled.div`
  margin-bottom: 16px;
  margin-left: 5px;
  > :first-child {
    display: flex;
    flex-direction: row;
  }
`

const SmallHeadingStyle = styled(SmallHeading)`
  margin-bottom: 5px;
`

const StyledFormLabelledCheckbox = styled(FormLabelledCheckbox)<{ $isOneColumnLayout?: boolean }>`
  margin-top: ${SPACE_XXSMALL};
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    max-width: ${(props) => (props.$isOneColumnLayout ? `${LARGE_COINTAINER_MAX_WIDTH_PX}px` : undefined)};
  }
`

export const Consent = forwardRef<HTMLInputElement, ConsentProps>((props: ConsentProps, ref) => {
  const {
    name,
    label,
    description,
    preCheckboxLabel,
    preCheckboxDescription,
    checkboxId,
    checkboxLabel,
    labelTextProps,
    links = [],
    isRequired,
    isOneColumnLayout,
    ...rest
  } = props

  const overriddenId = name.replace(/\W/g, '-')

  return (
    <Wrapper data-testid={`${overriddenId}-consent-wrapper`}>
      {label && (
        <SmallHeadingStyle $color="primary" weight="bold">
          {label}
        </SmallHeadingStyle>
      )}
      {description && (
        <SmallText tagName="div" $color="secondary">
          {description}
        </SmallText>
      )}
      <LinksWrapper>
        <ListSection>
          <ul>
            {links.map(({ text, details, url }) => (
              <li key={text}>
                <LinkWrapper>
                  <div>
                    <MediumText>
                      <ExternalLink href={url}>{text}</ExternalLink>
                    </MediumText>
                  </div>
                  <MutedSmallText tagName="div">{details}</MutedSmallText>
                </LinkWrapper>
              </li>
            ))}
          </ul>
        </ListSection>
      </LinksWrapper>
      <CheckboxWrapper>
        <Label
          titleWeight={labelTextProps ? labelTextProps.weight : 'medium'}
          id={checkboxId}
          label={preCheckboxLabel}
          description={preCheckboxDescription}
          labelIdOverride={`${checkboxId}-top-label`}
          isRequired={isRequired}
        >
          <StyledFormLabelledCheckbox
            $isOneColumnLayout={isOneColumnLayout}
            name={name}
            id={checkboxId}
            label={checkboxLabel}
            {...rest}
            ref={ref}
          />
        </Label>
      </CheckboxWrapper>
    </Wrapper>
  )
})

Consent.displayName = 'Consent'
