import { useMediaQuery } from '../global/useMediaQuery'

export type ReturnObj = {
  isReducedMotion: boolean
}

export const usePrefersReducedMotion = (): ReturnObj => {
  const isReducedMotion = useMediaQuery('(prefers-reduced-motion)')
  return { isReducedMotion }
}
