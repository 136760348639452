import { StorageDataAbbreviation } from '@shared'
import { formatBytes } from '@shared/utils/formatBytes/formatBytes'
import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import { styled } from 'styled-components'

import { Button } from '../actions/Button'
import { CloseIcon, TrashIcon } from '../assets/Icons'
import { ProgressBar } from '../decorative/ProgressBar'
import { MediumText, SmallText } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { SPACE_MEDIUM } from '../global/spacing'
import { TruncatedInMiddleSpan } from './primitive/TruncatedInMiddleSpan'

const UploadItemContainer = styled(IndentedFormFieldContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 10px;
`

const LeftColumnContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

const TitleText = styled(MediumText)<{ $isValid: boolean }>`
  color: ${(props) => (props.$isValid ? `${Colors.TextStrongPrimary}` : `${Colors.TextStrongNeutral}`)};
`

const ProgressBarContainer = styled.div`
  height: ${SPACE_MEDIUM};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

interface FormUploadItemProps {
  fileName: string
  fileSize: number
  onRemoveButtonClick: () => void
  error?: string
  uploadProgress?: number
  isValid: boolean
  dataAbbreviation: StorageDataAbbreviation
  progressBarAriaLabel: string
  cancelFileUploadAriaLabel: string
  removeFileAriaLabel: string
}

export const FormUploadItem = ({
  fileName,
  fileSize,
  onRemoveButtonClick,
  error,
  uploadProgress = 100,
  isValid,
  dataAbbreviation,
  progressBarAriaLabel,
  cancelFileUploadAriaLabel,
  removeFileAriaLabel,
}: FormUploadItemProps) => {
  const isUploadDone = uploadProgress >= 100

  const resolveStatus = () => {
    if (!isValid) {
      return (
        <SmallText tagName="div" $color="danger">
          {error}
        </SmallText>
      )
    }

    if (isUploadDone) {
      return (
        <SmallText tagName="div" $color="secondary">
          {formatBytes(fileSize, 2, dataAbbreviation)}
        </SmallText>
      )
    }

    return (
      <ProgressBarContainer>
        <ProgressBar progress={uploadProgress} ariaLabel={progressBarAriaLabel} />
      </ProgressBarContainer>
    )
  }
  return (
    <li>
      <UploadItemContainer>
        <LeftColumnContainer>
          <TitleText weight="regular" tagName="div" $isValid={isValid && isUploadDone}>
            <TruncatedInMiddleSpan text={fileName} />
          </TitleText>
          {resolveStatus()}
        </LeftColumnContainer>
        <Button
          onClick={onRemoveButtonClick}
          aria-label={`${isUploadDone ? removeFileAriaLabel : cancelFileUploadAriaLabel}: ${fileName}`}
          color="neutral"
          icon={isUploadDone ? <TrashIcon /> : <CloseIcon />}
          variant="secondary"
        />
      </UploadItemContainer>
    </li>
  )
}
