import {
  SDS_SPACE_LARGE,
  SDS_SPACE_MEDIUM,
  SDS_SPACE_SMALL,
  SDS_SPACE_XLARGE,
  SDS_SPACE_XSMALL,
  SDS_SPACE_XXLARGE,
  SDS_SPACE_XXSMALL,
  SDS_SPACE_XXXSMALL,
  SDS_SPACE_XXXXLARGE,
  SDS_SPACE_XXXXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'

export const SPACE_XXXXSMALL = SDS_SPACE_XXXXSMALL
export const SPACE_XXXSMALL = SDS_SPACE_XXXSMALL
export const SPACE_XXSMALL = SDS_SPACE_XXSMALL
export const SPACE_XSMALL = SDS_SPACE_XSMALL
export const SPACE_SMALL = SDS_SPACE_SMALL
export const SPACE_MEDIUM = SDS_SPACE_MEDIUM
export const SPACE_LARGE = SDS_SPACE_LARGE
export const SPACE_XLARGE = SDS_SPACE_XLARGE
export const SPACE_XXLARGE = SDS_SPACE_XXLARGE
export const SPACE_XXXXLARGE = SDS_SPACE_XXXXLARGE
