import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { LeadText, MainHeading, MutedSmallText } from '@ui-components/decorative/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

export interface FormStepMainHeaderProps {
  header: string
  id?: string
  additionalText?: ReactNode
  description?: ReactNode
  shouldHyphenateHeader?: boolean
  tagName?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Heading = styled(MainHeading)<{ $shouldHyphenate?: boolean }>`
  hyphens: ${(props) => (props.$shouldHyphenate ? 'auto' : 'none')};
  margin-bottom: 0px;
`

export const FormStepMainHeader = ({
  header,
  additionalText,
  description,
  shouldHyphenateHeader = false,
  tagName = 'h2',
  id,
}: FormStepMainHeaderProps) => {
  return (
    <TightSectionContent>
      <Heading $shouldHyphenate={shouldHyphenateHeader} tagName={tagName} id={id ?? 'main-heading'}>
        {header}
      </Heading>
      {additionalText && <LeadText>{additionalText}</LeadText>}
      {description && <MutedSmallText>{description}</MutedSmallText>}
    </TightSectionContent>
  )
}
