import { JSXElementConstructor } from 'react'
import { styled } from 'styled-components'

import { DefaultProps, ExtraSmallHeading, HeadingTypographyProps, SmallHeading } from '../decorative/Typography'
import { Colors } from '../global/colors'
import { SPACE_XXXSMALL } from '../global/spacing'

export const ModalMainHeading: JSXElementConstructor<HeadingTypographyProps> & { defaultProps: DefaultProps } = styled(
  SmallHeading,
)`
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${Colors.TextDefaultNeutral};

  > :first-child {
    margin-right: 4px;
    align-items: flex-start;
  }
`

const ModalExtraSmallHeading: JSXElementConstructor<HeadingTypographyProps> & { defaultProps: DefaultProps } = styled(
  ExtraSmallHeading,
)`
  margin-top: 24px;
  margin-bottom: 8px;
`

export const ModalSubHeading = (props: HeadingTypographyProps) => {
  return <ModalExtraSmallHeading tagName="h3" {...props} />
}

export const ModalHeaderContainer = styled.div`
  gap: ${SPACE_XXXSMALL};
  span {
    color: ${Colors.TextStrongNeutral};
  }
`
