import { MakeRequired } from '@shared'
import { TrashIcon } from '@ui-components/assets/Icons'
import { useIsDesktopScreen } from '@ui-components/hooks/useIsDesktopScreen'

import { Button } from '../Button'

type ButtonProps = Omit<React.ComponentProps<typeof Button>, 'icon' | 'color'>
export type RemoveItemButtonProps = MakeRequired<ButtonProps, 'aria-label' | 'label'>

export const RemoveItemButton = ({ label, ...rest }: RemoveItemButtonProps) => {
  const isDesktop = useIsDesktopScreen()
  return <Button icon={<TrashIcon />} label={isDesktop ? label : undefined} variant="secondary" {...rest} />
}
