import { WizardStepContainer } from '../layout/WizardStepContainer'
import { WizardPageContent } from './WizardPageContent'
import { WizardStepProps } from './WizardTypes'

export const WizardStep = (props: WizardStepProps) => {
  return (
    <WizardStepContainer {...props} $useLegacyBrand={props.$useLegacyBrand ?? true}>
      <WizardPageContent {...props} />
    </WizardStepContainer>
  )
}
