import { Loader } from '@s-group/design-system-components/Loader/Loader'

import { Colors } from '../global/colors'

interface LoadingSpinnerProps {
  className?: string
  alt: string
  type?: 'basic' | 'button'
  size?: 'small' | 'medium' | 'large' | number
  'aria-hidden'?: boolean
}

export const LoadingSpinner = ({
  className,
  alt,
  type,
  size = 'medium',
  ...rest
}: LoadingSpinnerProps): React.ReactElement => {
  const color = type === 'button' ? Colors.ElementStrongestPrimary : undefined
  const shouldDelay = type === 'button'
  return <Loader aria-label={alt} size={size} className={className} color={color} delay={shouldDelay} {...rest} />
}
