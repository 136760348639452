import { Section } from '@ui-components/global/LayoutStyling'
import * as React from 'react'
import { styled } from 'styled-components'

import { IconSize } from '../assets/Icons'
import { Icon, IconTypeEnum } from '../decorative/IconComponent'
import { LargeText, MediumText } from '../decorative/Typography'

export interface StatusViewProps {
  headerText: string
  icon?: IconTypeEnum
  iconSize?: IconSize
  contentText?: string
  bullets?: string[]
  children?: React.ReactNode
}

const HugeText = styled(LargeText)`
  font-size: 27px;
  line-height: 32px;
  font-weight: 700;
`

const InstructionSection = styled(Section)`
  &&& {
    padding-top: 0px;
  }
  text-align: left;
  ul {
    list-style-type: square;
    padding-left: 15px;
    li {
      padding-left: 15px;
      margin-bottom: 15px;
    }
  }
`

const Content = styled(Section)`
  text-align: center;
`

const InfoTextWrapper = styled.div`
  margin-top: 15px;
`

export const StatusView = (props: StatusViewProps): JSX.Element => {
  const topSection = (headerText: string, contentText = '') => (
    <>
      {props.icon && <Icon iconType={props.icon} size={props.iconSize ?? IconSize.EXTRALARGE} />}
      <InfoTextWrapper>
        <HugeText $color="primary" tagName="h1">
          {headerText}
        </HugeText>
      </InfoTextWrapper>
      {contentText && (
        <InfoTextWrapper>
          <LargeText $color="primary">{contentText}</LargeText>
        </InfoTextWrapper>
      )}
    </>
  )

  const instructionSection = Array.isArray(props.bullets) && props.bullets.length > 0 && (
    <InstructionSection>
      <ul>
        {props.bullets.map((bulletPoint) => (
          <li key={bulletPoint}>
            <MediumText $color="primary">{bulletPoint}</MediumText>
          </li>
        ))}
      </ul>
    </InstructionSection>
  )

  return (
    <Content>
      {topSection(props.headerText, props.contentText)}
      {instructionSection}
      {props.children}
    </Content>
  )
}
