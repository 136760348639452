import { styled } from 'styled-components'

import { MutedSmallText } from '../decorative/Typography'
import { ErrorLabel, FlexRowContainer } from '../global/CommonStyling'
import { SPACE_XXXSMALL } from '../global/spacing'

const INDENT_PX = 2

const MessageContainer = styled(FlexRowContainer)`
  justify-content: space-between;
  padding-left: ${INDENT_PX}px;
  padding-right: ${INDENT_PX}px;
`

interface ErrorAndDescriptionRowProps {
  id: string
  error?: string
  description?: string
  className?: string
}

const Description = styled(MutedSmallText)`
  margin-top: ${SPACE_XXXSMALL}; // align with ErrorLabel margin-top
`

export const ErrorAndDescriptionRow = (props: ErrorAndDescriptionRowProps): JSX.Element => {
  const { error, id, description, className } = props
  return (
    <MessageContainer className={className}>
      {error && (
        <div>
          <ErrorLabel id={id + '-error'} aria-live="assertive">
            {error}
          </ErrorLabel>
        </div>
      )}
      {description && <Description>{description}</Description>}
    </MessageContainer>
  )
}
