import { Select, SelectProps as DSSelectProps } from '@s-group/design-system-components/Select/Select'
import classNames from 'classnames'
import { forwardRef, RefAttributes } from 'react'
import { styled } from 'styled-components'

import { InputFieldProps } from '../../global/ComponentProps'
import { buildAriaAttributes } from '../../global/global'

interface SelectFieldProps extends InputFieldProps {
  disablePlaceholder?: boolean
}

const TypedSelect = Select as React.ComponentType<DSSelectProps & RefAttributes<HTMLSelectElement>>

const UnstyledSelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      id = '',
      children,
      name,
      className,
      disabled,
      defaultValue,
      onBlur,
      onChange,
      placeholder = '',
      disablePlaceholder = false,
      required = true,
      error,
      description,
      tabIndex = 0,
    }: SelectFieldProps,
    ref,
  ) => (
    <TypedSelect
      ref={ref}
      id={id}
      name={name}
      defaultValue={defaultValue}
      className={classNames(className, { error: !!error })}
      error={!!error}
      required={required}
      tab-index={tabIndex}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      rounding={'small'}
      sizing={'small'}
      {...buildAriaAttributes(id, required, !!description, !!error)}
      style={{ width: '100%' }}
    >
      <option value={defaultValue} disabled={disablePlaceholder}>
        {placeholder}
      </option>
      {children}
    </TypedSelect>
  ),
)

UnstyledSelectField.displayName = 'SelectField'

export const SelectField = styled(UnstyledSelectField)`
  &:invalid:not(.error) {
    border-color: unset;
  }
`
