import { styled } from 'styled-components'

import { SegmentedProgressBar, SegmentedProgressBarProps } from '../../decorative/SegmentedProgressBar'
import { SecondaryHeaderWrapper } from '../../global/CommonStyling'
import { DEFAULT_SCROLLED_OVER_HEIGHT_PX, STEP_HEADER_HEIGHT } from '../../global/constants'
import { useIsScrolledOver } from '../../hooks'
import { SecondaryTopBar, SecondaryTopBarProps } from '../../navigation/SecondaryTopBar'

export interface FloatingTopBarProps {
  secondaryTopBarProps: SecondaryTopBarProps
  progressBarProps?: SegmentedProgressBarProps
}

export const FloatingTopBar = ({ secondaryTopBarProps, progressBarProps }: FloatingTopBarProps) => {
  const isScrolledOver = useIsScrolledOver(DEFAULT_SCROLLED_OVER_HEIGHT_PX)

  const floatingTopBarProps = secondaryTopBarProps

  const staticTopBarProps: Partial<SecondaryTopBarProps> = {
    ...secondaryTopBarProps,
    // Override default values in secondaryTopBarProps
    title: undefined,
    description: undefined,
    removeSidePadding: true,
    hideBoxShadow: true,
    hideButtonLabelsInMobileBreakpoint: false,
  }

  const topBarProps = isScrolledOver ? floatingTopBarProps : staticTopBarProps

  return (
    <Header>
      {secondaryTopBarProps && (
        <SecondaryHeader $fixedToTop={isScrolledOver} data-testid="secondary-header">
          <SecondaryTopBar {...topBarProps} small={false} titleTagName="div" />
          {progressBarProps && <SegmentedProgressBar {...progressBarProps} />}
        </SecondaryHeader>
      )}
    </Header>
  )
}

const Header = styled.div`
  height: ${STEP_HEADER_HEIGHT}px;
`

const SecondaryHeader = styled(SecondaryHeaderWrapper)`
  height: ${STEP_HEADER_HEIGHT}px;
  margin-bottom: 0;
`
