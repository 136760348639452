import { ErrorVisualMode } from '@ui-components/containers/ErrorComponentTypes'
import { FullWidthContentSection } from '@ui-components/global/LayoutStyling'
import * as React from 'react'

import { ErrorComponent } from '../containers/ErrorComponent'
import { LogoIconType } from '../global/ComponentProps'
import { PrimaryTopBar } from '../navigation/PrimaryTopBar'
import { PAGE_EXIT_BUTTON_CLASS } from '../utilities/helpers'

export interface TechnicalErrorProps {
  heading: React.ReactNode
  content: React.ReactNode
  buttonLabel: string
  onButtonClick: () => void
}

export const TechnicalErrorStep = (props: TechnicalErrorProps) => {
  const { heading, content, buttonLabel, onButtonClick } = props
  return (
    <div>
      <PrimaryTopBar
        actionButtonOptions={{ className: PAGE_EXIT_BUTTON_CLASS, label: buttonLabel, onClick: onButtonClick }}
        icon={LogoIconType.SOK}
        variant="compact"
      />
      <FullWidthContentSection>
        <ErrorComponent heading={heading} content={content} mode={ErrorVisualMode.TechnicalError} />
      </FullWidthContentSection>
    </div>
  )
}
