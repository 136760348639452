import { PropsWithChildren, ReactElement } from 'react'
import { styled } from 'styled-components'

import { PAGE_COMPONENT_GAP } from '../global/constants'
import { BaseModal } from './BaseModal'
import { ModalContent } from './ModalContent'

export interface NoButtonsModalProps {
  contentLabel?: string
  onClose: () => unknown
  isOpen: boolean
  header?: ReactElement | string
  requestId?: string
}

const Content = styled(ModalContent)`
  padding-bottom: ${PAGE_COMPONENT_GAP}px;
`

export const NoButtonsModal = ({
  contentLabel,
  onClose,
  isOpen,
  header,
  children,
  requestId,
}: PropsWithChildren<NoButtonsModalProps>) => {
  return (
    <BaseModal contentLabel={contentLabel ?? 'Prompt modal'} onClose={onClose} isOpen={isOpen} footer={undefined}>
      <Content header={header} requestId={requestId}>
        {children}
      </Content>
    </BaseModal>
  )
}
