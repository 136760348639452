import {
  Checkbox as DSCheckbox,
  CheckboxProps as DSCheckboxProps,
} from '@s-group/design-system-components/Checkbox/Checkbox'
import classNames from 'classnames'
import { FocusEvent, forwardRef, RefAttributes } from 'react'

import { CheckboxProps } from '../../global/ComponentProps'

const TypedDSCheckbox = DSCheckbox as React.ComponentType<DSCheckboxProps & RefAttributes<HTMLInputElement>>

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, name, onChange, value, className, disabled, onBlur, required = false, error }, ref) => (
    <TypedDSCheckbox
      ref={ref}
      id={id}
      name={name}
      className={classNames(className, { error: !!error })}
      error={!!error}
      required={required}
      disabled={disabled}
      alternative
      onBlur={(e: FocusEvent) => {
        if (onBlur) {
          onBlur(e)
        }
      }}
      aria-invalid={!!error}
      onChange={onChange}
      value={value}
    />
  ),
)

Checkbox.displayName = 'Checkbox'
