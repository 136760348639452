import { ReactNode } from 'react'
import { css, keyframes, styled } from 'styled-components'

import { MOTION_DURATION_SLOW, MOTION_EASING_DEFAULT } from '../global/motion'
import { SPACE_XXXSMALL } from '../global/spacing'
import { usePrefersReducedMotion } from '../hooks/usePrefersReducedMotion'

interface ShakingStylingProps {
  $shouldShake: boolean
  $isReducedMotion: boolean
}

export interface ShakingContainerProps {
  children: ReactNode
  shouldShake: boolean
  className?: string
}

const shake = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-${SPACE_XXXSMALL}); }
    50% { transform: translateX(${SPACE_XXXSMALL}); }
    75% { transform: translateX(-${SPACE_XXXSMALL}); }
    100% { transform: translateX(0); }
  `

const Container = styled.div<ShakingStylingProps>`
  ${(props) =>
    props.$shouldShake &&
    !props.$isReducedMotion &&
    css`
      animation: ${shake};
      animation-duration: ${MOTION_DURATION_SLOW};
      animation-timing-function: cubic-bezier(${MOTION_EASING_DEFAULT});
      animation-iteration-count: infinite;
    `}
`

export const ShakingContainer = ({ children, shouldShake, className }: ShakingContainerProps): JSX.Element => {
  const { isReducedMotion } = usePrefersReducedMotion()

  return (
    <Container className={className} $shouldShake={shouldShake} $isReducedMotion={isReducedMotion}>
      {children}
    </Container>
  )
}
