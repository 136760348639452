import { CopyIcon } from '@ui-components/assets/Icons'

import { Button } from '../Button'

export interface CopyButtonProps {
  onClick: (event: React.BaseSyntheticEvent) => unknown
  label?: string
}

export const CopyButton = (props: CopyButtonProps) => {
  return <Button onClick={props.onClick} label={props.label} icon={<CopyIcon />} compact={true} variant="plain" />
}
