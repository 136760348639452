import { useEffect, useState } from 'react'

export const useIsScrolledOver = (scrolledOverHeightPx: number): boolean => {
  const [isScrolledOver, setIsScrolledOver] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setIsScrolledOver(window.scrollY > scrolledOverHeightPx)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrolledOverHeightPx])

  return isScrolledOver
}
