import { ReactNode } from 'react'

import { MediumText, MutedSmallText, TextWeight } from '../decorative/Typography'

export const Label = ({
  id,
  label,
  description,
  children,
  titleWeight = 'medium',
  isRequired = false,
  labelIdOverride = undefined,
  as = 'label',
}: {
  id: string
  titleWeight?: TextWeight
  label: string
  description?: ReactNode
  children?: ReactNode
  isRequired?: boolean
  labelIdOverride?: string
  as?: 'label' | 'legend' | 'span'
}): JSX.Element => (
  <div>
    <MediumText $color="primary" weight={titleWeight} tagName={as} id={labelIdOverride ?? `${id}-label`} htmlFor={id}>
      {isRequired ? `* ${label}` : label}
    </MediumText>

    {description && (
      <MutedSmallText tagName="div" id={`${id}-description`}>
        {description}
      </MutedSmallText>
    )}

    {children && <MediumText tagName="div">{children}</MediumText>}
  </div>
)
Label.displayName = 'ElementLabel'
