import { Modal } from '@s-group/design-system-components/Modal/Modal'
import { ReactNode, useEffect } from 'react'
import * as R from 'remeda'

interface BaseModalProps {
  contentLabel: string
  children?: ReactNode
  parentSelector?: Element | null
  onClose?: () => void
  isOpen?: boolean
  footer: ReactNode
}

const defaultSelector = () => {
  // under testing, after test has finished, this can be undefined
  // and modal still is doing some pending timer actions which can cause flakiness
  if (!document) {
    return null
  }
  return process.env.NODE_ENV === 'test' ? document.body : document.querySelector('#root')
}

export const BaseModal = ({
  contentLabel,
  children,
  isOpen,
  parentSelector = defaultSelector(),
  onClose = R.doNothing(),
  footer,
}: BaseModalProps): JSX.Element => {
  useEffect(() => {
    // tests can have document as undefined during cleanup (see defaultSelector above)
    if (isOpen && document) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal
      aria-label={contentLabel}
      onClose={onClose}
      open={isOpen ?? false}
      closeOnOutsideClick={false}
      portalParent={parentSelector}
      footer={footer}
    >
      {children}
    </Modal>
  )
}

BaseModal.displayName = 'BaseModal'
