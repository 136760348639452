import { EditItemButton, EditItemButtonProps } from '@ui-components/actions/special/EditItemButton'
import { Fragment, ReactNode } from 'react'
import { styled } from 'styled-components'

import { Divider } from '../decorative/Divider'
import { DataSummary } from '../form/DataSummary'
import { TitleSection } from './TitleSection'

export interface SummaryViewProps {
  introSection?: ReactNode
  dataSections?: SummarySection[]
  consentSection?: ReactNode
}

export interface SummarySection {
  id: string
  title?: string
  dataSummaryTitle: string
  children: ReactNode
  isEditable: boolean
  editButtonProps?: EditItemButtonProps
}

export const SummaryView = ({ introSection, dataSections, consentSection }: SummaryViewProps) => {
  return (
    <>
      {introSection && <Section>{introSection}</Section>}
      {dataSections?.map((section) => (
        <Fragment key={`${section.id}-fragment`}>
          <Section id={section.id} data-testid={section.id}>
            {section?.title && <TitleSection title={section.title} />}
            <DataSummary
              heading={section.dataSummaryTitle}
              editLink={
                section.isEditable &&
                section.editButtonProps && (
                  <EditItemButton compact={true} variant="plain" {...section.editButtonProps} />
                )
              }
              largeScreenSupport={true}
            >
              {section.children}
            </DataSummary>
          </Section>
          <Divider />
        </Fragment>
      ))}
      {consentSection && <Section>{consentSection}</Section>}
    </>
  )
}

const Section = styled.section`
  > :first-child {
    margin-top: 0;
  }
`
