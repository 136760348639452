import { BrandWrapper } from '@s-group/design-system-components/BrandWrapper/BrandWrapper'
import * as theme from '@s-group/design-system-tokens/web/tokens/s-pankki/theme'
import { shouldForwardProp } from '@ui-components/utilities/helpers'
import { ReactNode } from 'react'
import { StyleSheetManager } from 'styled-components'

export const BankBrandWrapper = ({ children }: { children?: ReactNode }) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <BrandWrapper theme={theme}>{children}</BrandWrapper>
  </StyleSheetManager>
)
