import { resolveCssDimensionValue } from '@ui-components/utilities/helpers'
import { styled } from 'styled-components'

import { Colors } from './colors'
import { FlexColumnContainer } from './CommonStyling'
import { PAGE_COMPONENT_GAP } from './constants'
import { Section } from './LayoutStyling'

/**
 * Section for form element grouping. Prefer using FormSectionContainer to layout children correctly
 */
export const FormSection = styled(Section)`
  & form > div {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & form > hr {
    margin-bottom: 48px;
  }
`

export const FormSectionForTextInputs = styled.div`
  > div {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
//TODO: DIG-5022
/** avoid using, use IndentedFormFieldContainer instead */
export const IndentedFormFieldWrapper = styled.div`
  border-left: 1px solid ${Colors.BorderWeakNeutral};
  margin: -28px 0 0 15px;
  padding: 20px 0 20px 20px;

  > div:not(:last-child) {
    padding-bottom: 20px;
  }
`

export const IndentedFormFieldContainer = styled(FlexColumnContainer)<{ gap?: number | string }>`
  border-left: 1px solid ${Colors.BorderWeakNeutral};
  margin: 16px 0 0 10px;
  padding: 16px 0 24px 26px;
  gap: ${({ gap }) => resolveCssDimensionValue(gap, `${PAGE_COMPONENT_GAP}px`)};
`
