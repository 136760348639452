import {
  Address,
  BankAccount,
  CardType,
  DomesticAddress,
  ExitUrlResponse,
  KycInfo,
  KycInfoRequest,
  Language,
  UiConfig,
  VerifyAuthCodeRequest,
} from '../types'

export interface ExistingServices {
  hasAllServices: boolean
  hasCompleteAoData: boolean
  hasAoMembership: boolean
  isMemberOfAoHousehold: boolean
  hasSid?: boolean
  hasBankCustomership: boolean
  hasValidKyc: boolean
  hasBankAccount: boolean
  hasPaymentCard: boolean
  hasChargeablePaymentCard: boolean
  bankAccounts: DigiLiBankAccount[]
  hasNetbank: boolean
}

export interface DigiLiBankAccount extends BankAccount {
  isValidForBenefitAccount: boolean
  isValidForPaymentCard: boolean
  isValidForNetbankDebitAccount: boolean
}

export interface MarketingPermissions {
  email: boolean
  mobile: boolean
}

export interface Coop {
  id: string
  name: string
}

export interface UserServiceInfo {
  existingServices: Partial<ExistingServices>
}

export interface BasicUserInfo extends UserServiceInfo {
  ssn: string
  firstNames: string
  lastName: string
  address?: Address
  emailAddress?: string
  emailVerified?: boolean
  phoneNumber?: string
  language?: AoLanguage
  gender?: Gender
  marketingPermissions: MarketingPermissions
  existingServices: ExistingServices
  coops?: Coop[]
  proposedCoopId?: string
  aoHouseholdPrimaryMemberName?: string
  isEmployee: boolean
  isOpeningNetbankDisabled: boolean
  isCredentialsRecoveryDisabled: boolean
  isMigratedCustomer: boolean
  isSokosPromotionDisabled: boolean
  availablePaymentCardTypes: CardType[]
}

export interface EmailVerificationRequest {
  email: string
}

export interface EmailVerificationResponse {
  isAlreadyVerified: boolean
  isReserved: boolean
  isResendAllowed: boolean
}

export interface ResendEmailVerificationResponse {
  isResendAllowed: boolean
}

export interface EmailVerificationCodeResponse {
  isRetryAllowed: boolean
  isEmailValid: boolean
}

export enum Flow {
  DigiLi = 'digili',
  DigiPa = 'digipa',
  DigiHa = 'digiha',
}

export interface DigiliVerifyAuthCodeRequest extends VerifyAuthCodeRequest, DigiLiUiConfig {}

export interface RecoverSessionRequest {
  transactionId: string
}

export enum Gender {
  Female = 'female',
  Male = 'male',
  Other = 'other',
  Unknown = 'unknown',
}

export interface NetbankServiceRequest {
  debitAccount: string
}

export interface UnderageMember {
  firstNames: string
  lastName: string
  ssn: string
  address: DomesticAddress
  orderScard: boolean
}

export interface InviteMember {
  firstNames: string
  lastName: string
  email: string
  language: Language
}

export interface UpdateAoCustomerRequest {
  language: AoLanguage
  gender?: Gender
  marketingPermissions: MarketingPermissions
  phoneNumber: string
  email?: string
}

export interface FailedInvitedMember {
  firstNames: string
  lastName: string
}

export interface UpdateAoInfoResponse {
  failedAdultMembers: FailedInvitedMember[]
  failedUnderageMembers: FailedInvitedMember[]
}

export enum HouseholdSelection {
  CreateNew = 'createNew',
  ContinueAsMember = 'continueAsMember',
}

export interface ValidateHouseholdMemberRequest {
  ssn: string
}

export interface ValidateHouseholdMemberResponse {
  valid: boolean
}

export interface InitializePaymentRequest extends UpdateAoCustomerRequest {
  amount: number
  coopId: string
  householdSelection: HouseholdSelection
  underageMembers: UnderageMember[]
  inviteMembers: InviteMember[]
}

export interface SetPaymentMethodRequest {
  paymentMethod: PaymentMethodId
}

// Checkout details provided by the SSC ECOM SDK Javascript
export interface SubmitPaymentRequest {
  type: string
  data: object
  locale?: string
  shopper_info?: {
    email?: string
    phone?: string
    ip_address: string
  }
  browser_info?: {
    color_depth: number
    java_enabled: boolean
    javascript_enabled?: boolean
    language: string
    screen_height: number
    screen_width: number
    time_zone_offset: number
    user_agent: string
  }
}

export interface PaymentMethod {
  id: PaymentMethodId
  name: string
}

export interface InitializePaymentResponse {
  paymentState: PaymentState
  paymentMethods?: PaymentMethod[]
}

// Returned by SSC ECOM API, provided to SSC ECOM component
export interface SscCheckoutActionResponse {
  checkout_id: string
  action: 'adyen_sdk' | 'redirect'
  action_data: object
}

export interface BankAccountResponse {
  bankAccountNumber: string
}

export interface BenefitServiceRequest {
  bankAccountNumber: string
}

export interface BankInfoResponse {
  kycInfo: KycInfo
  benefitAccountNumber?: string
}

export type DigiLiUiConfig = UiConfig<Flow, DigiLiSource>

export interface SessionConfigResponse extends ExitUrlResponse, DigiLiUiConfig {}

export interface CreateBankCustomerRequest extends KycInfoRequest {}

export interface PaymentCardInfo {
  validCardExists: boolean
  chargeableCardExists: boolean
}

export interface PaymentTransactionId {
  transactionId: string
}

export enum DigiLiSource {
  SMobile = 'smobile',
  SKanava = 'skanava',
  SPankki = 'spankki',
  OKS = 'oks',
  AoMember = 'aomember',
  Unknown = 'unknown',
}

export enum AoLanguage {
  Fi = 'fi',
  Sv = 'sv',
  Other = 'other',
}

export interface PaymentNotificationRequest {
  protected: string
  payload: string
  signature: string
}

export interface PaymentNotificationResponse {
  accepted: true
}

export enum PaymentMethodId {
  Card = 'card',
  NetBank = 'bank',
  MobilePay = 'mobilepay',
}

export enum PaymentType {
  Card = 'scheme',
  MobilePay = 'mobilepay',
  NetBank = 'ebanking_FI',
}

export enum CardBrands {
  MasterCard = 'mc',
  Visa = 'visa',
}

export enum PaymentState {
  Initialized = 'initialized',
  Completed = 'completed',
}

export enum SscCheckoutLocale {
  Finnish = 'fi-FI',
  Swedish = 'sv-SE',
}

export const sscCheckoutLocales: Record<Language, SscCheckoutLocale> = {
  [Language.Fi]: SscCheckoutLocale.Finnish,
  [Language.Sv]: SscCheckoutLocale.Swedish,
}

export enum PaymentQueryParam {
  CompletedPayment = 'completedPayment',
}
