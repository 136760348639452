import { useEffect, useState } from 'react'

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches)

  useEffect(() => {
    const mediaMatch = window.matchMedia(query)
    const handler = (e: Event) => setMatches((e as MediaQueryListEvent).matches)
    const propagate = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    mediaMatch.hasOwnProperty('addEventListener')
      ? mediaMatch.addEventListener('change', handler)
      : // iOS < 14
        mediaMatch.addListener(propagate)

    return () =>
      mediaMatch.hasOwnProperty('removeEventListener')
        ? mediaMatch.removeEventListener('change', handler)
        : mediaMatch.removeListener(propagate)
  }, [query, setMatches])
  return matches
}
