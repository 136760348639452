import { ReactElement, ReactNode } from 'react'

import { Button } from '../actions/Button'
import { BaseModal } from './BaseModal'
import { ModalButtonContainer, ModalContent } from './ModalContent'
import { ModalVisualMode } from './ModalTypes'
import { getIcon } from './ModalUtils'

export interface PromptModalProps {
  contentLabel?: string
  children?: ReactNode
  onPrimaryButtonClick?: () => unknown
  onSecondaryButtonClick?: () => unknown
  onClose?: () => unknown
  primaryButtonLabel: string
  secondaryButtonLabel: string
  isOpen: boolean
  header?: ReactElement | string
  mode?: ModalVisualMode
  shouldKeepButtonsOrder?: boolean
  requestId?: string
}

const shouldReverseButtons = (mode: ModalVisualMode, shouldKeepButtonsOrder: boolean): boolean => {
  if (shouldKeepButtonsOrder) {
    return false
  }
  return [ModalVisualMode.Danger, ModalVisualMode.Warn, ModalVisualMode.Help].includes(mode)
}

const PrimaryButton = (props: PromptModalProps) => (
  <Button
    label={props.primaryButtonLabel}
    onClick={props.onPrimaryButtonClick}
    disabled={!props.onPrimaryButtonClick}
    data-testid="prompt-modal-button"
  />
)

const SecondaryButton = (props: PromptModalProps) => (
  <Button
    disabled={!props.onSecondaryButtonClick}
    label={props.secondaryButtonLabel}
    mode={props.mode === ModalVisualMode.Danger ? 'danger' : undefined}
    onClick={props.onSecondaryButtonClick}
    variant="secondary"
  />
)

interface ButtonsProp extends PromptModalProps {
  mode: ModalVisualMode
  shouldKeepButtonsOrder: boolean
}

const Buttons = (props: ButtonsProp) => {
  const { mode, shouldKeepButtonsOrder } = props
  const buttons = [
    <SecondaryButton {...props} key="secondaryButton" />,
    <PrimaryButton {...props} key={'primaryButton'} />,
  ]

  return (
    <ModalButtonContainer>
      {shouldReverseButtons(mode, shouldKeepButtonsOrder) ? [...buttons].reverse() : buttons}
    </ModalButtonContainer>
  )
}

export const PromptModal = (props: PromptModalProps): JSX.Element => {
  const {
    contentLabel,
    children,
    onClose,
    isOpen,
    header,
    mode = ModalVisualMode.Info,
    shouldKeepButtonsOrder = false,
    requestId,
  } = props

  const icon = getIcon(mode)

  return (
    <BaseModal
      contentLabel={contentLabel ?? 'Prompt modal'}
      onClose={onClose}
      isOpen={isOpen}
      footer={<Buttons {...props} mode={mode} shouldKeepButtonsOrder={shouldKeepButtonsOrder} />}
    >
      <ModalContent icon={icon} header={header} requestId={requestId}>
        {children}
      </ModalContent>
    </BaseModal>
  )
}

PromptModal.displayName = 'PromptModal'
