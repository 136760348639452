import { styled } from 'styled-components'

import { Button, ButtonProps } from '../../actions/Button'
import { BRAND_ICON_DEFAULT_HEIGHT, SokGreenIcon, SokGreenIconNoWhitespace, SpankkiGreenIcon } from '../../assets/Icons'
import { MediumText, SmallText } from '../../decorative/Typography'
import { Colors } from '../../global/colors'
import { LogoIconType } from '../../global/ComponentProps'
import {
  BREAKPOINT_NARROW_MOBILE_PX,
  FONT_FAMILY_S_BONUS_UX,
  MAIN_HEADER_HEIGHT,
  MAIN_STEP_HEADER_HEIGHT,
} from '../../global/constants'
import { LEGACY_SPACE_LARGE } from '../../global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXSMALL } from '../../global/spacing'
import { useMediaQuery } from '../../global/useMediaQuery'
import { ChangeLanguageButtonOptions, PrimaryTopBarActionButtonOptions, PrimaryTopBarVariantOptions } from './types'

export interface PrimaryTopBarProps {
  bottomBorder?: boolean
  actionButtonOptions?: PrimaryTopBarActionButtonOptions
  changeLanguageButtonOptions?: ChangeLanguageButtonOptions
  icon?: LogoIconType
  serviceName?: string
  userDisplayName?: string
  variant?: PrimaryTopBarVariantOptions
}

export const PrimaryTopBar = ({
  actionButtonOptions,
  bottomBorder = false,
  changeLanguageButtonOptions,
  icon = LogoIconType.SOK,
  serviceName,
  userDisplayName,
  variant = 'large',
}: PrimaryTopBarProps) => {
  const { variant: actionButtonVariant = 'plain', ...actionButtonProps } = actionButtonOptions ?? {}

  const actionButtonStyleProps: ButtonProps = {
    compact: actionButtonVariant === 'plain',
    sizing: variant === 'compact' ? 'xsmall' : 'small',
    variant: actionButtonVariant,
  }

  const isNarrowMobileSize = useMediaQuery(`(max-width: ${BREAKPOINT_NARROW_MOBILE_PX - 1}px)`)

  const getIcon = () => {
    if (variant === 'compact') {
      return icon === LogoIconType.SPankki && !isNarrowMobileSize
        ? SpankkiGreenIcon(62, BRAND_ICON_DEFAULT_HEIGHT)
        : SokGreenIconNoWhitespace()
    } else {
      return icon === LogoIconType.SPankki ? SpankkiGreenIcon() : SokGreenIcon()
    }
  }

  const Text = variant === 'compact' ? SmallText : MediumText

  return (
    <Nav $bottomBorder={variant === 'large' && bottomBorder} $topBarVariant={variant}>
      {getIcon()}
      {variant === 'compact' && serviceName && (
        <ServiceNameText>
          <Text>{serviceName}</Text>
        </ServiceNameText>
      )}
      <RightContainer>
        {variant === 'large' && changeLanguageButtonOptions && (
          <Button
            aria-label={changeLanguageButtonOptions.ariaLabel}
            compact={true}
            data-testid={'primary-top-bar-change-language'}
            label={changeLanguageButtonOptions.label}
            onClick={changeLanguageButtonOptions.onClick}
            variant="plain"
          />
        )}
        <TextAndActionContainer>
          {userDisplayName && (
            <UserDisplayNameText>
              <Text>{userDisplayName}</Text>
            </UserDisplayNameText>
          )}
          {actionButtonOptions && actionButtonProps && (
            <ActionButton
              $topBarVariant={variant}
              data-testid={'primary-top-bar-action'}
              {...actionButtonProps}
              {...actionButtonStyleProps}
            />
          )}
        </TextAndActionContainer>
      </RightContainer>
    </Nav>
  )
}

const Nav = styled.nav<{ $bottomBorder?: boolean; $topBarVariant: PrimaryTopBarVariantOptions }>`
  background-color: ${Colors.BackgroundDefaultNeutral};
  color: ${Colors.TextStrongNeutral};
  display: flex;
  font-family: ${FONT_FAMILY_S_BONUS_UX};
  height: ${(props) =>
    props.$topBarVariant === 'compact' ? `${MAIN_STEP_HEADER_HEIGHT}px` : `${MAIN_HEADER_HEIGHT}px`};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${SPACE_SMALL};
  border-bottom: ${(props) => (props.$bottomBorder ? `1px solid ${Colors.BorderWeakNeutral}` : '0')};
  position: relative;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACE_XXSMALL};

  @media (min-width: ${BREAKPOINT_NARROW_MOBILE_PX}px) {
    gap: ${LEGACY_SPACE_LARGE};
  }
`

const ActionButton = styled(Button)<{ $topBarVariant: PrimaryTopBarVariantOptions }>`
  white-space: nowrap;
`

const ServiceNameText = styled.div`
  flex: 1;
  ::before {
    color: ${Colors.TextWeakNeutral};
    content: '|';
    margin: 0 ${SPACE_XXSMALL};
  }
`

const TextAndActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACE_XXSMALL};
`

const UserDisplayNameText = styled.div`
  white-space: nowrap;
`
