import { Link as DSLink, LinkProps as DSLinkProps } from '@s-group/design-system-components/Link/Link'
import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { ReactNode, RefAttributes } from 'react'
import { styled } from 'styled-components'

import { SPACE_XXXXSMALL } from '../global/spacing'

export interface LinkProps extends DSLinkProps {
  children?: ReactNode
  ['data-testid']?: string
}

const LinkWrap = styled.span`
  text-align: left;
  * {
    vertical-align: middle;
    display: flex;
    align-items: center;
    gap: ${SPACE_XXXXSMALL};
  }
  &:hover * {
    cursor: pointer;
    text-decoration: underline;
  }
`

const TypedDSLink = DSLink as React.ComponentType<LinkProps & RefAttributes<HTMLLinkElement>>

export const Link = ({
  // Always include href to make link focusable
  href = '',
  icon,
  iconPos = 'before',
  children,
  tabIndex,
  onClick,
  className,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  target,
  color,
  standalone,
  external,
}: LinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // Prevent default navigation behaviour
    e.preventDefault()
    onClick?.(e)
  }

  return (
    <LinkWrap>
      <TypedDSLink
        href={href}
        standalone={standalone}
        onClick={handleClick}
        className={className}
        tabIndex={tabIndex}
        data-testid={dataTestId}
        icon={icon}
        iconPos={iconPos}
        target={target}
        color={color}
        external={external}
        role={!href ? 'link' : undefined}
      >
        {children}
        {ariaLabel && <VisuallyHidden>{ariaLabel}</VisuallyHidden>}
      </TypedDSLink>
    </LinkWrap>
  )
}
