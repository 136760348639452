import '@s-group/design-system-tokens/web/assets/fonts/SBonusDisplay.css'
import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css'

import { Heading, HeadingProps } from '@s-group/design-system-components/Heading/Heading'
import { Text, TextProps } from '@s-group/design-system-components/Text/Text'
import { SDS_TEXT_HEADING_SMALL_REGULAR } from '@s-group/design-system-tokens/web/tokens/text.es6.js'
import { JSXElementConstructor } from 'react'
import { css, styled } from 'styled-components'

import { Colors } from '../global/colors'

export interface DefaultProps extends Readonly<Pick<HeadingProps, 'weight' | 'tagName'>> {}

export type TextWeight = 'bold' | 'regular' | 'medium' | 'light'

export type SnippetColor = 'primary' | 'secondary' | 'danger' | 'inherit'

export enum HeadingFontSize {
  Main = 53,
  L = 43,
  M = 27,
  S = 22,
  XS = 18,
}

export interface HeadingTypographyProps extends HeadingProps {
  $color?: SnippetColor
}

export interface TypographyProps extends TextProps {
  $color?: SnippetColor
  $text?: string
  htmlFor?: string
}

type HeadingElement = JSXElementConstructor<HeadingTypographyProps> & { defaultProps: DefaultProps }

type TypographyElement = JSXElementConstructor<TypographyProps>

const colorSnippet = ({ $color }: TypographyProps) => {
  if ($color === 'primary') {
    return css`
      color: ${Colors.TextDefaultNeutral};
    `
  } else if ($color === 'secondary') {
    return css`
      color: ${Colors.TextStrongNeutral};
    `
  } else if ($color === 'danger') {
    return css`
      color: ${Colors.TextError};
    `
  }

  return css`
    color: inherit;
  `
}

export const paragraphSnippet = ($marginBottom: string) => css`
  margin-bottom: ${$marginBottom};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const paragraphSnippetSelector = ($size: string) => (props: TypographyProps) =>
  props['tagName'] === 'p' && paragraphSnippet($size)

const headingBottomMarginModifier = 0.75
const largeHeadingLineHeight = '49px'
const mediumHeadingLineHeight = '34px'
const smallHeadingLineHeight = '28px'
const extraSmallHeadingLineHeight = '24px'

const extraLargeTextFontSize = '22px'
const largeTextFontSize = '18px'
const mediumTextFontSize = '16px'
const smallTextFontSize = '14px'
const extraSmallTextFontSize = '11px'

export const MainHeading: HeadingElement = styled(Heading).attrs(({ tagName }: { tagName: string }) => ({
  tagName,
  variant: 'display',
  weight: 'bold',
  transform: 'uppercase',
  sizing: 'small',
}))`
  margin-bottom: 24px;
  color: ${Colors.TextMediumPrimary};
`

MainHeading.defaultProps = {
  tagName: 'h1',
}

export const LargeHeading: HeadingElement = styled(Heading).attrs(({ tagName }: { tagName: string }) => ({
  tagName,
  variant: 'display',
  weight: 'semibold',
  sizing: 'xsmall',
}))`
  margin-bottom: calc(${largeHeadingLineHeight} * ${headingBottomMarginModifier});
  color: ${Colors.TextMediumPrimary};
`

LargeHeading.defaultProps = {
  tagName: 'h2',
}

export const MediumHeading: HeadingElement = styled(Heading).attrs({
  weight: 'bold',
  sizing: 'medium',
})`
  margin-bottom: calc(${mediumHeadingLineHeight} * ${headingBottomMarginModifier});
  ${colorSnippet}
`

export const SmallHeading: HeadingElement = styled(Heading).attrs(({ weight }: { weight: string }) => ({
  weight,
  sizing: 'small',
}))`
  margin-bottom: calc(${smallHeadingLineHeight} * ${headingBottomMarginModifier});
  ${colorSnippet}
`

SmallHeading.defaultProps = {
  weight: 'bold',
}

export const ExtraSmallHeading: HeadingElement = styled(Heading).attrs({
  weight: 'bold',
  sizing: 'xsmall',
})`
  margin-bottom: calc(${extraSmallHeadingLineHeight} * ${headingBottomMarginModifier});
  ${colorSnippet}
`

export const XXSmallHeading: HeadingElement = styled(Heading).attrs({
  weight: 'medium',
  sizing: 'xxsmall',
})`
  ${colorSnippet}
`

export const ButtonText: TypographyElement = styled(Text).attrs({
  sizing: 'medium',
  weight: 'medium',
})`
  ${colorSnippet}
`

export const LargeText: TypographyElement = styled(Text).attrs({
  sizing: 'large',
})`
  ${colorSnippet}
  ${paragraphSnippetSelector(largeTextFontSize)}
`

export const ExtraLargeText: TypographyElement = styled(Text).attrs({
  sizing: 'large',
})`
  font-size: ${extraLargeTextFontSize};
  line-height: 28px;
  ${colorSnippet}
  ${paragraphSnippetSelector(extraLargeTextFontSize)}
`

export const LeadText = styled(Text).attrs({ tagName: 'div' })<TypographyProps>`
  ${SDS_TEXT_HEADING_SMALL_REGULAR}
  ${colorSnippet}

  p {
    ${paragraphSnippet(largeTextFontSize)}
  }
`

export const MediumText: TypographyElement = styled(Text).attrs({
  sizing: 'medium',
})`
  ${colorSnippet}
  ${paragraphSnippetSelector(mediumTextFontSize)}
`

export const SmallText: TypographyElement = styled(Text).attrs({
  sizing: 'small',
})`
  ${colorSnippet}
  ${paragraphSnippetSelector(smallTextFontSize)}
`

export const MutedSmallText = styled(SmallText)<TypographyProps>`
  color: ${Colors.TextStrongNeutral};
  p:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const ExtraSmallText: TypographyElement = styled(Text).attrs({
  sizing: 'xsmall',
})`
  ${colorSnippet}
  ${paragraphSnippetSelector(extraSmallTextFontSize)}
`

export const MiddleText: TypographyElement = styled(Text)`
  margin-bottom: 0;

  &:focus-visible {
    outline: 0px;
  }

  ${colorSnippet}
  ${paragraphSnippetSelector(largeTextFontSize)}
`
