import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { styled } from 'styled-components'

import { splitStringInHalf } from '../../utilities/helpers'

const TruncatedSpan = styled.span`
  &:after,
  &:before {
    display: inline-block;
    max-width: 50%;
    overflow: hidden;
    white-space: pre;
  }

  &:before {
    content: attr(data-content-start);
    text-overflow: ellipsis;
  }

  &:after {
    content: attr(data-content-end);
    text-overflow: clip;
    direction: rtl;
  }
`

export const TruncatedInMiddleSpan = ({ text }: { text: string }) => {
  const splitResult = splitStringInHalf(text)
  return (
    <>
      <TruncatedSpan
        data-content-start={splitResult.firstHalf}
        data-content-end={splitResult.secondHalf}
        title={text}
        aria-hidden={true}
      />
      <VisuallyHidden>{text}</VisuallyHidden>
    </>
  )
}
