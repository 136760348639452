import { BREAKPOINT_DESKTOP_PX } from '@ui-components/global/constants'
import { css, styled } from 'styled-components'

import { Button, ButtonProps } from './../Button'

export interface DynamicWidthButtonProps extends Omit<DynamicWidthButtonBaseProps, 'variant'> {}

interface DynamicWidthButtonBaseProps extends Partial<ButtonProps> {
  $isFullWidth?: boolean
}

export const PrimaryButton = (props: DynamicWidthButtonProps): JSX.Element => {
  return <DynamicWidthButton {...props} variant="primary" />
}

export const SecondaryButton = (props: DynamicWidthButtonProps): JSX.Element => {
  return <DynamicWidthButton {...props} variant="secondary" />
}

export const PlainButton = (props: DynamicWidthButtonProps): JSX.Element => {
  return <DynamicWidthButton {...props} variant="plain" />
}

const DynamicWidthButton = styled(Button)<{ $isFullWidth?: boolean }>`
  width: 50%;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_DESKTOP_PX - 1}px) {
    width: 100%;
  }

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`
