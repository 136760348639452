import { PenIcon } from '../../assets/Icons'
import { MediumText } from '../../decorative/Typography'
import { useIsDesktopScreen } from '../../hooks/useIsDesktopScreen'
import { LinkButton } from './../LinkButton'
import { ActionProperties } from './../types'

export interface EditLinkButtonProps extends ActionProperties {
  ariaLabel: string
}

export const EditLinkButton = ({ label, ariaLabel, onClick }: EditLinkButtonProps) => {
  const isDesktop = useIsDesktopScreen()

  return (
    <LinkButton
      onClick={onClick as (e: React.BaseSyntheticEvent) => unknown}
      aria-label={ariaLabel}
      icon={<PenIcon />}
      compact={true}
    >
      {isDesktop && <MediumText>{label}</MediumText>}
    </LinkButton>
  )
}
