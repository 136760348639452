import { ReactElement, ReactNode } from 'react'

import { PrimaryButton, SecondaryButton } from '../actions/special/DynamicWidthButtons'
import { BaseModal } from './BaseModal'
import { ModalButtonContainer, ModalContent } from './ModalContent'
import { ModalVisualMode } from './ModalTypes'
import { getIcon } from './ModalUtils'

export type ButtonType = 'primary' | 'secondary'

export interface AlertModalProps {
  header: string | ReactElement | undefined
  contentLabel?: string
  children?: ReactNode
  buttonLabel: string
  buttonType?: ButtonType
  isOpen: boolean
  mode?: ModalVisualMode
  onButtonClick: () => unknown
  requestId?: string
}

export const AlertModal = ({
  contentLabel,
  children,
  onButtonClick,
  buttonLabel,
  buttonType = 'primary',
  isOpen,
  header,
  mode = ModalVisualMode.Plain,
  requestId,
}: AlertModalProps): JSX.Element => {
  const icon = getIcon(mode)
  const isPrimaryButton = buttonType === 'primary'
  const buttons = (
    <ModalButtonContainer>
      {isPrimaryButton ? (
        <PrimaryButton data-testid="alert-modal-button" onClick={onButtonClick} label={buttonLabel} />
      ) : (
        <SecondaryButton data-testid="alert-modal-button" onClick={onButtonClick} label={buttonLabel} />
      )}
    </ModalButtonContainer>
  )
  return (
    <BaseModal contentLabel={contentLabel ?? 'Alert modal'} onClose={onButtonClick} isOpen={isOpen} footer={buttons}>
      <ModalContent icon={icon} header={header} requestId={requestId}>
        {children}
      </ModalContent>
    </BaseModal>
  )
}

AlertModal.displayName = 'AlertModal'
