import { Card } from '@s-group/design-system-components/Card/Card'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_LARGE_DESKTOP_PX, BREAKPOINT_NARROW_MOBILE_PX } from './constants'

const DSCard = ({
  children,
  className,
  'data-testid': dataTestId,
}: {
  children: ReactNode
  className?: string
  ['data-testid']?: string
}) => (
  <Card rounding="rounded" variant="elevated" className={className} data-testid={dataTestId}>
    {children}
  </Card>
)

const CARD_PADDING = 18
export const CardSection = styled(DSCard)`
  margin: 8px 16px;
  padding: ${CARD_PADDING}px ${CARD_PADDING}px;
  flex-direction: column;
  align-items: flex-start;
  width: calc((${BREAKPOINT_DESKTOP_PX}px - ${CARD_PADDING * 4}px) / 2);

  @media (max-width: ${BREAKPOINT_NARROW_MOBILE_PX - 1}px) {
    width: 90%;
  }

  > * + * {
    margin-top: 0;
  }

  && {
    display: flex;

    &:hover {
      box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.16);
    }
  }
`

export interface CardSectionProps {
  $direction?: 'row' | 'column'
}

export const CardSectionWrapper = styled.div<CardSectionProps>`
  display: flex;
  flex-direction: ${(props: CardSectionProps) => (props.$direction ? props.$direction : 'column')};
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX - 1}px) {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  ${CardSection} {
    ${(props: CardSectionProps) =>
      props.$direction === 'column' &&
      `
      margin: 8px 0px;
      width: -webkit-fill-available;
      
      &:first-child {
        margin-top: 16px;
      }
      &:last-child {
        margin-bottom: 16px;
      }
  `}
  }
`
