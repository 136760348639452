import {
  AuthenticateResponse,
  CardApplicationResponse,
  CbsTokenResponse,
  CreditLimitApplicationRequest,
  ExitUrlResponse,
  GetPostOfficeResponse,
  LogErrorRequest,
  PaymentCardApplicationRequest,
  SendApplicationRequest,
  SignApplicationResponse,
  StatusResponse,
  ValidatePhoneNumberRequest,
  ValidatePhoneNumberResponse,
} from './types'

export interface Endpoint<TResponse = void, TRequest = void, TQueryParamNames = string, TPathParamNames = string> {
  path: string
  method: 'get' | 'put' | 'post' | 'delete'
  responsePayload?: TResponse
  requestPayload?: TRequest
  queryParamNames?: TQueryParamNames
  pathParamNames?: TPathParamNames
}

export const ENDPOINT_NEW_CARD_APPLICATION: Endpoint<void, PaymentCardApplicationRequest> = {
  path: '/application/new-card',
  method: 'put',
}

export const ENDPOINT_CREDIT_LIMIT_APPLICATION: Endpoint<void, CreditLimitApplicationRequest> = {
  path: '/application/credit-limit',
  method: 'put',
}

export const ENDPOINT_PREVIEW_APPLICATION: Endpoint<string> = {
  path: '/application/preview',
  method: 'get',
}

export const ENDPOINT_SIGN_APPLICATION: Endpoint<SignApplicationResponse> = {
  path: '/application/signing-url',
  method: 'get',
}

export const ENDPOINT_SEND_APPLICATION: Endpoint<void, SendApplicationRequest> = {
  path: '/application/send',
  method: 'put',
}

export const ENDPOINT_GET_POST_OFFICE: Endpoint<GetPostOfficeResponse> = {
  path: '/postOffice',
  method: 'get',
}

export const ENDPOINT_GET_APPLICATION_RESULT: Endpoint<CardApplicationResponse> = {
  path: '/application/result',
  method: 'get',
}

export const ENDPOINT_AUTHENTICATE: Endpoint<AuthenticateResponse, void, 'lang' | 'authMode'> = {
  path: '/authenticate',
  method: 'get',
}

export const ENDPOINT_LOG_ERROR: Endpoint<void, LogErrorRequest> = {
  path: '/log-error',
  method: 'post',
}

export const ENDPOINT_GET_EXIT_URLS: Endpoint<ExitUrlResponse, void, 'sourceChannel' | 'flow'> = {
  path: '/exit-urls',
  method: 'get',
}

export const ENDPOINT_GET_CBS_TOKEN: Endpoint<CbsTokenResponse, void> = {
  path: '/cbs-token',
  method: 'get',
}

export const ENDPOINT_STATUS: Endpoint<StatusResponse> = {
  path: '/status',
  method: 'get',
}

export const ENDPOINT_VALIDATE_PHONE_NUMBER: Endpoint<ValidatePhoneNumberResponse, ValidatePhoneNumberRequest> = {
  path: '/validate-phone-number',
  method: 'post',
}

export type UploadDocumentQueryParams = 'fileName' | 'fileType'
export type UploadDocumentResponse = { signedUrl: string }

export type FileUploadEndPoint<
  TResponse extends UploadDocumentResponse,
  TQueryParams extends UploadDocumentQueryParams,
> = Endpoint<TResponse, void, TQueryParams, string>
