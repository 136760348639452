import { TextBadge } from '@s-group/design-system-components/TextBadge/TextBadge'
import { SDS_FONT_WEIGHT_REGULAR } from '@s-group/design-system-tokens/web/tokens/font.es6'
import { styled } from 'styled-components'

import { TextBadgeColors } from '../global/ComponentProps'

/**
 *  Using styled(TextBadge)`` to override font-weight resulted some weird errors in ui-component tests.
 *  It is maybe related to mismatching styled-components versions between this package and SDS-library (v5 and v6)
 */
const FontWeightOverride = styled.div`
  span {
    font-weight: ${SDS_FONT_WEIGHT_REGULAR} !important;
  }
`

export interface ColoredLabelProps {
  color: TextBadgeColors
  text: string
  variant?: 'filled' | 'tonal' | 'outlined'
}

export const ColoredLabel = ({ text, color, variant }: ColoredLabelProps): JSX.Element => (
  <FontWeightOverride>
    <TextBadge variant={variant ?? 'tonal'} rounding="pill" color={color}>
      {text}
    </TextBadge>
  </FontWeightOverride>
)
