import { FullWidthPageContentSection, Section } from '@ui-components/global/LayoutStyling'
import { StandardShadow } from '@ui-components/global/shadow'
import { PropsWithChildren, ReactNode } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'
import { BREAKPOINT_DESKTOP_PX } from '../global/constants'

const PaperContainer = styled.div`
  width: 100vw;
  max-width: 2000px;
  background: ${Colors.White};
  display: flex;
  margin: auto;
  flex-direction: column;
  ${StandardShadow}
`

const ContentWrapper = styled.div<{ fullWidthFooter?: boolean }>`
  max-width: ${(props) => (!props.fullWidthFooter ? `${BREAKPOINT_DESKTOP_PX - 1}px;` : 'inherit')};
`

const footerAttributes = {
  role: 'region',
}

const FooterSection = styled(FullWidthPageContentSection).attrs(footerAttributes)`
  padding-bottom: 64px;
`

interface WideContainerProps extends PropsWithChildren {
  footerContent?: ReactNode
  fullWidthFooter?: boolean
  ['data-testid']?: string
}

export const WideContainer = (props: WideContainerProps) => {
  const { footerContent, children, fullWidthFooter } = props
  return (
    <>
      <PaperContainer data-testid={props['data-testid'] ?? 'entrance'}>{children}</PaperContainer>
      {footerContent && (
        <FooterSection $backgroundColor={'transparent'}>
          <ContentWrapper fullWidthFooter={fullWidthFooter}>
            <Section halfHorizontalPadding={fullWidthFooter}>{footerContent}</Section>
          </ContentWrapper>
        </FooterSection>
      )}
    </>
  )
}
