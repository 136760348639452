import isPropValid from '@emotion/is-prop-valid'
import * as R from 'remeda'
import { WebTarget } from 'styled-components'

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_LARGE_DESKTOP_PX, BREAKPOINT_NARROW_MOBILE_PX } from '../global/constants'

export const removeKeysFromObject = (keys: string[] | string, obj: object): Record<string, unknown> => {
  if (typeof keys === 'string') {
    keys = [keys]
  }
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)))
}

export const isFirefox = (): boolean => {
  return navigator.userAgent.toLowerCase().search('firefox') > -1
}

export const isSafari = (): boolean => {
  return navigator.userAgent.toLowerCase().search('safari') > -1
}

export const PAGE_TITLE_CLASS = 'page-title-of-the-view'
export const PAGE_LOADER_CLASS = 'page-loading-indicator-content'
export const PAGE_BACK_BUTTON_CLASS = 'page-back-button'
export const PAGE_EXIT_BUTTON_CLASS = 'page-exit-button'

export const parseComponentId = (name: string, id?: string): string => {
  // id is used for aria, so it must be present if not set.
  return id || name.replace(/\s+/g, '-')
}

export const isMobileSize = (): boolean => window.innerWidth <= BREAKPOINT_NARROW_MOBILE_PX

export const isDesktopSize = (): boolean => window.innerWidth >= BREAKPOINT_DESKTOP_PX

export const isLargeDesktopSize = (): boolean => window.innerWidth >= BREAKPOINT_LARGE_DESKTOP_PX

export const toObjArray = <T>(arr: T[]): { id: number; item: T }[] => {
  return arr.map((item, index) => {
    return { id: index, item }
  })
}

export const resolveCssDimensionValue = (
  value: string | number | undefined,
  defaultValue: string,
  numericalUnit: string = 'px',
): string => {
  if (R.isString(value)) {
    return value
  } else if (R.isNumber(value)) {
    return `${value}${numericalUnit}`
  } else {
    return defaultValue
  }
}

export const splitStringInHalf = (str: string): { firstHalf: string; secondHalf: string } => {
  const middleIndex = Math.floor(str.length / 2)
  // add Unicode bidirectional control characters LTR to make direction: rtl ignore the new dash
  const newStr = str.replace(/-/g, '\u200E\u2014\u200E')

  const firstHalf = newStr.substring(0, middleIndex)
  const secondHalf = newStr.substring(middleIndex)

  return {
    firstHalf,
    secondHalf,
  }
}

export const getShortRequestId = (str: string): string => {
  return str.slice(0, 13).replace(/-$/, '')
}

// This implements the default behavior from styled-components v5
// This is needed until DS migrates to styled-components v6
export const shouldForwardProp = (propName: string, target: WebTarget): boolean => {
  if (typeof target === 'string') {
    return isPropValid(propName)
  }
  return true
}
